import styled from 'styled-components'
import { Popover as PopoverMiu } from '@material-ui/core'

export const Container = styled.div`
  width: 255px;
`

export const Popover = styled(PopoverMiu)`
  margin-top: 4px;

  & .MuiPaper-root {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
`

type MenuItemProps = {
  disabled?: boolean
}

export const MenuItem = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 16px;
  cursor: ${(props: MenuItemProps) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props: MenuItemProps) => (props.disabled ? 0.2 : 1)};
  ${(props: MenuItemProps) => props.disabled && 'pointer-events: none;'}

  &:hover {
    background: rgba(0, 5, 100, 0.04);
  }
`
