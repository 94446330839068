import { Button, IconButton, RoundedCross } from '@gmini/ui-kit'
import { useState } from 'react'

import {
  ButtonContainer,
  Dialog,
  InfoText,
  SmallText,
  TextArea,
  Title,
  TitleContainer,
  Wrapper,
} from './IssueOfficialReplyForm.styled'

type IssueOfficialReplyFormProps = {
  open: boolean
  onClose: () => void
  onSubmit: (values: { description: string }) => void
}

export const IssueOfficialReplyForm = ({
  open,
  onClose,
  onSubmit,
}: IssueOfficialReplyFormProps) => {
  //TODO: код когда понадобится прикреплять документы
  // const [attachedDocuments, setAttachedDocuments] = useState([])
  const [description, setDescription] = useState('')

  const handleSubmit = () => {
    onSubmit({ description })
    onResetComment()
    onClose()
  }

  const onResetComment = () => {
    setDescription('')
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onResetComment()
        onClose()
      }}
    >
      <Wrapper>
        <TitleContainer>
          <Title>Замечание устранено</Title>
          <IconButton
            onClick={() => {
              onClose()
              onResetComment()
            }}
          >
            <RoundedCross />
          </IconButton>
        </TitleContainer>
        <InfoText>
          Если работа по устранению данного замечания завершена, сформируйте
          официальный ответ для инициатора замечания.
        </InfoText>
        <SmallText>Комментарий</SmallText>
        <TextArea
          value={description}
          onChange={e => setDescription(e.currentTarget.value)}
          autoFocus
        />
        {/* TODO: код когда понадобится прикреплять документы */}
        {/* <AttachDocumentsWrapper>
          <SmallText>
            Список документов: <span>{attachedDocuments.length}</span>
          </SmallText>
          <Button
            onClick={handleSubmit}
            size='regular'
            color='tertiary'
            leftIcon={<PlusCircle width='16px' height='16px' />}
          >
            <span>Добавить</span>
          </Button>
        </AttachDocumentsWrapper> */}
        <ButtonContainer>
          <Button
            color='secondary'
            onClick={() => {
              onResetComment()
              onClose()
            }}
          >
            Отменить
          </Button>
          <Button disabled={!description.trim()} onClick={handleSubmit}>
            Применить
          </Button>
        </ButtonContainer>
      </Wrapper>
    </Dialog>
  )
}
