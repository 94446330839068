import { NoFiles, Setting } from '@gmini/ui-kit'
import { ClickAwayListener } from '@material-ui/core'
import { ReactNode, useMemo, useState } from 'react'

import { Checkbox, OpenCtxButton } from '../../atoms'

import { ThreeDotsIcon } from '../../atoms/ThreeDotsIcon'

import { ColumnOrderPopup } from '../../molecules/ColumnOrderPopup'

import {
  CheckboxTh,
  SettingTh,
  StyledTable,
  Th,
  Thead,
  LinearProgress,
  PaginationLine,
  PaginationLineTd,
  Count,
  Tr,
  Td,
  CheckboxTd,
  SettingButtonWrapper,
  SettingButton,
  NumberTh,
  NumberTd,
  NoFilesContainer,
  TextMedium,
} from './Table.styled'

export type TableDataItem = Record<string, any>

export type TableRenderCellProps<DataItem extends TableDataItem> = {
  value: DataItem[keyof DataItem]
  row: DataItem
}

export type TableColumnType = 'number' | 'string'

export type TableColumn<DataItem extends TableDataItem> = {
  name: ReactNode
  thContent?: ReactNode
  field: keyof DataItem
  visible: boolean
  type?: TableColumnType
  style?: React.CSSProperties
  cellStyle?: React.CSSProperties
  renderCell?: (props: TableRenderCellProps<DataItem>) => ReactNode
}

export type TableProps<DataItem extends TableDataItem> = {
  rows: DataItem[]
  columns: TableColumn<DataItem>[]
  pending: boolean
  totalRows: number
  activeRowKey: string | number | undefined
  getRowKey: (row: DataItem) => string | number
  onRowCtxMenu: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item: DataItem,
  ) => void
  onChangeColumns: (next: TableColumn<DataItem>[]) => void
  onClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    item: DataItem,
  ) => void
  isEnabledCol?: (col: TableColumn<DataItem>) => boolean
}

export function Table<DataItem extends TableDataItem>({
  columns,
  rows,
  pending,
  totalRows,
  activeRowKey,
  getRowKey,
  onRowCtxMenu,
  onClick,
  onChangeColumns,
  isEnabledCol,
}: TableProps<DataItem>) {
  const [isColumnOrderPopupOpen, setIsColumnOrderPopupOpen] = useState(false)
  const [checked, setChecked] = useState<Record<string, boolean>>({})
  const checkedCount = useMemo(
    () => Object.values(checked).filter(Boolean).length,
    [checked],
  )
  const allChecked = checkedCount === rows.length

  const onCheckRow = (key: string | number, value: boolean) => {
    setChecked(prev => ({ ...prev, [key]: value }))
  }

  const onCheckAll = () => {
    if (allChecked) {
      setChecked({})
      return
    }

    const entries = rows.map(row => [getRowKey(row), true])
    setChecked(Object.fromEntries(entries))
  }

  return (
    <>
      <StyledTable>
        <Thead>
          <tr>
            <CheckboxTh>
              <Checkbox
                indeterminate={Number(rows.length) > 0 && allChecked}
                checked={false}
                onChange={onCheckAll}
                visible
              />
            </CheckboxTh>

            {columns
              .filter(c => (c.visible && isEnabledCol ? isEnabledCol(c) : true))
              .map((column, idx) => {
                const content = column.thContent
                  ? column.thContent
                  : column.name
                const CellComponent = column.type === 'number' ? NumberTh : Th

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <CellComponent key={idx} style={column.style}>
                    {content}
                  </CellComponent>
                )
              })}

            <SettingTh>
              <SettingButtonWrapper>
                <SettingButton
                  onClick={() => setIsColumnOrderPopupOpen(prev => !prev)}
                  size='small'
                >
                  <Setting
                    color='rgba(53, 59, 96, 0.5)'
                    width='24px'
                    height='24px'
                  />
                </SettingButton>
                {isColumnOrderPopupOpen ? (
                  <ClickAwayListener
                    onClickAway={() => setIsColumnOrderPopupOpen(false)}
                  >
                    <div>
                      <ColumnOrderPopup
                        columns={columns}
                        onChangeColumns={onChangeColumns}
                        isEnabledCol={isEnabledCol}
                      />
                    </div>
                  </ClickAwayListener>
                ) : null}
              </SettingButtonWrapper>
            </SettingTh>
          </tr>
          <tr>
            <td colSpan={99999999}>
              {/* Число 99999999 нужно, чтобы индикатор загрузки распространялся на все колонки */}
              <LinearProgress show={pending} />
            </td>
          </tr>
        </Thead>
        <tbody>
          {!rows.length && !pending ? (
            <NoFilesContainer>
              <NoFiles />
              <TextMedium>Нет совпадений.</TextMedium>
              <TextMedium>
                Вы можете попробовать изменить параметры поиска.
              </TextMedium>
            </NoFilesContainer>
          ) : (
            rows.map((row, idx, { length }) => {
              const rowKey = getRowKey(row)
              const rowChecked = checked[rowKey]
              const active = activeRowKey === rowKey

              return (
                <Tr
                  key={rowKey}
                  selected={rowChecked || active}
                  onContextMenu={event => onRowCtxMenu(event, row)}
                  onClick={event => onClick(event, row)}
                >
                  <CheckboxTd>
                    <Checkbox
                      visible={rowChecked}
                      checked={rowChecked}
                      onChange={e => onCheckRow(rowKey, e.target.checked)}
                      onClick={e => e.stopPropagation()}
                    />
                  </CheckboxTd>

                  {columns
                    .filter(c =>
                      c.visible && isEnabledCol ? isEnabledCol(c) : true,
                    )
                    .map((column, colIdx) => {
                      const value = row[column.field]
                      const CellComponent =
                        column.type === 'number' ? NumberTd : Td

                      return (
                        <CellComponent
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${rowKey}_${colIdx}`}
                          style={column.cellStyle}
                        >
                          {column.renderCell
                            ? column.renderCell({
                                value,
                                row,
                              })
                            : (value as string)}
                        </CellComponent>
                      )
                    })}

                  <Td>
                    <OpenCtxButton
                      onClick={event => {
                        event.stopPropagation()
                        onRowCtxMenu(event, row)
                      }}
                      active={active}
                      size='small'
                    >
                      <ThreeDotsIcon />
                    </OpenCtxButton>
                  </Td>
                </Tr>
              )
            })
          )}
        </tbody>
      </StyledTable>
      <PaginationLine>
        <PaginationLineTd>
          {Number(rows.length) > 0 && (
            <Count>
              Показаны 1 - {rows.length} из {totalRows}
            </Count>
          )}
        </PaginationLineTd>
      </PaginationLine>
    </>
  )
}
