import { AppliedFilters } from '@gmini/components'
import {
  useAppliedFilterDateData,
  useAppliedFilterTypeData,
  useAppliedFilterStatusData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import {
  ASSIGNEE_USER_IDS_IL,
  CREATED_DATE_RANGE_IL,
  ISSUE_TYPE_IDS_IL,
  OWNER_IDS_IL,
  STATUSES_IL,
  UPDATED_DATE_RANGE_IL,
  SHORT_DISPLAY_DATE_FORMAT,
  UPDATED_DATE_CODE_IL,
  CREATED_DATE_CODE_IL,
  ASSIGNEE_ROLE_IDS_IL,
  ASSIGNEE_COMPANY_IDS_IL,
  DEADLINE_CODE_IL,
} from '../../../constants'
import { companyList$ } from '../../company.store'

import { issueStatusList$ } from '../../issueStatus.store'

import { issueTypes$ } from '../../issueTypes.store'
import { roleList$ } from '../../role.store'
import { projectUserList$ } from '../../user.store'

import { useAppliedFilterDeadlineData } from './useAppliedFilterDeadlineData'

type IssueListAppliedFiltersProps = {
  onChange?: (query: URLSearchParamsCustom) => void
}

export const IssueListAppliedFilters = ({
  onChange,
}: IssueListAppliedFiltersProps) => {
  const issueTypes = useStore(issueTypes$)
  const issueStatusList = useStore(issueStatusList$)
  const userList = useStore(projectUserList$)
  const roleList = useStore(roleList$)
  const companyList = useStore(companyList$)

  const preparedIssueTypes = useAppliedFilterTypeData({
    name: 'Дисциплина',
    urlKey: ISSUE_TYPE_IDS_IL,
    typeList: issueTypes,
  })

  const preparedIssueStatuses = useAppliedFilterStatusData({
    name: 'Статус',
    urlKey: STATUSES_IL,
    statusList: issueStatusList,
  })

  const preparedOwners = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: OWNER_IDS_IL,
    userList,
  })

  const preparedAssignee = useAppliedFilterUserData({
    name: 'Назначено на',
    urlKey: [
      ASSIGNEE_USER_IDS_IL,
      ASSIGNEE_ROLE_IDS_IL,
      ASSIGNEE_COMPANY_IDS_IL,
    ],
    userList: [
      ...userList,
      ...roleList.map(role => ({ ...role, name: role.title })),
      ...companyList.map(company => ({ ...company, name: company.label })),
    ],
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_IL,
    urlKeyValue: UPDATED_DATE_RANGE_IL,
    name: 'Изменен',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_IL,
    urlKeyValue: CREATED_DATE_RANGE_IL,
    name: 'Создан',
  })

  const preparedDeadline = useAppliedFilterDeadlineData({
    urlKeyCode: DEADLINE_CODE_IL,
    name: 'Срок',
  })

  const appliedFilters = useMemo(
    () =>
      [
        preparedIssueTypes,
        preparedIssueStatuses,
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedOwners,
        preparedAssignee,
        preparedDeadline,
      ].filter(({ value }) => value.length),
    [
      preparedIssueTypes,
      preparedIssueStatuses,
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedOwners,
      preparedAssignee,
      preparedDeadline,
    ],
  )

  return <AppliedFilters onChange={onChange} items={appliedFilters} />
}
