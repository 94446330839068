import { createStore } from 'effector'

import * as api from '@gmini/ism-api-sdk'

export const fetchIssueTypes = api.GTechIssueType.fetchList.createContext()
export const fetchIssueTypesPending$ = fetchIssueTypes.pending$

export const issueTypes$ = createStore<api.GTechIssueType[]>([]).on(
  fetchIssueTypes.doneData,
  (_state, result) => result,
)
