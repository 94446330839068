import styled from 'styled-components'

type FieldLabelProps = {
  required?: boolean
}

export const FieldLabel = styled.div`
  font-family: Roboto, sans-serif;
  color: #000335;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  &:after {
    ${(props: FieldLabelProps) => props.required && 'content: " *";'}
    color: #de4242;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
`
