import { Badge, RoundedCross } from '@gmini/ui-kit'

import {
  BubbleStyled,
  BubbleTitle,
  BubbleValue,
  DeleteButton,
} from './Bubble.styled'

type BubbleProps = {
  title: string
  value: string | Array<string | number>
  onClose?: () => void
  onResetFilter?: (() => void) | null
}

export const Bubble = ({
  title,
  value,
  onClose,
  onResetFilter,
}: BubbleProps) => (
  <BubbleStyled>
    <BubbleTitle>{title}:</BubbleTitle>
    <BubbleValue>
      {Array.isArray(value) && value.length > 1 ? (
        <Badge bgColor='#2235AA'>{value.length}</Badge>
      ) : (
        value
      )}
    </BubbleValue>
    {onResetFilter && (
      <DeleteButton onClick={onResetFilter}>
        <RoundedCross
          color='rgba(255, 255, 255, 0.5)'
          width='14px'
          height='14px'
          onClick={onClose}
        />
      </DeleteButton>
    )}
  </BubbleStyled>
)
