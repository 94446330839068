import { ReactNode, useEffect } from 'react'

import { WithHeader } from '@gmini/components'

import { useQuery } from '@gmini/utils'

import { useNavbar, getModulesLinkFromEnv } from '@gmini/common'

import { Tabs } from '@gmini/ui-kit'

import { envLinks } from '../../../config'

import { fetchIssueTypes } from '../../issueTypes.store'
import { fetchAllUserList, fetchProjectUserList } from '../../user.store'
import { fetchStatusList } from '../../issueStatus.store'
import { PROJECT_URN } from '../../../constants'
import { fetchRoleList } from '../../role.store'

type IssueManagerWrapperProps = {
  children: ReactNode | ReactNode[]
  loading?: boolean
  brandSecondary?: ReactNode
}

export const IssueManagerWrapper = ({
  children,
  loading,
  brandSecondary,
}: IssueManagerWrapperProps) => {
  const query = useQuery()
  const projectUrn = query.get(PROJECT_URN) || null

  useEffect(() => {
    fetchIssueTypes.submit()
    fetchRoleList.submit()
    fetchAllUserList.submit({})
    fetchStatusList.submit()
  }, [])

  useEffect(() => {
    if (projectUrn) {
      fetchProjectUserList.submit({ projectUrn })
    }
  }, [projectUrn])

  const { NavbarDropDown, opened } = useNavbar({
    navModules: getModulesLinkFromEnv(envLinks, projectUrn),
  })

  const onChangeTab = (tabIndex: number) => {
    try {
      const links = JSON.parse(envLinks)

      if (tabIndex === 1 || !links.chm) {
        return
      }

      window.location.href = `https://${links.chm}${
        projectUrn ? `?projectUrn=${projectUrn}` : ''
      }`
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <WithHeader
      NavbarDropDown={NavbarDropDown}
      openedNavbarDropDown={opened}
      loading={loading}
      brandSecondary={brandSecondary}
      Tabs={
        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={1}
          headerStyles={{
            fontSize: '14px',
            height: '49px',
            gap: 19,
            marginTop: 'auto',
            marginBottom: '-1px',
            borderBottom: 'none',
          }}
          contentStyles={{
            height: '100%',
            paddingTop: '0',
            display: 'flex',
          }}
          tabs={[
            {
              title: 'Чек-листы',
              content: null,
            },
            {
              title: 'Замечания',
              content: null,
            },
          ]}
        />
      }
      title='Замечания'
    >
      {children}
    </WithHeader>
  )
}
