import * as chmApi from '@gmini/chm-api-sdk'
import { assertNever } from '@gmini/utils'

export function getUrlKey(
  source: chmApi.AssigneeSource,
  {
    urlKeyUser,
    urlKeyRole,
    urlKeyCompany,
  }: { urlKeyUser: string; urlKeyRole: string; urlKeyCompany?: string },
) {
  switch (source) {
    case chmApi.AssigneeSource.USER: {
      return urlKeyUser
    }
    case chmApi.AssigneeSource.ROLE: {
      return urlKeyRole
    }
    case chmApi.AssigneeSource.COMPANY: {
      return urlKeyCompany || '' //TODO сделать обязательным, когда добавим компании для всех остальных сущностей
    }
    default: {
      assertNever('Unexpected source', source as never)
    }
  }
}
