import { Popover } from '@material-ui/core'
import React, { useRef, useState } from 'react'

import {
  Container,
  SelectedItem,
  StatusItem,
  StatusItemBadge,
  SelectArow,
} from './ColoredSelect.styled'
import { ColoredSelectItem } from './ColoredSelect.types'

type StatusSelectProps<T> = {
  selectedItem: T | undefined
  list: T[]
  onChange?: (item: T) => void
  disabled?: boolean
}

export const ColoredSelect = <T extends ColoredSelectItem>({
  list,
  selectedItem,
  onChange,
  disabled,
}: StatusSelectProps<T>) => {
  const [statusPopupOpen, setStatusPopupOpen] = useState(false)

  const anchorEl = useRef<HTMLDivElement>(null)

  const onClickSelectItem = (item: T) => {
    setStatusPopupOpen(false)
    onChange?.(item)
  }

  const canSelect = list.length > 0

  return (
    <Container>
      <SelectedItem
        active={canSelect}
        disabled={disabled}
        color={selectedItem?.color || '#E5E7F1'}
        onClick={canSelect ? () => setStatusPopupOpen(true) : undefined}
        ref={anchorEl}
      >
        {selectedItem?.name || 'Не задан'}
        {canSelect ? <SelectArow color='#fff' width='14px' /> : null}
      </SelectedItem>
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={statusPopupOpen}
        onClose={() => setStatusPopupOpen(false)}
      >
        {list.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <StatusItem onClick={() => onClickSelectItem(item)} key={idx}>
            <StatusItemBadge color={item.color} />
            {item.name}
          </StatusItem>
        ))}
      </Popover>
    </Container>
  )
}
