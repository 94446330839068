import * as ismApi from '@gmini/ism-api-sdk'

import { allowedFiltersService } from '@gmini/helpers'

export const {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} = allowedFiltersService({
  fetchAllowedFiltersApi: ismApi.GTechIssueTemplate.fetchAllowedFilters,
})
