import { PlusCircle } from '@gmini/ui-kit'
import { ChangeEventHandler } from 'react'

import { Wrapper, Input, Label } from './AttachFile.styled'
import { AttachFileSize, AttachFileVariant } from './AttachFile.types'

type AttachFileProps = {
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  buttonText?: string
  variant?: AttachFileVariant
  size?: AttachFileSize
}

export const AttachFile = ({
  onChange,
  disabled,
  buttonText,
  variant = 'default',
  size = 'medium',
}: AttachFileProps) => (
  <Wrapper>
    <Label size={size} htmlFor='file' disabled={disabled} variant={variant}>
      <PlusCircle />
      {buttonText}
    </Label>
    <Input
      multiple={true}
      id='file'
      type='file'
      name='file'
      onChange={event => {
        onChange?.(event)
        event.currentTarget.value = ''
      }}
    />
  </Wrapper>
)
