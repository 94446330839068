import { useStore } from 'effector-react'
import moment from 'moment'

import { LongText } from '@gmini/ui-kit'

import { ReactNode } from 'react'

import {
  buildShortUserLabel,
  buildUserLabel,
  declensionsOfNum,
} from '@gmini/helpers'

import * as api from '@gmini/ism-api-sdk'

import { UserBadge } from '@gmini/components'

import {
  FULL_DISPLAY_DATE_FORMAT,
  defaultIssueStatusColor,
  issueStatusColorMap,
  issueAnswerStatusDescMap,
  DEFAULT_DISPLAY_DATE_FORMAT,
} from '../../../constants'

import { projectUserList$ } from '../../user.store'

import { issueTypes$ } from '../../issueTypes.store'

import { fetchIssueHistoryPending$, issueHistory$ } from '../../issue.store'

import { issueStatusList$ } from '../../issueStatus.store'

import { roleList$ } from '../../role.store'

import {
  HistoryItem,
  ChangeInfo,
  UserLabel,
  Date,
  Operation,
  Item,
  ItemLabel,
  ItemValue,
  Wrapper,
  ChangeBody,
  FileWrapper,
  NoHistoryMessage,
  Circle,
  FileItem,
  ItemsWrapper,
  Status,
  ItemValueThrough,
  Separator,
  ItemValueInner,
  ArrowRight,
  File,
  VerticalLine,
  FileWithCross,
} from './EditIssuePopupHistoryChanges.styled'

type EditIssuePopupHistoryChangesProps = {
  issue: api.GTechIssue.IssuePopulated | api.GTechIssue
}

export const EditIssuePopupHistoryChanges = ({
  issue,
}: EditIssuePopupHistoryChangesProps) => {
  const issueHistory = useStore(issueHistory$)
  const fetchIssueHistoryPending = useStore(fetchIssueHistoryPending$)
  const userList = useStore(projectUserList$)
  const roleList = useStore(roleList$)
  const issueTypes = useStore(issueTypes$)
  const issueStatusList = useStore(issueStatusList$)

  if (!issueHistory && !fetchIssueHistoryPending) {
    return <NoHistoryMessage>Нет данных об активности</NoHistoryMessage>
  }

  const shortUserLabelOwner = buildUserLabel(
    userList.find(user => user.id === issue.ownerId) || null,
  )

  return (
    <Wrapper>
      {issueHistory?.list.map(
        ({ authorId, date, items, operation }, idx, list) => {
          const files = items.filter(api.GTechIssue.isHistoryFileItem)

          const addedFiles = files.filter(
            ({ operation }) => operation === 'ADDED',
          )
          const addedFilesLength = addedFiles.length

          const removedFiles = files.filter(
            ({ operation }) => operation === 'REMOVED',
          )
          const removedFilesLength = removedFiles.length

          const ownerHistoryItem =
            userList.find(({ id }) => id === authorId) || null

          const visibleSeparator = list.length > 1 && list.length - 1 !== idx

          const answerItem = items.filter(api.GTechIssue.isHistoryAnswerItem)

          return (
            <>
              <HistoryItem>
                <UserBadge>{buildShortUserLabel(ownerHistoryItem)}</UserBadge>
                <ChangeBody>
                  <ChangeInfo>
                    <UserLabel>
                      {ownerHistoryItem?.email ||
                        buildUserLabel(ownerHistoryItem)}
                    </UserLabel>
                    <Date>{moment(date).format(FULL_DISPLAY_DATE_FORMAT)}</Date>
                  </ChangeInfo>

                  <Operation>
                    <VerticalLine />
                    <Circle />
                    {operationMap[operation]} замечание
                  </Operation>

                  <ItemsWrapper>
                    {items.map((item, idx) => {
                      if (
                        api.GTechIssue.isHistoryFileItem(item) ||
                        api.GTechIssue.isHistoryAnswerItem(item)
                      ) {
                        return null
                      }

                      let preparedNewValue: ReactNode = item.newValue ? (
                        <LongText partSize={15}>
                          {item.newValue.toString()}
                        </LongText>
                      ) : (
                        ''
                      )
                      let preparedOldValue: ReactNode = item.oldValue ? (
                        <LongText partSize={15}>
                          {item.oldValue.toString()}
                        </LongText>
                      ) : (
                        ''
                      )
                      let preparedItemLabel: ReactNode = api.GTechIssue.isHistoryItemStatus(
                        item,
                      )
                        ? ''
                        : fieldLabelMap[item.field]

                      if (api.GTechIssue.isHistoryItemAssigneeUserId(item)) {
                        const { oldValue, newValue } = item
                        preparedOldValue = (
                          <LongText partSize={15}>
                            {buildUserLabel(
                              userList.find(user => user.id === oldValue) ||
                                null,
                            )}
                          </LongText>
                        )
                        preparedNewValue = (
                          <LongText partSize={15}>
                            {buildUserLabel(
                              userList.find(user => user.id === newValue) ||
                                null,
                            )}
                          </LongText>
                        )
                      } else if (
                        api.GTechIssue.isHistoryItemAssigneeRoleId(item)
                      ) {
                        const { oldValue, newValue } = item
                        preparedOldValue = (
                          <LongText partSize={15}>
                            {roleList.find(role => role.id === oldValue)
                              ?.title || ''}
                          </LongText>
                        )
                        preparedNewValue = (
                          <LongText partSize={15}>
                            {roleList.find(role => role.id === newValue)
                              ?.title || ''}
                          </LongText>
                        )
                      } else if (api.GTechIssue.isHistoryDeadlineItem(item)) {
                        const { oldValue, newValue } = item
                        preparedOldValue = (
                          <LongText partSize={15}>
                            {moment(oldValue).format(
                              DEFAULT_DISPLAY_DATE_FORMAT,
                            )}
                          </LongText>
                        )
                        preparedNewValue = (
                          <LongText partSize={15}>
                            {moment(newValue).format(
                              DEFAULT_DISPLAY_DATE_FORMAT,
                            )}
                          </LongText>
                        )
                      } else if (
                        api.GTechIssue.isHistoryItemIssueTypeId(item)
                      ) {
                        const { oldValue, newValue } = item
                        preparedOldValue = (
                          <LongText partSize={15}>
                            {issueTypes.find(
                              issueType => issueType.id === oldValue,
                            )?.name || ''}
                          </LongText>
                        )

                        preparedNewValue = (
                          <LongText partSize={15}>
                            {issueTypes.find(
                              issueType => issueType.id === newValue,
                            )?.name || ''}
                          </LongText>
                        )
                      } else if (api.GTechIssue.isHistoryItemStatus(item)) {
                        const { newValue } = item

                        const newStatusColor = newValue
                          ? issueStatusColorMap[newValue]
                          : defaultIssueStatusColor

                        preparedNewValue = (
                          <Status background={newStatusColor}>
                            {newValue
                              ? issueStatusList.find(
                                  ({ status }) => status === newValue,
                                )?.name
                              : ''}
                          </Status>
                        )

                        preparedItemLabel =
                          operation === 'UPDATED'
                            ? 'Изменен статус на'
                            : 'Статус'
                      }

                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Item key={idx}>
                          <VerticalLine />
                          <Circle />
                          <ItemLabel>{preparedItemLabel}</ItemLabel>

                          <ItemValue>
                            {operation === 'UPDATED' &&
                              !api.GTechIssue.isHistoryItemStatus(item) && (
                                <>
                                  <ItemValueThrough>
                                    {preparedOldValue}
                                  </ItemValueThrough>
                                  <ArrowRight color='#a2a3b7' />
                                </>
                              )}
                            <ItemValueInner>{preparedNewValue}</ItemValueInner>
                          </ItemValue>
                        </Item>
                      )
                    })}

                    {answerItem.map(({ status, id, description }) => {
                      const itemLabel = issueAnswerStatusDescMap[status]
                      const color = replyStatusColorMap[status] || '#a2a3b7'

                      return (
                        <>
                          <Item key={id}>
                            <VerticalLine />
                            <Circle color={color} />
                            <ItemLabel color={color}>
                              Официальный ответ {itemLabel}
                            </ItemLabel>
                          </Item>
                          <ItemValue m='4.5px 0' size='medium'>
                            {description}
                          </ItemValue>
                        </>
                      )
                    })}

                    {operation === 'ADDED' && (
                      <Item>
                        <VerticalLine />
                        <Circle />
                        <ItemLabel>Владелец замечания</ItemLabel>
                        <ItemValue>{shortUserLabelOwner}</ItemValue>
                      </Item>
                    )}
                    {addedFilesLength > 0 && (
                      <HistoryFileList list={addedFiles} operation='ADDED' />
                    )}
                    {removedFilesLength > 0 && (
                      <HistoryFileList
                        list={removedFiles}
                        hiddenVerticalLine={addedFilesLength !== 0}
                        operation='REMOVED'
                      />
                    )}
                  </ItemsWrapper>
                </ChangeBody>
              </HistoryItem>
              {visibleSeparator && <Separator />}
            </>
          )
        },
      )}
    </Wrapper>
  )
}

const replyStatusColorMap: Record<api.IssueAnswer.StatusEnum, string> = {
  DECLINED: '#BF3737',
  ACCEPTED: '#0bbb7b',
  PENDING: '#4c5ecf',
  DELETED: '#a2a3b7',
}

type HistoryFileListProps = {
  list: api.GTechIssue.HistoryFileItem[]
  hiddenVerticalLine?: boolean
  operation: api.HistoryChanges.Operations
}

const HistoryFileList = ({
  list,
  hiddenVerticalLine,
  operation,
}: HistoryFileListProps) => {
  const fileListLength = list.length

  const iconSize = list.length === 1 ? 'medium' : 'small'
  return (
    <>
      <Item>
        {!hiddenVerticalLine && <VerticalLine />}
        <Circle />
        <ItemLabel>
          {operation === 'ADDED' ? 'Добавил(а)' : 'Удалил(а)'} {fileListLength}{' '}
          {declensionsOfNum(fileListLength, ['файл', 'файла', 'файлов'])}
        </ItemLabel>
      </Item>
      <FileWrapper>
        {list.map(({ filename }, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <FileItem key={idx} gap={list.length === 1 ? '8px' : '12px'}>
            {operation === 'ADDED' ? (
              <File size={iconSize} />
            ) : (
              <FileWithCross size={iconSize} />
            )}
            <ItemValueInner>
              <LongText partSize={15}>{filename}</LongText>
            </ItemValueInner>
          </FileItem>
        ))}
      </FileWrapper>
    </>
  )
}

type FieldMap = Record<
  | 'issueTypeId'
  | 'name'
  | 'description'
  | 'assigneeUserId'
  | 'status'
  | 'assigneeRoleId'
  | 'deadline',
  string
>

const fieldLabelMap: FieldMap = {
  assigneeUserId: 'Назначен на',
  assigneeRoleId: 'Назначен на',
  description: 'Описание',
  issueTypeId: 'Дисциплина',
  name: 'Название',
  status: 'Статус',
  deadline: 'Срок',
}

const operationMap: Record<api.HistoryChanges.Operations, string> = {
  ADDED: 'Создал(а)',
  REMOVED: 'Удалил(а)',
  UPDATED: 'Изменил(а)',
}
