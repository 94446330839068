import { AppliedFilters } from '@gmini/components'
import {
  useAppliedFilterDateData,
  useAppliedFilterTypeData,
  useAppliedFilterUserData,
} from '@gmini/helpers'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useMemo } from 'react'

import {
  ASSIGNEE_IDS_TL,
  CREATED_DATE_RANGE_TL,
  ISSUE_TYPE_IDS_TL,
  OWNER_IDS_TL,
  UPDATED_DATE_RANGE_TL,
  SHORT_DISPLAY_DATE_FORMAT,
  UPDATED_DATE_CODE_TL,
  CREATED_DATE_CODE_TL,
} from '../../../constants'

import { issueTypes$ } from '../../issueTypes.store'
import { projectUserList$ } from '../../user.store'

type IssueTemplateListAppliedFiltersProps = {
  onChange?: (query: URLSearchParamsCustom) => void
}

export const IssueTemplateListAppliedFilters = ({
  onChange,
}: IssueTemplateListAppliedFiltersProps) => {
  const issueTypes = useStore(issueTypes$)
  const userList = useStore(projectUserList$)

  const preparedIssueTypes = useAppliedFilterTypeData({
    name: 'Дисциплина',
    urlKey: ISSUE_TYPE_IDS_TL,
    typeList: issueTypes,
  })

  const preparedOwners = useAppliedFilterUserData({
    name: 'Автор',
    urlKey: OWNER_IDS_TL,
    userList,
  })

  const preparedAssignee = useAppliedFilterUserData({
    name: 'Назначено на',
    urlKey: ASSIGNEE_IDS_TL,
    userList,
  })

  const preparedUpdatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: UPDATED_DATE_CODE_TL,
    urlKeyValue: UPDATED_DATE_RANGE_TL,
    name: 'Изменен',
  })

  const preparedCreatedDate = useAppliedFilterDateData({
    dateFormat: SHORT_DISPLAY_DATE_FORMAT,
    urlKeyCode: CREATED_DATE_CODE_TL,
    urlKeyValue: CREATED_DATE_RANGE_TL,
    name: 'Создан',
  })

  const appliedFilters = useMemo(
    () =>
      [
        preparedIssueTypes,
        preparedUpdatedDate,
        preparedCreatedDate,
        preparedOwners,
        preparedAssignee,
      ].filter(({ value }) => value.length),
    [
      preparedIssueTypes,
      preparedUpdatedDate,
      preparedCreatedDate,
      preparedOwners,
      preparedAssignee,
    ],
  )

  return <AppliedFilters onChange={onChange} items={appliedFilters} />
}
