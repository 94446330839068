import { ReactNode } from 'react'
import { userInfo$ } from '@gmini/common/lib/auth/auth'
import { Header, HeaderLogoWrap, Logo } from '@gmini/ui-kit'
import { useKeycloak } from '@react-keycloak/web'
import { useStore } from 'effector-react'

import {
  HeaderWrapper,
  TabsWrapper,
  Wrapper,
  BrandContainer,
  BrandTitle,
  PageContentContainer,
  LinearProgress,
  LoadingIndicatorWrap,
  PageContentWrapper,
} from './WithHeader.styled'

type WithHeaderProps = {
  loading?: boolean
  children: ReactNode | ReactNode[]
  NavbarDropDown: JSX.Element
  openedNavbarDropDown: boolean
  Tabs?: ReactNode
  brandSecondary?: ReactNode
  title: string
}

export const WithHeader = ({
  children,
  loading,
  NavbarDropDown,
  openedNavbarDropDown,
  Tabs,
  title,
  brandSecondary,
}: WithHeaderProps) => {
  const { keycloak } = useKeycloak()

  const userInfo = useStore(userInfo$)

  if (!userInfo) {
    return null
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header
          onLogout={() => keycloak.logout()}
          brandSecondary={brandSecondary}
          brand={
            <BrandContainer active={openedNavbarDropDown}>
              <HeaderLogoWrap>
                <Logo />
              </HeaderLogoWrap>
              <BrandTitle>{title}</BrandTitle>
              {NavbarDropDown}
            </BrandContainer>
          }
          titleSecondary={Tabs ? <TabsWrapper>{Tabs}</TabsWrapper> : null}
          userInfo={userInfo}
        />
      </HeaderWrapper>
      <PageContentWrapper>
        <PageContentContainer>
          <LoadingIndicatorWrap>
            <LinearProgress show={loading} />
          </LoadingIndicatorWrap>
          {children}
        </PageContentContainer>
      </PageContentWrapper>
    </Wrapper>
  )
}
