import { useForm, Controller, useFieldArray } from 'react-hook-form'

import { useStore, useStoreMap } from 'effector-react'

import {
  Select,
  Tooltip,
  UploadedFile,
  ActionPopupWrapper,
  SelectByGroups,
  DatePicker,
} from '@gmini/ui-kit'

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Store } from 'effector'

import { assertNever, isNotEmpty } from '@gmini/utils'

import {
  fileValidationRules,
  maxCountFiles,
  maxCountErrorMessage,
  useFilesValidation,
} from '@gmini/helpers'

import {
  // ColoredSelect,
  FieldError,
  FieldLabel,
  FileValidationErrorsPopup,
} from '@gmini/components'

import * as chmApi from '@gmini/chm-api-sdk'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import dayjs from 'dayjs'

import { AttachFile } from '../../atoms'

import { createGTechIssue, createGTechIssuePending$ } from '../../issue.store'

import { FormValue, attachFiles } from '../../createGTechIssuePopup.store'

import { DEFAULT_DISPLAY_DATE_FORMAT, ZERO_SEARCH } from '../../../constants'

import { assigneeGroupList$, assigneeUserList$ } from '../../assigneeGroupList'

// import { issueStatusList$ } from '../../issueStatus.store'

import {
  resetTemplateList,
  templateList$,
  fetchIssueTemplateList,
} from './model'

import {
  TextArea,
  TextField,
  Form,
  FieldContainer,
  Separator,
  UploadFilesWrapper,
  FullSeparator,
  MultipleFieldRow,
  EmptyContainer,
  HeaderTitle,
} from './CreateGTechIssuePopup.styled'

const maxLengthTitleErrorMessage =
  'Максимальная допустимая длина названия - 256 символов'
const maxLengthDescriptionErrorMessage =
  'Максимальная допустимая длина описания - 256 символов'
const requiredErrorMessage = 'Это поле является обязательным'
const failDateErrorMessage = 'Некорректная дата'

type CreateGTechIssuePopupProps = {
  issueTypes$: Store<api.GTechIssueType[]>
  issueTypeId?: number
  onCreateIssue?: (issue: api.GTechIssue.IssuePopulated) => void
  projectUrn: string | null
  projectList: smApi.Project[]
  open: boolean
  onClose?: () => void
  loading?: boolean
  lockProjectSelect?: boolean
}

const templateLimit = 20
const defaultValues: FormValue = {
  assignee: null,
  issueType: null,
  name: '',
  description: '',
  files: [],
  project: null,
  template: null,
  deadline: null,
}

export const CreateGTechIssuePopup = ({
  issueTypes$,
  issueTypeId,
  onCreateIssue,
  projectUrn,
  projectList,
  open,
  onClose,
  loading,
  lockProjectSelect,
}: CreateGTechIssuePopupProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormValue>({ mode: 'onChange', defaultValues })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'files',
  })

  const filesState = watch('files')
  const project = watch('project')
  const template = watch('template')

  const assigneeUsers = useStore(assigneeUserList$)
  const assigneeGroupList = useStore(assigneeGroupList$)
  // const statusList = useStore(issueStatusList$) //TODO вернуть, во время выполнения задачи — US_GS_2210 / Замечания / Статус "Черновик" - возможность при создании замечания выбирать между двух статусов - "Черновик" и "Назначено"
  // const preparedStatusList = statusList.filter(
  //   ({ status }) =>
  //     api.IssueStatus.Enum.NOT_STARTED === status ||
  //     api.IssueStatus.Enum.DRAFT === status,
  // )
  const issueTypes = useStore(issueTypes$)
  const fetchIssueTemplateListPending = useStore(
    fetchIssueTemplateList.pending$,
  )
  const [
    openedFileValidationErrorsPopup,
    setOpenedFileValidationErrorsPopup,
  ] = useState(false)

  // const [status, setStatus] = useState<PreparedIssueStatus | null>(null)

  const [templateSearchValue, setTemplateSearchValue] = useState('')
  const [offsetTemplateList, setOffsetTemplateList] = useState(0)
  const createGTechIssuePending = useStore(createGTechIssuePending$)
  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn),
    [projectList, projectUrn],
  )

  const { templateList, total: totalTemplates } = useStoreMap({
    store: templateList$,
    keys: [templateSearchValue],
    fn: ({ byId$, ids$, totalTemplates$ }, [key]) => {
      const search = key || ZERO_SEARCH
      const idsList = ids$[search]

      if (idsList) {
        return {
          templateList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalTemplates$,
        }
      }

      return { templateList: [], total: null }
    },
  })

  useEffect(() => {
    if (!open) {
      resetTemplateList()
      setOffsetTemplateList(0)
    }
  }, [open])

  useEffect(() => {
    if (project) {
      resetTemplateList()
      setValue('assignee', null)
      setValue('description', '')
      setValue('issueType', null)
      setValue('name', '')
      setValue('template', null)
      setValue('deadline', null)
    }
  }, [project, setValue])

  useEffect(() => {
    if (project) {
      fetchIssueTemplateList({
        limit: templateLimit,
        offset: offsetTemplateList,
        filter: templateSearchValue.toLocaleLowerCase(),
        projectUrn: project?.urn,
      })
    }
  }, [offsetTemplateList, templateSearchValue, project])

  const {
    fileErrorMessages,
    validateFiles,
    clearFileErrors,
  } = useFilesValidation({
    rules: fileValidationRules,
    onError: () => setOpenedFileValidationErrorsPopup(true),
  })

  useEffect(() => {
    if (template) {
      const assignee = assigneeUsers.find(u => u.id === template.assigneeUserId)

      setValue('assignee', assignee || null)
      setValue('description', template.description || '')
      setValue(
        'issueType',
        issueTypes.find(({ id }) => id === template.issueTypeId) || null,
      )
      setValue('name', template.name || '')
    }
  }, [assigneeUsers, issueTypes, setValue, template])

  useEffect(() => {
    if (selectedProject) {
      setValue('project', selectedProject)
    }
  }, [selectedProject, setValue])

  const onSubmit = async (data: FormValue) => {
    try {
      const {
        name,
        assignee,
        description,
        files,
        issueType,
        project,
        deadline,
      } = data

      if (!project) {
        throw new Error('Не выбран проект')
      }

      if (files) {
        attachFiles({ files })
      }

      onCloseHandler()

      const params: api.GTechIssue.CreateIssueParams = {
        name,
        description,
        issueTypeId: issueType?.id,
        projectUrn: project?.urn,
        deadline: deadline ? dayjs(deadline).toISOString() : undefined,
      }

      switch (assignee?.source) {
        case chmApi.AssigneeSource.USER: {
          params.assigneeUserId = assignee?.id
          break
        }
        case chmApi.AssigneeSource.ROLE: {
          params.assigneeRoleId = assignee?.id
          break
        }
        case chmApi.AssigneeSource.COMPANY: {
          break
        }

        default: {
          if (assignee) {
            return assertNever('Unexpected source', assignee.source)
          }
        }
      }

      const issue = await createGTechIssue(params)

      if (issueType?.id === issueTypeId || !issueTypeId) {
        onCreateIssue?.(issue)
      }
    } catch (error) {
      console.error('error :>> ', error)
    }
  }

  const onCloseHandler = () => {
    onClose?.()
    reset({ ...defaultValues, project: selectedProject })
  }

  const attachFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (!files) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), filesState?.length)

    append(allowFiles)
  }

  const isMaxCountFiles = fields.length >= maxCountFiles

  const onScrollList = useCallback(
    event => {
      const isScrolledToEnd =
        Number(event.target?.scrollTop) + Number(event.target?.clientHeight) >=
        Number(event.target?.scrollHeight) - 20

      if (isScrolledToEnd && templateList?.length < Number(totalTemplates)) {
        setOffsetTemplateList(prevValue => prevValue + templateLimit)
      }
    },
    [templateList?.length, totalTemplates],
  )

  const onResetTemplate = () => {
    setValue('template', null)
  }

  // const onChangeStatus = (item: PreparedIssueStatus) => { //TODO вернуть, во время выполнения задачи — US_GS_2210 / Замечания / Статус "Черновик" - возможность при создании замечания выбирать между двух статусов - "Черновик" и "Назначено"
  //   setStatus(item)
  // }

  return (
    <>
      <FileValidationErrorsPopup
        open={openedFileValidationErrorsPopup}
        onClose={() => setOpenedFileValidationErrorsPopup(false)}
        errors={fileErrorMessages}
      />
      <ActionPopupWrapper
        open={open}
        onClose={onCloseHandler}
        onSubmit={handleSubmit(onSubmit)}
        width='600px'
        submitButtonTitle='Создать замечание'
        title={
          <HeaderTitle>
            Новое замечание{' '}
            {/* <ColoredSelect //TODO вернуть, во время выполнения задачи — US_GS_2210 / Замечания / Статус "Черновик" - возможность при создании замечания выбирать между двух статусов - "Черновик" и "Назначено"
              list={preparedStatusList}
              selectedItem={status}
              onChange={onChangeStatus}
            /> */}
          </HeaderTitle>
        }
        pending={loading || createGTechIssuePending}
      >
        {({ existScroll }) => (
          <Form>
            <FieldContainer>
              <FieldLabel required>Проект</FieldLabel>
              <Controller
                name='project'
                control={control}
                rules={{
                  required: { message: requiredErrorMessage, value: true },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={projectList}
                    getOptionLabel={(option: smApi.Project) => option.name}
                    placeholder='Выберите проект'
                    error={Boolean(errors.project)}
                    allowDelete={false}
                    lockSelect={lockProjectSelect}
                  />
                )}
              />
              <FieldError hidden={!errors.project}>
                {errors.project && 'message' in errors.project
                  ? errors.project.message
                  : null}
              </FieldError>
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>На основе шаблона</FieldLabel>
              <Controller
                name='template'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={templateList}
                    getOptionLabel={(option: api.GTechIssueTemplate) =>
                      option.name
                    }
                    placeholder='Выберите шаблон замечания'
                    onInputChange={(value: string) =>
                      setTemplateSearchValue(value)
                    }
                    onScrollList={onScrollList}
                    onReset={onResetTemplate}
                    disabled={!project || loading}
                    loading={fetchIssueTemplateListPending}
                  />
                )}
              />
            </FieldContainer>
            <FullSeparator />

            <MultipleFieldRow>
              <FieldContainer>
                <FieldLabel required>Название</FieldLabel>
                <Controller
                  name='name'
                  control={control}
                  rules={{
                    required: { message: requiredErrorMessage, value: true },
                    maxLength: {
                      message: maxLengthTitleErrorMessage,
                      value: 256,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Укажите название'
                      error={Boolean(errors.name)}
                      disabled={loading || createGTechIssuePending || !project}
                      clearable
                    />
                  )}
                />
                <FieldError hidden={!errors.name}>
                  {errors.name && 'message' in errors.name
                    ? errors.name.message
                    : null}
                </FieldError>
              </FieldContainer>

              <FieldContainer>
                <FieldLabel>Срок</FieldLabel>
                <Controller
                  name='deadline'
                  control={control}
                  rules={{
                    validate: value =>
                      value && !dayjs(value).isValid()
                        ? failDateErrorMessage
                        : true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      label='Укажите срок'
                      error={Boolean(errors.deadline)}
                      value={field.value}
                      disabled={loading || createGTechIssuePending || !project}
                      background='#f4f4f8'
                      onReset={() => {
                        setValue('deadline', null)
                        clearErrors('deadline')
                      }}
                      onError={(error, value) => {
                        if (value) {
                          setError('deadline', {
                            message: failDateErrorMessage,
                          })
                        }
                      }}
                      format={DEFAULT_DISPLAY_DATE_FORMAT}
                    />
                  )}
                />
                <FieldError hidden={!errors.deadline}>
                  {errors.deadline && 'message' in errors.deadline
                    ? errors.deadline.message
                    : null}
                </FieldError>
              </FieldContainer>
            </MultipleFieldRow>

            <MultipleFieldRow>
              <FieldContainer>
                <FieldLabel required>Дисциплина</FieldLabel>
                <Controller
                  name='issueType'
                  control={control}
                  rules={{
                    required: { message: requiredErrorMessage, value: true },
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={issueTypes}
                      getOptionLabel={(option: api.GTechIssueType) =>
                        option.name
                      }
                      placeholder='Задайте дисциплину'
                      error={Boolean(errors.issueType)}
                      disabled={loading || createGTechIssuePending || !project}
                    />
                  )}
                />
                <FieldError hidden={!errors.issueType}>
                  {errors.issueType && 'message' in errors.issueType
                    ? errors.issueType.message
                    : null}
                </FieldError>
              </FieldContainer>

              <FieldContainer>
                <FieldLabel>Назначено на</FieldLabel>

                <Controller
                  name='assignee'
                  control={control}
                  render={({ field }) => (
                    <SelectByGroups
                      {...field}
                      groups={assigneeGroupList}
                      getOptionLabel={value => value.label}
                      placeholder='Выберите пользователя/роль'
                      error={Boolean(errors.assignee)}
                      disabled={loading || createGTechIssuePending || !project}
                    />
                  )}
                />
                <FieldError hidden={!errors.assignee}>
                  {errors.assignee && 'message' in errors.assignee
                    ? errors.assignee.message
                    : null}
                </FieldError>
              </FieldContainer>
            </MultipleFieldRow>

            <FieldContainer>
              <FieldLabel>Описание</FieldLabel>

              <Controller
                name='description'
                control={control}
                rules={{
                  maxLength: {
                    message: maxLengthDescriptionErrorMessage,
                    value: 256,
                  },
                }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    error={Boolean(errors.description)}
                    placeholder='Укажите описание'
                    disabled={loading || createGTechIssuePending || !project}
                  />
                )}
              />

              <FieldError hidden={!errors.description}>
                {errors.description && 'message' in errors.description
                  ? errors.description.message
                  : null}
              </FieldError>
            </FieldContainer>

            <FieldContainer>
              <FieldLabel>Документы</FieldLabel>
              <Separator />

              {fields.length !== 0 && (
                <UploadFilesWrapper>
                  {fields.map((item, index) => (
                    <UploadedFile
                      key={item.id}
                      title={filesState?.[index]?.name || ''}
                      onDelete={() => {
                        clearFileErrors()
                        remove(index)
                      }}
                    />
                  ))}
                </UploadFilesWrapper>
              )}

              <Tooltip
                title={isMaxCountFiles ? maxCountErrorMessage : ''}
                styleContent={{
                  width: 'min-content',
                  marginBottom: '10px',
                  marginTop: '16px',
                }}
                noMaxWidth
              >
                <AttachFile
                  disabled={
                    isMaxCountFiles ||
                    loading ||
                    createGTechIssuePending ||
                    !project
                  }
                  buttonText='Прикрепить'
                  onChange={attachFile}
                  size='small'
                  variant='blue'
                />
              </Tooltip>
            </FieldContainer>

            {existScroll && <EmptyContainer />}
          </Form>
        )}
      </ActionPopupWrapper>
    </>
  )
}
