import {
  passesFilterDate,
  passesFilterType,
  passesFilterUser,
} from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'

import { IssueTemplateListFilters } from './IssueTemplateList.types'

export const matchIssueTemplateToFilters = (
  issueTemplate: api.GTechIssueTemplate,
  filters: IssueTemplateListFilters,
) => {
  const isPassAssigneeFilter = passesFilterUser(
    filters.assigneeIds,
    issueTemplate.assigneeUserId,
  )

  const isPassOwnerFilter = passesFilterUser(
    filters.ownerIds,
    issueTemplate.ownerId,
  )

  const isPassTypeFilter = passesFilterType(
    filters.issueTypeIds,
    issueTemplate.issueTypeId,
  )

  const currentDate = new Date()

  const {
    enrichedUpdatedDateRange,
    enrichedCreatedDateRange,
  } = filterDateEnrichment(currentDate, filters)

  const isPassUpdatedDateFilter = passesFilterDate(
    enrichedUpdatedDateRange,
    issueTemplate.updatedAt,
  )

  const isPassCreatedDateFilter = passesFilterDate(
    enrichedCreatedDateRange,
    issueTemplate.createdAt,
  )

  const isPassSearchFilter = issueTemplate.name
    .toLowerCase()
    .includes(filters.filter.toLowerCase())

  return (
    isPassAssigneeFilter &&
    isPassOwnerFilter &&
    isPassTypeFilter &&
    isPassUpdatedDateFilter &&
    isPassCreatedDateFilter &&
    isPassSearchFilter
  )
}
