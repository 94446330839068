/* eslint-disable react/display-name */
import {
  MouseEvent,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react'

import {
  Button,
  PlusCircle,
  IconButton,
  ConfirmActionPopover,
  Update,
  Tooltip,
} from '@gmini/ui-kit'

import moment from 'moment'

import { useSnackbar } from 'notistack'

import {
  isNotEmpty,
  URLSearchParamsCustom,
  useDebounce,
  useQuery,
} from '@gmini/utils'

import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useStoreMap, useStore } from 'effector-react'

import { combine } from 'effector'

import { useHistory } from 'react-router'

import {
  Table,
  TableColumn,
  TabContainer,
  TableContent,
  TableContentContainer,
  ActionPanel,
  SearchInput,
  Magnifier,
  SearchContainer,
  NameBold,
  HighlightOffIcon,
  InfiniteScroll,
  EntityCreatedSnackbar,
} from '@gmini/components'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import { issueTypes$ } from '../../issueTypes.store'
import { allUserList$, projectUserList$ } from '../../user.store'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  PROJECT_URN,
  SEARCH_TL,
  TEMPLATE_ID,
  ZERO_SEARCH,
  PROJECT_IS_NOT_SELECTED_ERROR,
} from '../../../constants'

import { UpsertIssueTemplatePopup } from '../UpsertIssueTemplatePopup'

import { toggleOpenPopup as toggleOpenUpsertIssueTemplatePopup } from '../UpsertIssueTemplatePopup/upsertIssueTemplatePopup.store'

import {
  createIssueTemplate,
  deleteIssueTemplate,
  updateIssueTemplate,
} from '../../issueTemplate.action'

import { FormValueUpsertIssueTemplate } from '../UpsertIssueTemplatePopup/UpsertIssueTemplatePopup.types'

import { IssueTemplateListFilterPanel } from '../IssueTemplateListFilterPanel'

import { fetchAllowedFiltersPending$ } from '../IssueTemplateListFilterPanel/model'

import { IssueTemplateListAppliedFilters } from '../IssueTemplateListAppliedFilters'

import {
  resetTemplateList,
  templateList$,
  fetchIssueTemplateList,
  fetchIssueTemplateListWithEnrichDateFilter,
  FetchIssueTemplateListWithPrepareDateRange,
  addIssueTemplateToIds,
} from './model'
import { getIssueTemplateListFilters } from './getIssueTemplateListFilters'
import { matchIssueTemplateToFilters } from './matchIssueTemplateToFilters'

type IssueTemplateListProps = {
  projectList: smApi.Project[]
}

export const IssueTemplateList = ({ projectList }: IssueTemplateListProps) => {
  const history = useHistory()

  const query = useQuery()
  const filters = useMemo(() => getIssueTemplateListFilters(query), [query])
  const projectUrn = query.get(PROJECT_URN) || null
  const templateIdFromUrl = query.get(TEMPLATE_ID) || ''
  const selectedTemplateId = templateIdFromUrl
    ? Number(templateIdFromUrl)
    : null

  const [searchValue, setSearchValue] = useState(filters.filter)
  const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)
  const [
    selectedTemplate,
    setSelectedTemplate,
  ] = useState<api.GTechIssueTemplate | null>(null)
  const [offset, setOffset] = useState(defaultOffset)

  const fetchIssueTemplateListCb = useCallback(
    (params: FetchIssueTemplateListWithPrepareDateRange) => {
      fetchIssueTemplateListWithEnrichDateFilter({
        ...filters,
        projectUrn,
        ...params,
      })
    },
    [filters, projectUrn],
  )

  const debouncedFetch = useDebounce({
    handler: () => {
      const formattedSearchValue = searchValue.trim().toLowerCase()
      if (filters.filter?.trim().toLowerCase() === formattedSearchValue) {
        return
      }
      resetTemplateList()
      setOffset(0)
      fetchIssueTemplateListCb({
        offset: 0,
        limit,
        filter: formattedSearchValue,
      })
      query.set(SEARCH_TL, searchValue)
      history.replace({ search: query.toString() })
    },
    delay: 500,
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRefCurrent = tableContainerRef.current
  const templatePending = useStore(templatePending$)
  const projectUserList = useStore(projectUserList$)
  const allUserList = useStore(allUserList$)
  const issueTypes = useStore(issueTypes$)
  const [columns, setColumns] = useState(getTemplateListColumnOrderFromStorage)

  const { templateList, total, byId } = useStoreMap({
    store: templateList$,
    keys: [searchValue],
    fn: ({ byId$, ids$, totalTemplates$ }, [key]) => {
      const search = key || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          templateList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalTemplates$ || 0,
          byId: byId$,
        }
      }

      return { templateList: [], total: 0, byId: byId$ }
    },
  })

  useEffect(() => () => resetTemplateList(), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedFetch(), [searchValue])

  const listIsNotOver = total > offset

  useEffect(() => {
    if (selectedTemplateId) {
      const openIssueFromLink = async () => {
        const currentIssue = byId[selectedTemplateId]
          ? byId[selectedTemplateId]
          : await api.GTechIssueTemplate.fetchMostRecent.defaultContext({
              id: selectedTemplateId,
            })
        setSelectedTemplate(currentIssue)
        toggleOpenUpsertIssueTemplatePopup(true)
      }
      openIssueFromLink()
    }
  }, [byId, selectedTemplateId])

  useEffect(() => {
    setColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    resetTemplateList()
    fetchIssueTemplateListCb({ limit, offset: 0 })
    setOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUrn])

  useEffect(() => {
    // Для пагинации
    if (!projectUrn || offset === 0) {
      return
    }

    fetchIssueTemplateListCb({ limit, offset })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, projectUrn])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: api.GTechIssueTemplate.IssueTemplate
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      onClick: props => {
        query.set(TEMPLATE_ID, props.item.id.toString())
        history.replace(query.toString())
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Создать копию',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      onClick: props => {
        setAnchorElPopover(props.event.target as Element)
        setSelectedTemplate(props.item)
      },
      icon: Icon.DELETE,
    },
  ])

  const handleClosePopover = () => {
    setAnchorElPopover(null)
    setSelectedTemplate(null)
  }

  const onDeleteTemplate = async () => {
    if (!selectedTemplate) {
      return
    }

    await deleteIssueTemplate({
      id: selectedTemplate.id,
      version: selectedTemplate.version,
    })
    resetTemplateList()
    fetchIssueTemplateListCb({ offset: 0, limit })
    setOffset(0)
    handleClosePopover()
  }

  const resetInfinityScroll = () => {
    tableContainerRefCurrent?.scrollTo(0, 0)
    resetTemplateList()
    fetchIssueTemplateListCb({ limit, offset: 0 })
    setOffset(0)
  }

  const tableList = useMemo(
    (): IssueTemplateTableRow[] =>
      templateList.map(template => ({
        ...template,
        issueTypeName: issueTypes.find(type => type.id === template.issueTypeId)
          ?.name,
        assignee: allUserList.find(({ id }) => template.assigneeUserId === id),
        owner: allUserList.find(user => user.id === template.ownerId),
      })),
    [templateList, issueTypes, allUserList],
  )

  const onSubmitUpsertIssueTemplate = async ({
    assigneeUser,
    issueType,
    project,
    ...otherData
  }: FormValueUpsertIssueTemplate) => {
    try {
      const assigneeUserId = assigneeUser?.id
      const issueTypeId = issueType?.id
      const projectUrn = project?.urn
      if (!projectUrn) {
        throw new Error('Не выбран проект')
      }

      let issueTemplate: api.GTechIssueTemplate | null = null

      if (selectedTemplate) {
        issueTemplate = await updateIssueTemplate({
          id: selectedTemplate.id,
          version: selectedTemplate.version,
          assigneeUserId,
          issueTypeId,
          ...otherData,
        })
      } else {
        issueTemplate = await createIssueTemplate({
          assigneeUserId,
          issueTypeId,
          projectUrn,
          ...otherData,
        })

        if (!issueTemplate) {
          //Отсутствие шаблона тут является не штатным поведением
          return
        }

        const isMatchIssueToFilters = matchIssueTemplateToFilters(
          issueTemplate,
          filters,
        )

        notifyCreatedIssueTemplate(issueTemplate, isMatchIssueToFilters)

        if (isMatchIssueToFilters) {
          addIssueTemplateToIds({
            id: issueTemplate.id,
            search: filters.filter,
          })
        }
      }
    } catch (error) {
      console.error('error :>> ', error)
    }
  }

  const onChangeFilter = useCallback(
    (query: URLSearchParamsCustom) => {
      if (!projectUrn) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      const filters = getIssueTemplateListFilters(query)

      resetTemplateList()
      fetchIssueTemplateListCb({
        offset: 0,
        limit,
        projectUrn,
        ...filters,
      })
      setOffset(0)
    },
    [fetchIssueTemplateListCb, projectUrn],
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notifyCreatedIssueTemplate = useCallback(
    (issueTemplate: api.GTechIssueTemplate, isMatchIssueToFilters: boolean) => {
      let infoText: string | null =
        'Измените параметры фильтрации для отображения данного шаблона замечания в общем списке.'

      if (isMatchIssueToFilters) {
        infoText = null
      }

      enqueueSnackbar('', {
        content: key => (
          <EntityCreatedSnackbar
            id={key}
            message={`Новый шаблон замечания “${issueTemplate.name}” успешно создан.`}
            onClose={() => closeSnackbar()}
            infoText={infoText}
          />
        ),
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )

  return (
    <TabContainer>
      <UpsertIssueTemplatePopup
        issueTemplate={selectedTemplate}
        allUsers={allUserList}
        projectUsers={projectUserList}
        issueTypes={issueTypes}
        onSubmit={onSubmitUpsertIssueTemplate}
        submitButtonTitle={selectedTemplate ? 'Применить' : 'Создать'}
        title={selectedTemplate ? 'Редактирование шаблона' : 'Новый шаблон'}
        onClose={() => {
          query.delete(TEMPLATE_ID)
          history.replace({ search: query.toString() })
          setSelectedTemplate(null)
        }}
        projectList={projectList}
        projectUrn={projectUrn}
      />
      <ConfirmActionPopover
        message={
          <>
            Вы уверены, что хотите навсегда удалить{' '}
            <NameBold>{selectedTemplate?.name}?</NameBold> Все ссылки на ресурсы
            будут удалены. Это действие не может быть отменено.
          </>
        }
        title='Удалить шаблон'
        anchorEl={anchorElPopover}
        handleClose={handleClosePopover}
        actions={
          <>
            <Button
              size='regular'
              color='secondary'
              onClick={handleClosePopover}
            >
              Отмена
            </Button>
            <Button color='warning' onClick={onDeleteTemplate} size='regular'>
              Удалить
            </Button>
          </>
        }
      />
      <TableContentContainer>
        <IssueTemplateListFilterPanel
          onChange={onChangeFilter}
          projectUrn={projectUrn}
        />
        <TableContent>
          <ActionPanel>
            <SearchContainer style={{ marginRight: 'auto' }}>
              <SearchInput
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                placeholder='Поиск'
                width='230px'
              />
              <Magnifier />
              {searchValue ? (
                <HighlightOffIcon onClick={() => setSearchValue('')} />
              ) : null}
            </SearchContainer>
            <IssueTemplateListAppliedFilters onChange={onChangeFilter} />

            <Tooltip placement='top' title='Обновить список шаблонов'>
              <IconButton
                onClick={resetInfinityScroll}
                disabled={templatePending || !projectUrn}
              >
                <Update color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
            </Tooltip>

            <Button
              onClick={() => toggleOpenUpsertIssueTemplatePopup(true)}
              leftIcon={<PlusCircle width='24px' height='24px' />}
              disabled={templatePending}
            >
              Создать шаблон замечания
            </Button>
          </ActionPanel>

          {!templatePending && <ContextMenu />}

          <InfiniteScroll
            hasMore={tableList.length !== 0 && listIsNotOver}
            next={() => setOffset(prevValue => prevValue + limit)}
            triggersObserve={[tableList]}
          >
            <Table
              columns={columns}
              rows={tableList}
              onChangeColumns={setColumns}
              pending={templatePending}
              getRowKey={row => row.id}
              activeRowKey={ctxMenu.item?.item.id}
              totalRows={total || 0}
              onRowCtxMenu={(e, item) => {
                e.preventDefault()

                setCtxMenu({
                  coords: { x: e.clientX, y: e.clientY },
                  item: { item, event: e },
                })
              }}
              onClick={(e, item) => {
                query.set(TEMPLATE_ID, item.id.toString())
                history.push({ search: query.toString() })
              }}
            />
          </InfiniteScroll>
        </TableContent>
      </TableContentContainer>
    </TabContainer>
  )
}

const defaultOffset = 0
const limit = 20

type IssueTemplateTableRow = api.GTechIssueTemplate.IssueTemplate & {
  issueTypeName?: string
  assignee?: smApi.Auth.UserData
  owner?: smApi.Auth.UserData
}

const columns: TableColumn<IssueTemplateTableRow>[] = [
  {
    name: 'ID',
    field: 'id',
    thContent: 'ID',
    type: 'number',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
    cellStyle: { wordBreak: 'break-word' },
    style: { width: 'auto' },
    name: 'Название',
    field: 'name',
    visible: true,
  },
  {
    renderCell: ({ row }) => <>{row.issueTypeName}</>,
    name: 'Дисциплина',
    field: 'issueTypeId',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>{moment(row.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}</>
    ),
    name: 'Создан',
    field: 'createdAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.updatedAt
        ? moment(row.updatedAt).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не изменялся',
    name: 'Изменен',
    field: 'updatedAt',
    visible: true,
  },
  {
    field: 'assignee',
    renderCell: ({ row }) => {
      if (!row.assignee) {
        return <>{'Не назначен'}</>
      }
      return <>{row.assignee.name}</>
    },
    name: 'Назначено на',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    renderCell: ({ row }) => {
      if (!row.owner) {
        return <>Не найден</>
      }
      return <>{row.owner.name}</>
    },
    name: 'Автор',
    field: 'owner',
    visible: true,
  },
]

type ColumnSettings = {
  field: string
  visible: boolean
}

const getTemplateListColumnOrderFromStorage = (): TableColumn<IssueTemplateTableRow>[] => {
  const data = localStorage.getItem('templateListColumnOrder')
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as ColumnSettings[]

      return columns
        .slice()
        .sort((a, b) => {
          const aIdx = parsedData.findIndex(s => s.field === a.field)
          const bIdx = parsedData.findIndex(s => s.field === b.field)

          // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
          if (aIdx < 0 || bIdx < 0) {
            return 0
          }

          return aIdx - bIdx
        })
        .map(col => {
          const colFromLocalStorage = parsedData.find(
            ({ field }) => field === col.field,
          )

          return {
            ...col,
            visible:
              colFromLocalStorage === undefined
                ? true
                : colFromLocalStorage.visible,
          }
        })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
  return columns
}

const setColumnsToStorage = (next: TableColumn<IssueTemplateTableRow>[]) => {
  localStorage.setItem(
    'templateListColumnOrder',
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}

export const templatePending$ = combine(
  [
    createIssueTemplate.pending$,
    fetchIssueTemplateList.pending$,
    deleteIssueTemplate.pending$,
    updateIssueTemplate.pending$,
    fetchAllowedFiltersPending$,
  ],
  pendings => pendings.some(Boolean),
)

export type TemplateCtxMenuValue = {
  templateID?: string
}
