/* eslint-disable react/no-danger */
import { HorizontalThreeDots, Trash, Reply } from '@gmini/ui-kit'
import dompurify from 'dompurify'

import { UserBadge, DropdownMenu } from '@gmini/components'

import moment from 'moment'

import { useState } from 'react'

import { FULL_DISPLAY_DATE_FORMAT } from '../../../constants'

import {
  Container,
  AuthorLabel,
  Header,
  Body,
  CreatedAt,
  OpenCtxMenuButton,
  Text,
  Content,
} from './Comment.styled'

type CommentProps = {
  text: string
  authorLabel: string
  authorShortLabel: string
  createdAt: string
  onDelete?: (() => void) | null
  onReply?: (() => void) | null
  disabled?: boolean
  deleted?: boolean
}

export const Comment = ({
  authorLabel,
  authorShortLabel,
  createdAt,
  text,
  onDelete,
  onReply,
  disabled,
  deleted,
}: CommentProps) => {
  const [
    anchorDropdownMenu,
    setAnchorElDropdownMenu,
  ] = useState<HTMLButtonElement | null>(null)

  const handleCloseDropdownMenu = () => {
    setAnchorElDropdownMenu(null)
  }

  const dropDownMenuItems = []

  if (onReply) {
    dropDownMenuItems.push({
      name: 'Прокомментировать',
      onClick: () => {
        setAnchorElDropdownMenu(null)
        onReply()
      },
      icon: <Reply />,
    })
  }

  if (onDelete && !deleted) {
    dropDownMenuItems.push({
      name: 'Удалить',
      onClick: () => {
        setAnchorElDropdownMenu(null)
        onDelete()
      },
      icon: <Trash color='#353B60' />,
      disabled,
    })
  }

  const cleanHtml = {
    __html: dompurify.sanitize(deleted ? 'Комментарий удален' : text, {
      ADD_ATTR: ['target'],
    }),
  }

  return (
    <Container>
      <UserBadge>{authorShortLabel}</UserBadge>
      <Content>
        <Header>
          <AuthorLabel>{authorLabel}</AuthorLabel>
          <CreatedAt>
            {moment(createdAt).format(FULL_DISPLAY_DATE_FORMAT)}
          </CreatedAt>
          <DropdownMenu
            anchorEl={anchorDropdownMenu}
            handleClose={handleCloseDropdownMenu}
            items={dropDownMenuItems}
          />

          <OpenCtxMenuButton
            onClick={event => setAnchorElDropdownMenu(event.currentTarget)}
            disabled={disabled}
            active={Boolean(anchorDropdownMenu)}
            hidden={dropDownMenuItems.length === 0}
          >
            <HorizontalThreeDots />
          </OpenCtxMenuButton>
        </Header>
        <Body>
          <Text dangerouslySetInnerHTML={cleanHtml} />
        </Body>
      </Content>
    </Container>
  )
}
