import { createStore } from 'effector'

import * as api from '@gmini/ism-api-sdk'

import { defaultIssueStatusColor, issueStatusColorMap } from '../constants'

export const fetchStatusList = api.IssueStatus.fetchList.createContext()
export const fetchStatusListPending$ = fetchStatusList.pending$

export type PreparedIssueStatus = api.IssueStatus.Data & { color: string }

export const issueStatusList$ = createStore<PreparedIssueStatus[]>([]).on(
  fetchStatusList.doneData,
  (state, result) =>
    result.map(item => ({
      ...item,
      color: issueStatusColorMap[item.status] || defaultIssueStatusColor,
    })),
)
