import styled from 'styled-components'

export const EditorWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const EditorFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(183, 201, 219, 0.2);
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  padding: 0 5px 5px 0;
`
export const BodyAreaWrap = styled.div`
  display: flex;
  border-left: 1px solid rgba(183, 201, 219, 0.2);
  border-right: 1px solid rgba(183, 201, 219, 0.2);
  padding: 0 5px 5px 0;
`
export const BodyArea = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 20px;
  padding: 0 15px;
  flex: 1;
`

export const FooterLeftWrap = styled.div`
  padding-left: 5px;
`
