import styled, { css } from 'styled-components'

import { PageContentContainer as PageContentContainerInit } from '@gmini/common/lib/components'
import { LinearProgress as LinearProgressInit } from '@gmini/ui-kit/lib/LinearProgress'

export const TabsWrapper = styled.div`
  margin-right: auto;
  margin-left: 14px;
  display: flex;
  height: 100%;
`

export const HeaderWrapper = styled.div`
  flex-grow: 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 0;
`

type BrandContainerProps = {
  active?: boolean
}

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 6px;
  background: inherit;
  color: #00003d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.1px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  transition: 0.2s ease-out background-color;
  padding: 8px 18px;
  border-radius: 4px;
  position: relative;

  ${(props: BrandContainerProps) =>
    props.active &&
    css`
      background: #e5e7f1;
    `}
`

export const BrandTitle = styled.div`
  margin-right: 50px;
`

export const PageContentContainer = styled(PageContentContainerInit)`
  flex-direction: column;
  min-height: 0;
`

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`

export const LoadingIndicatorWrap = styled.div`
  position: relative;
  height: 0;
`

type LinearProgressProps = {
  show?: boolean
}

export const LinearProgress = styled(LinearProgressInit)`
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 1;
  ${(props: LinearProgressProps) => !props.show && 'opacity: 0;'}
`
