import { URLSearchParamsCustom, useQuery } from '@gmini/utils'
import { useHistory } from 'react-router-dom'
import { Button, ScrollBar } from '@gmini/ui-kit'

import { Bubble } from '../../atoms'

import {
  AppliedFiltersScrollableContent,
  AppliedFiltersScrollableWrapper,
  ResetAllButtonWrapper,
} from './AppliedFilters.styled'

import { AppliedFilter } from './AppliedFilters.types'

type AppliedFiltersProps<T> = {
  items: Array<T>
  onChange?: (query: URLSearchParamsCustom) => void
}

export const AppliedFilters = <T extends AppliedFilter>({
  items,
  onChange,
}: AppliedFiltersProps<T>) => {
  const history = useHistory()
  const query = useQuery()

  const onResetAll = () => {
    query.deleteSeveral(items.map(item => item.urlKey).flat())
    history.replace({ search: query.toString() })

    onChange?.(query)
  }

  const onResetFilter = (item: T) => {
    if (Array.isArray(item.urlKey)) {
      item.urlKey.forEach(key => query.delete(key))
    } else {
      query.delete(item.urlKey)
    }
    history.replace({ search: query.toString() })

    onChange?.(query)
  }

  return (
    <AppliedFiltersScrollableWrapper>
      <ScrollBar contentStyled={AppliedFiltersScrollableContent}>
        {items.map((item, idx) => (
          <Bubble
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            title={item.name}
            value={item.value}
            onResetFilter={onResetFilter ? () => onResetFilter(item) : null}
          />
        ))}
        <ResetAllButtonWrapper hidden={!onResetAll || items.length < 2}>
          <Button onClick={onResetAll} color='tertiary'>
            Очистить фильтры
          </Button>
        </ResetAllButtonWrapper>
      </ScrollBar>
    </AppliedFiltersScrollableWrapper>
  )
}
