/* eslint-disable react/display-name */
import {
  MouseEvent,
  useEffect,
  useRef,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from 'react'

import {
  Button,
  PlusCircle,
  IconButton,
  Update,
  Tooltip,
  Download,
} from '@gmini/ui-kit'
import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'
import {
  isNotEmpty,
  URLSearchParamsCustom,
  useDebounce,
  useQuery,
} from '@gmini/utils'

import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useStore, useStoreMap } from 'effector-react'

import { useHistory } from 'react-router'
import moment from 'moment'
import { userInfo$ } from '@gmini/common'
import { useSnackbar } from 'notistack'

import {
  Table,
  TableColumn,
  TabContainer,
  TableContent,
  TableContentContainer,
  ActionPanel,
  SearchInput,
  Magnifier,
  SearchContainer,
  StatusContainer,
  StatusIndicator,
  HighlightOffIcon,
  InfiniteScroll,
  AnchorLink,
  LinkedEntityTableItem,
  LinkedEntityTableItemName,
  EntityCreatedSnackbar,
} from '@gmini/components'
import { useAssignees, AssigneeListItem } from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import { CreateGTechIssuePopup } from '../CreateGTechIssuePopup'

import {
  issuePending$,
  issuesList$,
  resetIssueList,
  fetchIssueListWithExtraData,
  PreparedIssue,
  FetchIssueListWithExtraDataParams,
  addIssueToIds,
  fetchIssueXlsxWithExtraData,
  fetchXlsxGTechIssuePending$,
  FetchIssueListXLSXWithExtraDataParams,
} from '../../issue.store'

import { getAssigneeFromIssue } from '../../getAssigneeFromIssue'

import { EditIssuePopup } from '../EditIssuePopup'
import { issueTypes$ } from '../../issueTypes.store'
import { allUserList$ } from '../../user.store'
import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  ISSUE_ID,
  PROJECT_IS_NOT_SELECTED_ERROR,
  PROJECT_URN,
  SEARCH_IL,
  ZERO_SEARCH,
} from '../../../constants'
import { gStationDocumentManagementUrl } from '../../../config'
import {
  assigneeRoleList$,
  assigneeAllUserList$,
  assigneeCompanyList$,
} from '../../assigneeGroupList'
import { IssueListFilterPanel } from '../IssueListFilterPanel'
import { issueStatusList$, PreparedIssueStatus } from '../../issueStatus.store'
import { IssueListAppliedFilters } from '../IssueListAppliedFilters'

import { getIssueListFilters } from './getIssueListFilters'
import { matchIssueToFilters } from './matchIssueToFilters'

type IssueListProps = {
  projectList: smApi.Project[]
}

export const IssueList = ({ projectList }: IssueListProps) => {
  const history = useHistory()

  const query = useQuery()
  const projectUrn = query.get(PROJECT_URN) || null
  const selectedIssueId = Number(query.get(ISSUE_ID)) || null
  const filters = useMemo(() => getIssueListFilters(query), [query])

  const [searchValue, setSearchValue] = useState(filters.filter)

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn) || null,
    [projectList, projectUrn],
  )

  const fetchIssueListWithExtraDataCb = useCallback(
    (params: FetchIssueListWithExtraDataParams) => {
      fetchIssueListWithExtraData({
        ...filters,
        showNotRelevant: true,
        ...params,
      })
    },
    [filters],
  )

  const [openCreateIssuePopup, setOpenCreateIssuePopup] = useState(false)
  const [offset, setOffset] = useState(defaultOffset)
  const debouncedFetch = useDebounce({
    handler: () => {
      const formattedSearchValue = searchValue.trim().toLowerCase()
      if (
        filters.filter.trim().toLowerCase() === formattedSearchValue ||
        !selectedProject
      ) {
        return
      }
      resetIssueList()
      setOffset(0)
      fetchIssueListWithExtraDataCb({
        offset: 0,
        limit,
        project: selectedProject,
        filter: formattedSearchValue,
      })
      query.set(SEARCH_IL, formattedSearchValue)
      history.replace({ search: query.toString() })
    },
    delay: 500,
  })
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableContainerRefCurrent = tableContainerRef.current
  const issuePending = useStore(issuePending$)
  const fetchXlsxGTechIssuePending = useStore(fetchXlsxGTechIssuePending$)
  const allUserList = useStore(allUserList$)
  const userInfo = useStore(userInfo$)
  const issueTypes = useStore(issueTypes$)
  const issueStatusList = useStore(issueStatusList$)
  const [columns, setColumns] = useState(getIssueListColumnOrderFromStorage())

  const fetchIssueXlsxWithExtraDataCb = useCallback(
    (params: Omit<FetchIssueListXLSXWithExtraDataParams, 'fields'>) => {
      fetchIssueXlsxWithExtraData({
        ...filters,
        showNotRelevant: true,
        ...params,
        fields: columns.map(({ field }) => field),
      })
    },
    [filters, columns],
  )

  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })

  const { issueList, total } = useStoreMap({
    store: issuesList$,
    keys: [filters.filter.trim().toLowerCase()],
    fn: ({ byId$, ids$, totalIssues$ }, [val]) => {
      const search = val || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          issueList: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalIssues$ || 0,
          byId: byId$,
        }
      }

      return { issueList: [], total: 0, byId: byId$ }
    },
  })

  const listIsNotOver = total > offset

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedFetch(), [searchValue])

  useEffect(() => {
    setColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    if (!selectedProject) {
      return
    }

    resetIssueList()
    fetchIssueListWithExtraDataCb({
      offset: 0,
      limit,
      project: selectedProject,
    })
    setOffset(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject])

  useEffect(() => {
    if (!selectedProject || offset === 0) {
      return
    }

    fetchIssueListWithExtraDataCb({
      offset,
      limit,
      project: selectedProject,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, selectedProject])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: IssueTableRow
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      onClick: props => {
        query.set(ISSUE_ID, props.item.id.toString())
        history.push({ search: query.toString() })
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Создать копию',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.COPY,
      disabled: () => true,
    },
    {
      title: 'Архивировать',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
  ])

  const resetInfinityScroll = () => {
    if (!selectedProject) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    tableContainerRefCurrent?.scrollTo(0, 0)
    resetIssueList()
    fetchIssueListWithExtraDataCb({
      offset: 0,
      limit,
      project: selectedProject,
    })
    setOffset(0)
  }

  const tableList = useMemo(
    (): IssueTableRow[] =>
      issueList.map((issue, idx) => {
        const assigneeIssue = getAssigneeFromIssue(issue)
        const [assignee] = getAssignees(assigneeIssue ? [assigneeIssue] : [])

        return {
          ...issue,
          issueTypeName: issueTypes.find(
            type => type.id === Number(issue.issueTypeId),
          )?.name,
          assignee,
          owner: allUserList.find(user => user.id === issue.ownerId),
          status: issueStatusList.find(item => item.status === issue.status),
        }
      }),
    [getAssignees, issueList, issueStatusList, issueTypes, allUserList],
  )

  const isEnabledCol = useCallback(
    (col: TableColumn<IssueTableRow>) => {
      if (gStationDocumentManagementUrl && col.field === 'linkedEntity') {
        return selectedProject?.sourceType === 'GStation'
      }

      return true
    },
    [selectedProject?.sourceType],
  )

  const onChangeFilter = useCallback(
    (query: URLSearchParamsCustom) => {
      if (!selectedProject) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      const filters = getIssueListFilters(query)

      resetIssueList()
      fetchIssueListWithExtraDataCb({
        offset: 0,
        limit,
        project: selectedProject,
        ...filters,
      })

      setOffset(0)
    },
    [fetchIssueListWithExtraDataCb, selectedProject],
  )

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notifyCreatedIssue = useCallback(
    (issue: api.GTechIssue.IssuePopulated, isMatchIssueToFilters: boolean) => {
      let infoText: string | null =
        'Измените параметры фильтрации для отображения данного замечания в общем списке.'

      if (isMatchIssueToFilters) {
        infoText = null
      }

      enqueueSnackbar('', {
        content: key => (
          <EntityCreatedSnackbar
            id={key}
            message={`Новое замечание “${issue.name}” успешно создано.`}
            onClose={() => closeSnackbar()}
            infoText={infoText}
          />
        ),
      })
    },
    [closeSnackbar, enqueueSnackbar],
  )

  const onCreateIssue = useCallback(
    issue => {
      if (!selectedProject) {
        throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
      }

      const isMatchIssueToFilters = matchIssueToFilters(issue, filters)

      notifyCreatedIssue(issue, isMatchIssueToFilters)

      if (isMatchIssueToFilters) {
        addIssueToIds({ id: issue.id, search: filters.filter })
      }
    },
    [filters, notifyCreatedIssue, selectedProject],
  )

  if (!userInfo) {
    return null
  }

  return (
    <TabContainer>
      {openCreateIssuePopup && (
        <CreateGTechIssuePopup
          onCreateIssue={onCreateIssue}
          issueTypes$={issueTypes$}
          open={openCreateIssuePopup}
          onClose={() => setOpenCreateIssuePopup(false)}
          projectUrn={projectUrn}
          projectList={projectList}
          lockProjectSelect={!!projectUrn}
        />
      )}

      <EditIssuePopup
        userInfo={userInfo}
        issue={issueList.find(({ id }) => id === selectedIssueId) || null}
        selectedProject={selectedProject}
      />

      <TableContentContainer>
        <IssueListFilterPanel
          onChange={onChangeFilter}
          projectUrn={projectUrn}
        />

        <TableContent>
          <ActionPanel>
            <SearchContainer>
              <SearchInput
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
                placeholder='Поиск'
                width='230px'
              />
              <Magnifier />
              {searchValue ? (
                <HighlightOffIcon onClick={() => setSearchValue('')} />
              ) : null}
            </SearchContainer>

            <IssueListAppliedFilters onChange={onChangeFilter} />

            <Tooltip
              placement='top'
              title='Обновить список замечаний'
              styleContent={{ marginLeft: 'auto' }}
            >
              <IconButton
                onClick={resetInfinityScroll}
                disabled={issuePending || !projectUrn}
              >
                <Update color='rgba(53, 59, 96, 0.5)' />
              </IconButton>
            </Tooltip>

            <Tooltip title='Выгрузить список замечаний в XLS'>
              <IconButton
                onClick={() => {
                  selectedProject &&
                    fetchIssueXlsxWithExtraDataCb({
                      project: selectedProject,
                    })
                }}
                disabled={fetchXlsxGTechIssuePending}
              >
                {fetchXlsxGTechIssuePending ? (
                  <CircularProgress size={24} />
                ) : (
                  <Download />
                )}
              </IconButton>
            </Tooltip>

            <Button
              onClick={() => setOpenCreateIssuePopup(true)}
              leftIcon={<PlusCircle width='24px' height='24px' />}
              disabled={issuePending}
            >
              Создать замечание
            </Button>
          </ActionPanel>

          {!issuePending && <ContextMenu />}

          <InfiniteScroll
            hasMore={tableList.length !== 0 && listIsNotOver}
            next={() => setOffset(prevValue => prevValue + limit)}
            triggersObserve={[tableList]}
          >
            <Table
              columns={columns}
              rows={tableList}
              onRowCtxMenu={(e, item) => {
                e.preventDefault()

                setCtxMenu({
                  coords: { x: e.clientX, y: e.clientY },
                  item: { item, event: e },
                })
              }}
              totalRows={total || 0}
              pending={issuePending}
              activeRowKey={ctxMenu.item?.item.id}
              getRowKey={row => row.id}
              onChangeColumns={setColumns}
              onClick={(e, item) => {
                query.set(ISSUE_ID, item.id.toString())
                history.push({ search: query.toString() })
              }}
              isEnabledCol={isEnabledCol}
            />
          </InfiniteScroll>
        </TableContent>
      </TableContentContainer>
    </TabContainer>
  )
}

const defaultOffset = 0
const limit = 20

type IssueTableRow = Omit<PreparedIssue, 'status'> & {
  issueTypeName?: string
  assignee?: AssigneeListItem | null
  owner?: smApi.Auth.UserData
  status?: PreparedIssueStatus
}

const columns: TableColumn<IssueTableRow>[] = [
  {
    field: 'id',
    name: 'ID',
    thContent: 'ID',
    type: 'number',
    visible: true,
  },
  {
    field: 'status',
    name: 'Статус',
    visible: true,
    style: {
      width: '145px',
    },
    renderCell: ({ row }) => (
      <StatusContainer>
        <StatusIndicator
          style={{
            background: row.status?.color,
          }}
        />
        {row.status?.name}
      </StatusContainer>
    ),
  },
  {
    field: 'name',
    name: 'Название',
    visible: true,
    cellStyle: { width: 'auto' },
    style: { width: 'auto' },
    renderCell: ({ row }) => (
      <>
        {row.name.length > 25 ? (
          <Tooltip placement='bottom' title={row.name}>
            <>{row.name.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name
        )}
      </>
    ),
  },
  {
    renderCell: ({ row }) => (
      <>{row.issueTypeId ? row.issueTypeName : 'Не задана'}</>
    ),
    name: 'Дисциплина',
    field: 'issueTypeId',
    visible: true,
  },
  {
    renderCell: ({ row }) => {
      let result: ReactNode = null

      if (row.linkedEntity === null) {
        result = 'Не привязано'
      }

      if (row.linkedEntity) {
        result = (
          <LinkedEntityTableItem>
            <LinkedEntityTableItemName>
              {row.linkedEntity.moduleName} /
            </LinkedEntityTableItemName>
            <Tooltip
              styleContent={{ display: 'inline-block' }}
              title={`Открыть в новом окне ${row.linkedEntity.moduleName}/${row.linkedEntity.entityName}`}
            >
              <AnchorLink
                onClick={event => {
                  window.open(row.linkedEntity?.link, '_blank')
                  event.preventDefault()
                  event.stopPropagation()
                }}
                style={{ paddingLeft: '3px', whiteSpace: 'nowrap' }}
              >
                {row.linkedEntity.entityName.length > 10
                  ? `${row.linkedEntity.entityName.substring(0, 10)}...`
                  : row.linkedEntity.entityName}
              </AnchorLink>
            </Tooltip>
          </LinkedEntityTableItem>
        )
      }
      return result
    },
    style: {
      width: '230px',
    },
    cellStyle: { width: '230px' },
    name: 'Привязано к',
    field: 'linkedEntity',
    visible: true,
  },
  {
    renderCell: ({ row }) => (
      <>{moment(row.createdAt).format(DEFAULT_DISPLAY_DATE_FORMAT)}</>
    ),
    name: 'Создан',
    field: 'createdAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.updatedAt
        ? moment(row.updatedAt).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не изменялось',
    name: 'Изменен',
    field: 'updatedAt',
    visible: true,
  },
  {
    renderCell: ({ row }) =>
      row.deadline
        ? moment(row.deadline).format(DEFAULT_DISPLAY_DATE_FORMAT)
        : 'Не указан',
    name: 'Срок',
    field: 'deadline',
    visible: true,
  },
  {
    field: 'assignee',
    renderCell: ({ row }) => {
      if (!row.assignee) {
        return <>{'Не назначен'}</>
      }

      return <>{row.assignee.label}</>
    },
    name: 'Назначено на',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
  {
    field: 'owner',
    renderCell: ({ row }) => {
      if (!row.owner) {
        return <>Не найден</>
      }
      return <>{row.owner.name}</>
    },
    name: 'Автор',
    visible: true,
    cellStyle: { width: '145px', overflow: 'hidden' },
    style: { width: '155px' },
  },
]

type ColumnSettings = {
  field: string
  visible: boolean
}

const getIssueListColumnOrderFromStorage = (): TableColumn<IssueTableRow>[] => {
  const data = localStorage.getItem('issueListColumnOrder')
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as ColumnSettings[]

      return columns
        .slice()
        .sort((a, b) => {
          const aIdx = parsedData.findIndex(s => s.field === a.field)
          const bIdx = parsedData.findIndex(s => s.field === b.field)

          // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
          if (aIdx < 0 || bIdx < 0) {
            return 0
          }

          return aIdx - bIdx
        })
        .map(col => {
          const colFromLocalStorage = parsedData.find(
            ({ field }) => field === col.field,
          )
          return {
            ...col,
            visible:
              colFromLocalStorage === undefined
                ? true
                : colFromLocalStorage.visible,
          }
        })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
  return columns
}

const setColumnsToStorage = (next: TableColumn<IssueTableRow>[]) => {
  localStorage.setItem(
    'issueListColumnOrder',
    JSON.stringify(next.map(({ field, visible }) => ({ field, visible }))),
  )
}
