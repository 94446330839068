import { userInfo$ } from '@gmini/common'
import {
  FilterAssignee,
  FilterDate,
  FilterPanel,
  FilterStatus,
  FilterType,
  FilterUser,
} from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import * as chmApi from '@gmini/chm-api-sdk'

import {
  ASSIGNEE_COMPANY_IDS_IL,
  ASSIGNEE_ROLE_IDS_IL,
  ASSIGNEE_USER_IDS_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  DEADLINE_RANGE_IL,
  DEADLINE_CODE_IL,
  ISSUE_TYPE_IDS_IL,
  OWNER_IDS_IL,
  PROJECT_IS_NOT_SELECTED_ERROR,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
} from '../../../constants'
import { assigneeGroupList$ } from '../../assigneeGroupList'
import { fetchCompanyListPending$ } from '../../company.store'

import {
  fetchStatusListPending$,
  issueStatusList$,
} from '../../issueStatus.store'
import { fetchIssueTypesPending$, issueTypes$ } from '../../issueTypes.store'
import { fetchRoleListPending$ } from '../../role.store'
import {
  projectUserList$,
  fetchProjectUserListPending$,
  fetchAllUserListPending$,
} from '../../user.store'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'
import { getIssueListFilterDeadlineOptions } from './issueListDeadlineOptions'

type IssueListFilterPanelProps = {
  onChange: (query: URLSearchParamsCustom) => void
  projectUrn: string | null
}

export const IssueListFilterPanel = ({
  onChange,
  projectUrn,
}: IssueListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const issueTypes = useStore(issueTypes$)
  const userList = useStore(projectUserList$)
  const fetchUserListPending = useStore(fetchProjectUserListPending$)
  const fetchCompanyListPending = useStore(fetchCompanyListPending$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const fetchIssueTypesPending = useStore(fetchIssueTypesPending$)
  const issueStatusList = useStore(issueStatusList$)
  const fetchStatusListPending = useStore(fetchStatusListPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
      showNotRelevant: true,
    })
  }, [projectUrn])

  const onChangeHandler = (query: URLSearchParamsCustom) => {
    onChange?.(query)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
      showNotRelevant: true,
    }) // Чтобы хэндлить изменения замечаний другими пользователями
  }

  return (
    <FilterPanel
      items={[
        {
          title: 'Дисциплины',
          component: (
            <FilterType
              allowedItems={allowedFilters?.issueTypeIds || []}
              typeList={issueTypes}
              urlKey={ISSUE_TYPE_IDS_IL}
              loading={fetchIssueTypesPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              countSkeletonItems={10}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Статус',
          component: (
            <FilterStatus
              allowedItems={allowedFilters?.statuses || []}
              statusList={issueStatusList}
              loading={fetchStatusListPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              urlKey={STATUSES_IL}
              countSkeletonItems={6}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата изменения',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_IL}
              urlKeyValue={UPDATED_DATE_RANGE_IL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата создания',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_IL}
              urlKeyValue={CREATED_DATE_RANGE_IL}
              onChange={onChangeHandler}
              disabled={!allowedFilters && fetchAllowFiltersPending}
            />
          ),
        },
        {
          title: 'Срок',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={DEADLINE_CODE_IL}
              urlKeyValue={DEADLINE_RANGE_IL}
              onChange={onChangeHandler}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              options={getIssueListFilterDeadlineOptions(new Date())}
            />
          ),
        },
        {
          title: 'Автор',
          component: (
            <FilterUser
              userList={userList}
              urlKey={OWNER_IDS_IL}
              allowedItems={allowedFilters?.ownerIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchUserListPending ||
                fetchAllUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Назначено на',
          component: (
            <FilterAssignee
              assigneeGroupList={assigneeGroupList.filter(
                ({ source }) => source !== chmApi.AssigneeSource.COMPANY,
              )}
              urlKeyRole={ASSIGNEE_ROLE_IDS_IL}
              urlKeyUser={ASSIGNEE_USER_IDS_IL}
              urlKeyCompany={ASSIGNEE_COMPANY_IDS_IL}
              allowedItems={[
                ...(allowedFilters?.assigneeRoleIds || []),
                ...(allowedFilters?.assigneeUserIds || []),
              ]}
              placeholder='Выбрать на кого на...'
              disabled={
                fetchUserListPending ||
                fetchAllUserListPending ||
                fetchCompanyListPending ||
                fetchRoleListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeHandler}
            />
          ),
        },
      ]}
    />
  )
}
