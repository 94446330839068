import { DateFilterItemsCode } from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'

import {
  ASSIGNEE_USER_IDS_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  ISSUE_TYPE_IDS_IL,
  OWNER_IDS_IL,
  SEARCH_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
  ASSIGNEE_ROLE_IDS_IL,
  DEADLINE_RANGE_IL,
  DEADLINE_CODE_IL,
} from '../../../constants'
import { IssueListFilterDeadlineOptionCode } from '../IssueListFilterPanel/issueListDeadlineOptions'

import { IssueListFilters } from './IssueList.types'

export const getIssueListFilters = (
  query: URLSearchParamsCustom,
): IssueListFilters => {
  const searchValueFromUrl = query.get(SEARCH_IL) || ''
  const issueTypeIds = query.getArray(ISSUE_TYPE_IDS_IL).map(Number)
  const statuses = query.getArray(STATUSES_IL)
  const ownerIds = query.getArray(OWNER_IDS_IL)
  const assigneeUserIds = query.getArray(ASSIGNEE_USER_IDS_IL)
  const assigneeRoleIds = query.getArray(ASSIGNEE_ROLE_IDS_IL)
  const createdDateRange = query.getArray(CREATED_DATE_RANGE_IL)
  const updatedDateRange = query.getArray(UPDATED_DATE_RANGE_IL)
  const createdDateCode = query.get(
    CREATED_DATE_CODE_IL,
  ) as DateFilterItemsCode | null
  const updatedDateCode = query.get(
    UPDATED_DATE_CODE_IL,
  ) as DateFilterItemsCode | null
  const deadlineRange = query.getArray(DEADLINE_RANGE_IL)
  const deadlineCode = query.get(
    DEADLINE_CODE_IL,
  ) as IssueListFilterDeadlineOptionCode | null

  return {
    filter: searchValueFromUrl,
    issueTypeIds,
    statuses,
    ownerIds,
    assigneeUserIds,
    assigneeRoleIds,
    createdDateRange,
    updatedDateRange,
    createdDateCode,
    updatedDateCode,
    deadlineRange,
    deadlineCode,
  }
}
