import { IssueStatus, IssueAnswer } from '@gmini/ism-api-sdk'

export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD.MM.YYYY'
export const FULL_DISPLAY_DATE_FORMAT = 'D MMM YYYY HH:mm'
export const SHORT_DISPLAY_DATE_FORMAT = 'MMM D YYYY'
export const LOCAL_ORIGIN = 'http://127.0.0.1:3000'
export const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development'
export const PRODUCTION_MODE = process.env.NODE_ENV === 'production'
export const ZERO_SEARCH = 'áÝé¾±µ'
export const PROJECT_IS_NOT_SELECTED_ERROR = 'Project is not selected'

//shared keys query params
export const SELECTED_TAB = 'selectedTab'
export const PROJECT_URN = 'projectUrn'

// issue list keys query params
export const ISSUE_TYPE_IDS_IL = 'issueTypeIdsIL'
export const OWNER_IDS_IL = 'ownerIdsIL'
export const ASSIGNEE_USER_IDS_IL = 'assigneeUserIdsIL'
export const ASSIGNEE_ROLE_IDS_IL = 'assigneeRoleIdsIL'
export const ASSIGNEE_COMPANY_IDS_IL = 'assigneeCompanyIdsIL'
export const STATUSES_IL = 'statusesIL'
export const UPDATED_DATE_RANGE_IL = 'updatedDateRangeIL'
export const UPDATED_DATE_CODE_IL = 'updatedDateCodeIL'
export const CREATED_DATE_RANGE_IL = 'createdDateRangeIL'
export const CREATED_DATE_CODE_IL = 'createdDateCodeIL'
export const DEADLINE_RANGE_IL = 'deadlineRangeIL'
export const DEADLINE_CODE_IL = 'deadlineCodeIL'
export const SEARCH_IL = 'searchIL'
export const ISSUE_ID = 'issueId'

// template list keys query params
export const ISSUE_TYPE_IDS_TL = 'issueTypeIdsTL'
export const OWNER_IDS_TL = 'ownerIdsTL'
export const ASSIGNEE_IDS_TL = 'assigneeIdsTL'
export const STATUSES_TL = 'statusesTL'
export const UPDATED_DATE_RANGE_TL = 'updatedDateRangeTL'
export const UPDATED_DATE_CODE_TL = 'updatedDateCodeIT'
export const CREATED_DATE_RANGE_TL = 'createdDateRangeTL'
export const CREATED_DATE_CODE_TL = 'createdDateCodeTL'
export const SEARCH_TL = 'searchTL'
export const TEMPLATE_ID = 'templateId'

export enum TabIndex {
  IssueList,
  TemplateList,
}

export const issueStatusColorMap: Record<IssueStatus.Enum, string> = {
  CLOSED: '#42AB85',
  DRAFT: '#E5E7F1',
  IN_PROCESS: '#DE900E',
  NOT_RELEVANT: '#E5E7F1',
  NOT_STARTED: '#A2A3B7',
  ON_REVIEW: '#4C5ECF',
}

export const defaultIssueStatusColor = '#42AB85'

export const issueAnswerStatusDescMap: Record<
  IssueAnswer.StatusEnum,
  string
> = {
  [IssueAnswer.StatusEnum.DECLINED]: 'отклонен',
  [IssueAnswer.StatusEnum.ACCEPTED]: 'принят',
  [IssueAnswer.StatusEnum.PENDING]: 'ожидает принятия',
  [IssueAnswer.StatusEnum.DELETED]: 'удален',
}
