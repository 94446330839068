import { createProjectListService } from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

export const {
  fetchProjectList,
  fetchProjectListPending$,
  projectList$,
} = createProjectListService({
  fetchProjectListApi: smApi.Project.fetchList,
})
