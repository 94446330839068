import { ReactNode } from 'react'
import { useDrop, useDrag, DragObjectWithType } from 'react-dnd'

import { ColumnOrderPopupItemWrapper } from './ColumnOrderPopupItem.styled'

export const DndColumnOrderItemType = 'COLUMNORDERITEM'

type ColumnOrderPopupItemProps<Column> = {
  children?: ReactNode
  column: Column
  handleDrop: (
    item: DragObjectWithType & {
      column: Column
    },
    column: Column,
  ) => void
}

export function ColumnOrderPopupItem<Column>({
  children,
  column,
  handleDrop,
}: ColumnOrderPopupItemProps<Column>) {
  const [, dragRef] = useDrag({
    item: {
      column,
      type: DndColumnOrderItemType,
    },
  })

  const [{ isOver }, dropRef] = useDrop({
    accept: DndColumnOrderItemType,
    drop: (
      item: DragObjectWithType & {
        column: Column
      },
    ) => handleDrop(item, column),
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  })

  return (
    <div
      ref={dropRef}
      style={{ background: isOver ? 'rgba(53, 59, 96, 0.1)' : 'none' }}
    >
      <ColumnOrderPopupItemWrapper ref={dragRef}>
        {children}
      </ColumnOrderPopupItemWrapper>
    </div>
  )
}
