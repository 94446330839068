import { DragObjectWithType } from 'react-dnd'

import {
  Checkbox,
  DraggableDotsIcon,
  ColumnOrderPopupItem,
} from '@gmini/components'

import {
  ColumnOrderPopupWrapper,
  ColumnOrderPopupItemText,
} from './ColumnOrderPopup.styled'

export type AbstractColumn = {
  name: React.ReactNode
  visible: boolean
}

export type ColumnOrderPopupProps<Column extends AbstractColumn> = {
  columns: Column[]
  onChangeColumns: (next: Column[]) => void
  isEnabledCol?: (col: Column) => boolean
}

export function ColumnOrderPopup<Col extends AbstractColumn>({
  columns,
  onChangeColumns,
  isEnabledCol,
}: ColumnOrderPopupProps<Col>) {
  const handleDrop = (
    item: DragObjectWithType & {
      column: Col
    },
    column: Col,
  ) => {
    const currentIndex = columns.indexOf(item.column)
    const targetIndex = columns.indexOf(column)
    const newOrder = [...columns]
    newOrder.splice(currentIndex, 1)
    newOrder.splice(targetIndex, 0, item.column)
    onChangeColumns(newOrder)
  }

  return (
    <ColumnOrderPopupWrapper>
      {columns
        .filter(col => (isEnabledCol ? isEnabledCol(col) : true))
        .map((column, idx) => (
          <ColumnOrderPopupItem
            column={column}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            handleDrop={handleDrop}
          >
            <DraggableDotsIcon />
            <Checkbox
              visible
              checked={column.visible}
              onChange={() => {
                const newColumnOrder = [...columns]
                const visibleColumnsCount = newColumnOrder.filter(
                  column => column.visible,
                ).length
                if (visibleColumnsCount === 1 && newColumnOrder[idx].visible) {
                  return
                }
                newColumnOrder[idx].visible = !column.visible
                onChangeColumns(newColumnOrder)
              }}
            />
            <ColumnOrderPopupItemText>{column.name}</ColumnOrderPopupItemText>
          </ColumnOrderPopupItem>
        ))}
    </ColumnOrderPopupWrapper>
  )
}
