import { Button } from '@gmini/ui-kit'
import { useStore } from 'effector-react'

import { buildShortUserLabel, buildUserLabel } from '@gmini/helpers'

import * as api from '@gmini/ism-api-sdk'
import moment from 'moment'

import { projectUserList$ } from '../../user.store'
import {
  FULL_DISPLAY_DATE_FORMAT,
  issueAnswerStatusDescMap,
} from '../../../constants'

import {
  Corner,
  DateInfo,
  IssueOfficialReplyWrapper,
  OfficialReply,
  ReplyOwnerAndDate,
  ReplyStatus,
  ReplyText,
  StatusWrapper,
  UserBadge,
  UserLabel,
  ButtonContainer,
} from './IssueOfficialReply.styled'

const replyStatusColorMap: Record<api.IssueAnswer.StatusEnum, string> = {
  DECLINED: '#ec868b',
  ACCEPTED: '#0bbb7b',
  PENDING: '#4c5ecf',
  DELETED: '#a2a3b7',
}

type IssueOfficialReplyProps<T> = {
  officialReply: T
  onReject?: ((officialReply: T) => void) | null
  onAccept?: ((officialReply: T) => void) | null
  onDelete?: ((officialReply: T) => void) | null
  disabled?: boolean
}

export const IssueOfficialReply = <
  T extends {
    status: api.IssueAnswer.StatusEnum
    createdAt: string
    authorId: string
    description: string
  }
>({
  officialReply,
  onDelete,
  onAccept,
  onReject,
  disabled,
}: IssueOfficialReplyProps<T>) => {
  const users = useStore(projectUserList$)

  const replyOwner =
    users.find(user => user.id === officialReply.authorId) || null

  return (
    <IssueOfficialReplyWrapper>
      <OfficialReply border={replyStatusColorMap[officialReply.status]}>
        <StatusWrapper background={replyStatusColorMap[officialReply.status]}>
          <Corner />
          <ReplyStatus>
            ОФИЦИАЛЬНЫЙ ОТВЕТ: {issueAnswerStatusDescMap[officialReply.status]}
          </ReplyStatus>
        </StatusWrapper>
        <ReplyOwnerAndDate>
          <UserBadge>{buildShortUserLabel(replyOwner)}</UserBadge>
          <UserLabel>
            {replyOwner?.email || buildUserLabel(replyOwner)}
          </UserLabel>
          <DateInfo>
            {moment(officialReply.createdAt).format(FULL_DISPLAY_DATE_FORMAT)}
          </DateInfo>
        </ReplyOwnerAndDate>
        <ReplyText>{officialReply.description}</ReplyText>
      </OfficialReply>

      <ButtonContainer>
        {onDelete && (
          <Button
            color='secondary'
            onClick={() => onDelete(officialReply)}
            disabled={disabled}
          >
            Удалить официальный ответ
          </Button>
        )}
        {onReject && (
          <Button
            color='secondary'
            onClick={() => onReject(officialReply)}
            disabled={disabled}
          >
            Отклонить
          </Button>
        )}
        {onAccept && (
          <Button onClick={() => onAccept(officialReply)} disabled={disabled}>
            Принять и Завершить замечание
          </Button>
        )}
      </ButtonContainer>
    </IssueOfficialReplyWrapper>
  )
}
