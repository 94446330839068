import { useQuery } from '@gmini/utils'
import { useMemo } from 'react'

type UseAppliedFilterStatusData<S> = {
  urlKey: string
  statusList: S[]
  name: string
}

export const useAppliedFilterStatusData = <
  S extends { status: string; name: string }
>({
  statusList,
  urlKey,
  name,
}: UseAppliedFilterStatusData<S>) => {
  const query = useQuery()
  const statusListFromUrl = query.getArray(urlKey)

  return useMemo(
    () => ({
      name,
      value: statusList
        .filter(item =>
          statusListFromUrl?.some(status => status === item.status),
        )
        .map(item => item.name),
      urlKey,
    }),
    [name, statusList, statusListFromUrl, urlKey],
  )
}
