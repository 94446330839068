import { isNotEmpty } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useCallback } from 'react'

import * as api from '@gmini/chm-api-sdk'

import { Store } from 'effector'

import { AssigneeListItem } from '@gmini/helpers'

type Assignee = {
  assigneeId: string
  source: api.AssigneeSource
}

type Initiator = {
  initiatorId: string
  source: api.AssigneeSource
}

type UseAssigneesProps = {
  assigneeRoleList$: Store<AssigneeListItem[]>
  assigneeUserList$: Store<AssigneeListItem[]>
  assigneeCompanyList$: Store<AssigneeListItem[]>
}

export const useAssignees = <E extends Assignee | Initiator>({
  assigneeRoleList$,
  assigneeUserList$,
  assigneeCompanyList$,
}: UseAssigneesProps) => {
  const assigneeUsers = useStore(assigneeUserList$)
  const assigneeRoles = useStore(assigneeRoleList$)
  const assigneeCompanies = useStore(assigneeCompanyList$)

  return useCallback(
    (assignees: Array<E>) =>
      assignees
        .map(assignee => {
          const assigneeId =
            (assignee as Assignee).assigneeId || //TODO убрать каст
            (assignee as Initiator).initiatorId

          switch (assignee.source) {
            case api.AssigneeSource.USER: {
              const user = assigneeUsers.find(({ id }) => id === assigneeId)

              if (!user) {
                return null
              }

              return { ...user, source: api.AssigneeSource.USER }
            }

            case api.AssigneeSource.ROLE: {
              const role = assigneeRoles.find(({ id }) => id === assigneeId)

              if (!role) {
                return null
              }

              return { ...role, source: api.AssigneeSource.ROLE }
            }

            case api.AssigneeSource.COMPANY: {
              const company = assigneeCompanies.find(
                ({ id }) => id === assigneeId,
              )

              if (!company) {
                return null
              }

              return { ...company, source: api.AssigneeSource.COMPANY }
            }
          }
        })
        .filter(isNotEmpty),

    [assigneeCompanies, assigneeRoles, assigneeUsers],
  )
}
