import { FilterDateOption } from '@gmini/components'
import { createDateRangeByDays } from '@gmini/helpers'

export function getIssueListFilterDeadlineOptions(
  currentDate: Date,
): FilterDateOption[] {
  return [
    {
      name: 'Все',
      code: IssueListFilterDeadlineOptionCode.ALL,
      dateRange: null,
    },
    {
      name: 'Приближающийся срок',
      code: IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED,
      dateRange:
        filterDeadlineRangeByCode[
          IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED
        ]?.(currentDate) || null,
    },
    {
      name: 'Только просроченные',
      code: IssueListFilterDeadlineOptionCode.ONLY_EXPIRED,
      dateRange:
        filterDeadlineRangeByCode[
          IssueListFilterDeadlineOptionCode.ONLY_EXPIRED
        ]?.(currentDate) || null,
    },
  ]
}

export enum IssueListFilterDeadlineOptionCode {
  ALMOST_EXPIRED = ' ALMOST_EXPIRED',
  ONLY_EXPIRED = 'ONLY_EXPIRED',
  ALL = 'ALL',
}

type FilterDeadlineRangeByCode = Record<
  IssueListFilterDeadlineOptionCode,
  ((currentDate: Date) => [string | null, string | null]) | null
>

export const filterDeadlineRangeByCode: FilterDeadlineRangeByCode = {
  [IssueListFilterDeadlineOptionCode.ALL]: null,
  [IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED]: (currentDate: Date) =>
    createDateRangeByDays(currentDate, 7, 'plus'),
  [IssueListFilterDeadlineOptionCode.ONLY_EXPIRED]: (currentDate: Date) => {
    const maxDateAlmostExpired = new Date(currentDate)
    maxDateAlmostExpired.setDate(maxDateAlmostExpired.getDate() - 1)
    return [null, maxDateAlmostExpired.toISOString()]
  },
}
