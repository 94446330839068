export const declensionsOfNum = (number: number, text_forms: string[]) => {
  const newNumber = Math.abs(number) % 100
  const number1 = newNumber % 10
  if (newNumber > 10 && newNumber < 20) {
    return text_forms[2]
  }
  if (number1 > 1 && number1 < 5) {
    return text_forms[1]
  }
  if (number1 === 1) {
    return text_forms[0]
  }
  return text_forms[2]
}
