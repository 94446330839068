import {
  // AllFolders, //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
  Tabs,
} from '@gmini/ui-kit'
import { useStore } from 'effector-react'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { useQuery } from '@gmini/utils'

import * as smApi from '@gmini/sm-api-sdk'

import {
  SwitchProject,
  SwitchProjectItem,
  // SwitchProjectPopoverItem,//TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
} from '@gmini/common'

import {
  // SwitchProjectPopoverItemSeparator, //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
  // SwitchProjectPopoverItemCount,
  // SwitchProjectProjectName,
  SwitchProjectSelectWrapper,
  PageContentWrapper,
} from '@gmini/components'

import {
  IssueList,
  IssueManagerWrapper,
  IssueTemplateList,
} from '../../organisms'

import { PROJECT_URN, TabIndex, SELECTED_TAB } from '../../../constants'

import {
  fetchProjectListPending$,
  projectList$,
  fetchProjectList,
} from './model'

import { TabTitle } from './IssueManagerPage.styled'

export const IssueManagerPage = () => {
  const [openedSwitchProjectPopup, setOpenedSwitchProjectPopup] = useState(
    false,
  )
  const fetchProjectListPending = useStore(fetchProjectListPending$)
  const projectList = useStore(projectList$)
  const history = useHistory()

  const query = useQuery()
  const projectUrn = query.get(PROJECT_URN) || null
  const selectedTab = Number(query.get(SELECTED_TAB)) || TabIndex.IssueList

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn) || null,
    [projectList, projectUrn],
  )

  useEffect(() => {
    fetchProjectList.submit()
  }, [])

  const onChangeTab = (nextTabIdx: number) => {
    query.set(SELECTED_TAB, nextTabIdx.toString())
    history.replace({ search: query.toString() })
  }

  const onChangeProject = useCallback(
    (project: smApi.Project) => {
      query.set(PROJECT_URN, project.urn)
      history.replace({ search: query.toString() })
    },
    [history, query],
  )

  useEffect(() => {
    if (!projectUrn && projectList.length) {
      onChangeProject(projectList[0])
    }
  }, [onChangeProject, projectList, projectUrn])

  const onCloseSwitchProjectPopup = () => setOpenedSwitchProjectPopup(false)

  return (
    <IssueManagerWrapper
      brandSecondary={
        <SwitchProjectSelectWrapper>
          <SwitchProject
            projectList={projectList}
            disabled={fetchProjectListPending}
            loading={fetchProjectListPending}
            onChangeProject={onChangeProject}
            selectedProjectName={selectedProject?.name || ''}
            // badgeIcon={!selectedProject && <AllFolders />} //TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
            onToggle={() => setOpenedSwitchProjectPopup(prev => !prev)}
            opened={openedSwitchProjectPopup}
            onClose={onCloseSwitchProjectPopup}
            renderItem={(project: smApi.Project, idx: number) => {
              const SwitchProjectItemJsx = (
                <SwitchProjectItem
                  onChangeProject={onChangeProject}
                  onClose={onCloseSwitchProjectPopup}
                  project={project}
                />
              )
              // if (idx === 0) {//TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
              //   return (
              //     <>
              //       <SwitchProjectPopoverItem
              //         onClick={() => {
              //           onChangeProject(null)
              //           onCloseSwitchProjectPopup()
              //         }}
              //       >
              //         <SwitchProjectProjectName>
              //           {allProjectItemName}
              //           <SwitchProjectPopoverItemCount>
              //             {projectList.length}
              //           </SwitchProjectPopoverItemCount>
              //         </SwitchProjectProjectName>
              //       </SwitchProjectPopoverItem>
              //       <SwitchProjectPopoverItemSeparator />
              //       {SwitchProjectItemJsx}
              //     </>
              //   )
              // }

              return SwitchProjectItemJsx
            }}
          />
        </SwitchProjectSelectWrapper>
      }
    >
      <PageContentWrapper>
        <Tabs
          onChangeTab={onChangeTab}
          activeTabIndex={selectedTab}
          headerStyles={{
            fontSize: '14px',
            height: '49px',
            gap: 19,
          }}
          contentStyles={{
            height: '100%',
            paddingTop: '0',
            display: 'flex',
            minHeight: 0,
          }}
          tabs={[
            {
              title: <TabTitle>Замечания</TabTitle>,
              content: <IssueList projectList={projectList} />,
            },
            {
              title: <TabTitle>Шаблоны</TabTitle>,
              content: <IssueTemplateList projectList={projectList} />,
            },
          ]}
        />
      </PageContentWrapper>
    </IssueManagerWrapper>
  )
}

// const allProjectItemName = 'Все проекты'//TODO временно скрыто в рамках задачи https://gmini.atlassian.net/browse/CI-34
