import { StatusContainer, StatusIndicator } from '@gmini/components'
import { URLSearchParamsCustom, useQuery } from '@gmini/utils'
import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import { FilterItem, FilterWrapper } from '../../atoms'

import { StatusItem } from './FilterStatus.types'

type FilterStatusProps<S> = {
  statusList: S[]
  allowedItems: string[]
  urlKey: string
  disabled?: boolean
  loading?: boolean
  countSkeletonItems?: number
  onChange?: (query: URLSearchParamsCustom) => void
}

export const FilterStatus = <S extends StatusItem>({
  allowedItems,
  statusList,
  urlKey,
  disabled,
  loading,
  countSkeletonItems,
  onChange,
}: FilterStatusProps<S>) => {
  const history = useHistory()

  const query = useQuery()
  const statusListFromUrl = query.getArray(urlKey)

  const preparedStatusList = useMemo(
    () =>
      statusList.map(item => ({
        ...item,
        uniqId: uuid(),
        selected: statusListFromUrl?.some(status => status === item.status),
        disabled:
          disabled || !allowedItems?.some(status => status === item.status),
      })),
    [allowedItems, disabled, statusList, statusListFromUrl],
  )

  const onChangeHandler = useCallback(
    (item: typeof preparedStatusList[0]) => {
      if (
        statusListFromUrl.length &&
        statusListFromUrl.some(id => id === item.status)
      ) {
        query.removeValue(urlKey, item.status)
      } else {
        query.appendValue(urlKey, item.status)
      }

      history.replace({ search: query.toString() })

      onChange?.(query)
    },
    [statusListFromUrl, history, query, onChange, urlKey],
  )

  const renderName = useCallback(
    (item: typeof preparedStatusList[0]) => (
      <StatusContainer>
        <StatusIndicator
          style={{
            background: item.color,
          }}
        />
        {item.name}
      </StatusContainer>
    ),
    [],
  )

  return (
    <FilterWrapper
      loading={!statusList.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedStatusList.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          onChange={onChangeHandler}
          renderName={renderName}
        />
      ))}
    </FilterWrapper>
  )
}
