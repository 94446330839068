import { createEvent } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import { AssigneeListItem } from '@gmini/helpers'

export type FormValue = {
  name: string
  description?: string
  assignee: AssigneeListItem | null
  files?: File[]
  issueType: api.GTechIssueType | null
  project: smApi.Project | null
  template: api.GTechIssueTemplate | null
  deadline: any //TODO либа для форм почему-то теряет тип DayJs при пробросе в Datepicker
}

export const attachFiles = createEvent<{ files: File[] }>()
