import { Arrow } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

export const Container = styled.div``

type SelectedItemProps = {
  color: string
  disabled?: boolean
  active: boolean
}

export const SelectedItem = styled.div`
  background: ${(props: SelectedItemProps) => props.color};
  border-radius: 20px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 0 4px;
  color: #fff;
  padding: 4px 8.5px;
  cursor: ${(props: SelectedItemProps) =>
    props.active ? 'pointer' : 'default'};

  ${(props: SelectedItemProps) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`

export const SelectArow = styled(Arrow)`
  transform: rotate(90deg);
`

export const StatusItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
  padding: 9px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000335;
  cursor: pointer;

  &:hover {
    background: #f4f4f8;
  }
`

type StatusItemBadgeProps = {
  color: string
}

export const StatusItemBadge = styled.div`
  height: 18px;
  width: 4px;
  border-radius: 4px;
  background: ${(props: StatusItemBadgeProps) => props.color};
`
