import * as api from '@gmini/ism-api-sdk'

import { createIssueTemplateService } from '../../createIssueTemplateListService'

export const {
  resetTemplateList,
  templateList$,
  fetchIssueTemplateList,
} = createIssueTemplateService({
  fetchIssueTemplateListApi: api.GTechIssueTemplate.fetchList,
})
