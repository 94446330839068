import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
} from 'effector'

import * as api from '@gmini/ism-api-sdk'
import * as smApi from '@gmini/sm-api-sdk'
import * as DMApi from '@gmini/sm-api-sdk/lib/DMAPi'

import { clone } from 'ramda'

import { DateFilterItemsCode } from '@gmini/components'

import { filterDateEnrichment } from '@gmini/helpers'

import { URLSearchParamsCustom } from '@gmini/utils'

import { ZERO_SEARCH } from '../constants'

import { gStationDocumentManagementUrl } from '../config'

import {
  deleteFile,
  startUploadFile,
  fetchContentFiles,
  uploadFiles,
} from './file.store'

import { attachFiles } from './createGTechIssuePopup.store'
import { fetchIssueCommentList } from './organisms/Comments/model'
import {
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './organisms/IssueListFilterPanel/model'
import { getIssueListFilters } from './organisms/IssueList/getIssueListFilters'
import { matchIssueToFilters } from './organisms/IssueList/matchIssueToFilters'
import {
  filterDeadlineRangeByCode,
  IssueListFilterDeadlineOptionCode,
} from './organisms/IssueListFilterPanel/issueListDeadlineOptions'

export const fetchMostRecentGTechIssue = api.GTechIssue.fetchMostRecent.createContext()
export const fetchMostRecentGTechIssuePending$ =
  fetchMostRecentGTechIssue.pending$
export const fetchListGTechIssue = api.GTechIssue.fetchList.createContext()
export const fetchXlsxGTechIssue = api.GTechIssue.fetchXlsx.createContext()
export const fetchListGTechIssuePending$ = fetchListGTechIssue.pending$
export const fetchXlsxGTechIssuePending$ = fetchXlsxGTechIssue.pending$
export const createGTechIssue = api.GTechIssue.create.createContext()
export const createGTechIssuePending$ = createGTechIssue.pending$

export const updateGTechIssue = api.GTechIssue.update.createContext()
export const updateGTechIssuePending$ = updateGTechIssue.pending$
export const changeStatus = api.GTechIssue.changeStatus.createContext()
export const changeStatusPending$ = changeStatus.pending$

changeStatus.doneData.watch(({ id, version }) => {
  fetchIssueCommentList({ issueId: id, issueVersion: version })
})

merge([
  createGTechIssue.doneData,
  updateGTechIssue.doneData,
  changeStatus.doneData,
]).watch(issue => {
  fetchAllowedFilters({
    projectUrn: issue.projectUrn,
    showNotRelevant: true,
  })

  const query = new URLSearchParamsCustom(window.location.search)
  const filters = getIssueListFilters(query)

  if (!matchIssueToFilters(issue, filters)) {
    removeIssueFromIds({ id: issue.id, search: filters.filter })
  }
})

export type FetchIssueListWithExtraDataParams = Omit<
  api.GTechIssue.FetchListParams,
  'projectUrn' | 'createdDateRange' | 'updatedDateRange' | 'deadlineRange'
> & {
  project: smApi.Project
  createdDateRange?: string[] | null
  updatedDateRange?: string[] | null
  createdDateCode?: DateFilterItemsCode | null
  updatedDateCode?: DateFilterItemsCode | null
  deadlineCode?: IssueListFilterDeadlineOptionCode | null
}

export type FetchIssueListXLSXWithExtraDataParams = Omit<
  FetchIssueListWithExtraDataParams,
  'limit' | 'offset'
> & {
  fields: string[]
}

export const fetchIssueListWithExtraData = createEffect<
  FetchIssueListWithExtraDataParams,
  unknown
>()

fetchIssueListWithExtraData.use(
  async ({
    project,
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    deadlineCode,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const {
      enrichedUpdatedDateRange,
      enrichedCreatedDateRange,
    } = filterDateEnrichment(currentDate, {
      updatedDateRange,
      createdDateRange,
      updatedDateCode,
      createdDateCode,
    })

    const enrichedDeadlineRange = deadlineCode
      ? filterDeadlineRangeByCode[deadlineCode]?.(currentDate)
      : null

    const issueListData = await fetchListGTechIssue({
      ...otherProps,
      projectUrn: project.urn,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
      deadlineRange: enrichedDeadlineRange,
    })

    if (
      gStationDocumentManagementUrl &&
      project?.sourceType === 'GStation' &&
      issueListData.total
    ) {
      fetchListLinkedIssueToFile({
        issues: issueListData.list.map(({ id }) => id),
      })
    }
  },
)

export const fetchIssueXlsxWithExtraData = createEffect<
  FetchIssueListXLSXWithExtraDataParams,
  unknown
>()

fetchIssueXlsxWithExtraData.use(
  async ({
    project,
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    fields,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const {
      enrichedUpdatedDateRange,
      enrichedCreatedDateRange,
    } = filterDateEnrichment(currentDate, {
      updatedDateRange,
      createdDateRange,
      updatedDateCode,
      createdDateCode,
    })

    await fetchXlsxGTechIssue({
      query: {
        ...otherProps,
        projectUrn: project.urn,
        updatedDateRange: enrichedUpdatedDateRange,
        createdDateRange: enrichedCreatedDateRange,
      },
      body: { fields },
    })
  },
)

export const fetchListLinkedIssueToFile = DMApi.DMFile.fetchListLinkedIssueToFile.createContext()
export const fetchListLinkedIssueToFilePending$ =
  fetchListLinkedIssueToFile.pending$

export const fetchIssueHistory = api.GTechIssue.fetchHistoryChanges.createContext()
export const fetchIssueHistoryPending$ = fetchIssueHistory.pending$

export const issueHistory$ = createStore<api.GTechIssue.HistoryChanges | null>(
  null,
).on(fetchIssueHistory.doneData, (_state, result) => result)

fetchMostRecentGTechIssue.doneData.watch(({ id, version }) => {
  fetchContentFiles({ issueId: id, issueVersion: version })
})

sample({
  clock: createGTechIssue.doneData,
  source: attachFiles,
  fn: ({ files }, { id, version }) => ({
    files,
    issueId: id,
    issueVersion: version,
  }),
}).watch(async ({ files, issueId, issueVersion }) => {
  if (!files.length) {
    return
  }

  await uploadFiles({ issueId, issueVersion }, files)
})

export const addIssueToList = createEvent<api.GTechIssue.Issue>()
export const resetIssueList = createEvent()

export type LinkedEntity = {
  moduleName: string
  entityName: string
  link: string
}

export type PreparedIssue = (
  | api.GTechIssue.Issue
  | api.GTechIssue.IssuePopulated
) & {
  linkedEntity?: LinkedEntity | null
  allowedActions?: api.GTechIssue.IssuePopulated['allowedActions'] | undefined
  answer?: api.GTechIssue.IssuePopulated['answer']
}

type ById = {
  [id: string]: PreparedIssue
}

export const issuePending$ = combine(
  [
    createGTechIssuePending$,
    fetchListGTechIssuePending$,
    fetchListLinkedIssueToFilePending$,
    changeStatusPending$,
    fetchAllowedFiltersPending$,
  ],
  pendings => pendings.some(Boolean),
)

const byId$ = createStore<ById>({})
  .on(fetchListGTechIssue.doneData, (state, result) => {
    const next = { ...state }
    result.list.forEach(ch => (next[ch.id] = { ...next[ch.id], ...ch }))
    return next
  })
  .on(
    merge([
      updateGTechIssue.doneData,
      fetchMostRecentGTechIssue.doneData,
      changeStatus.doneData,
      startUploadFile.doneData,
      createGTechIssue.doneData,
    ]),
    (state, result) => ({
      ...state,
      [result.id]: { ...state[result.id], ...result },
    }),
  )
  .on([deleteFile.doneData], (state, result) => ({
    ...state,
    [result.issueId]: {
      ...state[result.issueId],
      version: result.issueVersion,
    },
  }))
  .on(fetchListLinkedIssueToFile.done, (state, { params, result }) => {
    const next = clone(state)

    params.issues.forEach(issueId => {
      const linkedEntity = result.issues.find(
        ({ externalId }) => externalId === issueId,
      )
      return (next[issueId] = {
        ...next[issueId],
        linkedEntity: linkedEntity
          ? {
              moduleName: 'G-station',
              entityName: linkedEntity.file.name,
              link: linkedEntity.file.webview,
            }
          : null,
      })
    })

    return next
  })

export const addIssueToIds = createEvent<{ search: string; id: number }>()
export const removeIssueFromIds = createEvent<{ search: string; id: number }>()

type IdsBySearchValue = Record<string | symbol, number[] | undefined>

const ids$ = createStore<IdsBySearchValue>({})
  .on(fetchListGTechIssue.done, (state, { params, result }) => {
    const next = { ...state }
    const search = params.filter || ZERO_SEARCH
    next[search] = [
      ...new Set([
        ...(state[search] || []),
        ...result.list.map(({ id }) => id),
      ]),
    ]
    return next
  })
  .on(addIssueToIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = [...new Set([result.id, ...(next[search] || [])])]

    return next
  })
  .on(removeIssueFromIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = next[search]?.filter(id => id !== result.id) || []

    return next
  })
  .on(resetIssueList, state => ({}))

export const totalIssues$ = createStore<number | null>(null).on(
  fetchListGTechIssue.doneData,
  (state, result) => result.total,
)

export const issuesList$ = combine({ byId$, ids$, totalIssues$ })
