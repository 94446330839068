import {
  passesFilterDate,
  passesFilterStatus,
  passesFilterType,
  passesFilterUser,
} from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'

import { filterDeadlineRangeByCode } from '../IssueListFilterPanel/issueListDeadlineOptions'

import { IssueListFilters } from './IssueList.types'

export const matchIssueToFilters = (
  issue: api.GTechIssue.IssuePopulated,
  filters: IssueListFilters,
) => {
  const isPassAssigneeUserFilter = passesFilterUser(
    filters.assigneeUserIds,
    issue.assigneeUserId,
  )

  const isPassAssigneeRoleFilter = passesFilterUser(
    filters.assigneeRoleIds,
    issue.assigneeRoleId,
  )

  const isPassOwnerFilter = passesFilterUser(filters.ownerIds, issue.ownerId)

  const isPassTypeFilter = passesFilterType(
    filters.issueTypeIds,
    issue.issueTypeId,
  )

  const isPassStatusFilter = passesFilterStatus(filters.statuses, issue.status)

  const currentDate = new Date()

  const {
    enrichedUpdatedDateRange,
    enrichedCreatedDateRange,
  } = filterDateEnrichment(currentDate, filters)

  const isPassUpdatedDateFilter = passesFilterDate(
    enrichedUpdatedDateRange,
    issue.updatedAt,
  )

  const isPassCreatedDateFilter = passesFilterDate(
    enrichedCreatedDateRange,
    issue.createdAt,
  )

  const isPassDeadlineFilter = passesFilterDate(
    filters.deadlineCode
      ? filterDeadlineRangeByCode[filters.deadlineCode]?.(currentDate)
      : null,
    issue.deadline,
  )

  const isPassSearchFilter = issue.name
    .toLowerCase()
    .includes(filters.filter.toLowerCase())

  return (
    isPassAssigneeUserFilter &&
    isPassAssigneeRoleFilter &&
    isPassOwnerFilter &&
    isPassTypeFilter &&
    isPassStatusFilter &&
    isPassUpdatedDateFilter &&
    isPassCreatedDateFilter &&
    isPassSearchFilter &&
    isPassDeadlineFilter
  )
}
