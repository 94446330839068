import { URLSearchParamsCustom, useQuery } from '@gmini/utils'

import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import { FilterItem, FilterWrapper } from '../../atoms'

import { DateFilterItemsCode } from './dateFilterItemsCode.enum'
import { FilterDateOption } from './FilterDate.types'

import { filterDateItems } from './filterDateItems'

type FilterStatusProps = {
  allowedRange: [string, string] | null
  urlKeyCode: string
  urlKeyValue: string
  disabled?: boolean
  loading?: boolean
  countSkeletonItems?: number
  onChange?: (query: URLSearchParamsCustom) => void
  options?: FilterDateOption[]
}

export const FilterDate = ({
  allowedRange,
  urlKeyCode,
  urlKeyValue,
  disabled,
  loading,
  countSkeletonItems,
  onChange,
  options,
}: FilterStatusProps) => {
  const history = useHistory()

  const query = useQuery()
  const dateFilterCode = query.get(urlKeyCode) as DateFilterItemsCode | null
  // const dateFilterValueFromUrl = query.getArray(urlKeyValue) as [string, string] //TODO вернуть, когда добавлю datePicker

  const preparedFilterDateItems = useMemo(() => {
    const currentDate = new Date()

    return (options ? options : filterDateItems(currentDate)).map(item => {
      let selected = dateFilterCode === item.code

      if (item.code === DateFilterItemsCode.ALL && !dateFilterCode) {
        selected = true
      }

      return {
        ...item,
        uniqId: `${uuid()}__${urlKeyCode}`,
        selected,
        disabled: disabled || !allowedRange,
      }
    })
  }, [options, dateFilterCode, urlKeyCode, disabled, allowedRange])

  const onChangeHandler = useCallback(
    (item: typeof preparedFilterDateItems[0]) => {
      query.set(urlKeyCode, item.code)

      history.replace({ search: query.toString() })

      onChange?.(query)
    },
    [history, onChange, query, urlKeyCode],
  )

  return (
    <FilterWrapper
      loading={!preparedFilterDateItems.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedFilterDateItems.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          onChange={onChangeHandler}
          component='radio'
        />
      ))}
      {dateFilterCode === DateFilterItemsCode.CUSTOM && (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <div onClick={() => {}}></div>
      )}
    </FilterWrapper>
  )
}
