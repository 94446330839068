import {
  RoundedCross,
  Chain,
  Tooltip,
  ConfirmActionPopover,
  Button,
  Tabs,
  CrossedChain,
  LongText,
  DatePicker,
} from '@gmini/ui-kit'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import { Popup } from '@gmini/ui-kit/lib/Popup'
import { useStore } from 'effector-react'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as chmApi from '@gmini/chm-api-sdk'

import {
  fileValidationRules,
  useFilesValidation,
  maxCountErrorMessage,
  useShowAll,
  isImage,
  AssigneeListItem,
  useAssignees,
} from '@gmini/helpers'

import { assertNever, useQuery } from '@gmini/utils'

import {
  ColoredSelect,
  FileValidationErrorsPopup,
  TextFieldMultilineWithPreview,
  Gallery,
  ShowAll,
  pencil,
  smallPencil,
} from '@gmini/components'

import { FileViewer, useContextMenu, UserInfo } from '@gmini/common'

import * as api from '@gmini/ism-api-sdk'

import * as smApi from '@gmini/sm-api-sdk'

import { clone } from 'ramda'

import moment from 'moment'

import dayjs, { Dayjs } from 'dayjs'

import { ClickAwayListener } from '@material-ui/core'

import { useSnackbar } from 'notistack'

import { buildIssueLink } from '../../../helpers'

import { SelectWithPreview, SelectByGroupsWithPreview } from '../../molecules'

import {
  fetchMostRecentGTechIssue,
  fetchIssueHistory,
  updateGTechIssue,
  changeStatus,
  PreparedIssue,
  addIssueToIds,
  fetchListLinkedIssueToFile,
} from '../../issue.store'

import { issueStatusList$, PreparedIssueStatus } from '../../issueStatus.store'

import { EditIssuePopupHistoryChanges } from '../EditIssuePopupHistoryChanges'

import { IssueOfficialReplyForm } from '../IssueOfficialReplyForm'

import {
  fileList$,
  deleteFile,
  fileLinkById$,
  downloadFile,
  uploadFiles,
} from '../../file.store'

import { AttachFile, SelectPreview } from '../../atoms'

import { Comments } from '../Comments'

import { IssueOfficialReply } from '../IssueOfficialReply'

import { issueTypes$ } from '../../issueTypes.store'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  ISSUE_ID,
  PROJECT_URN,
  SEARCH_IL,
} from '../../../constants'

import {
  assigneeAllUserList$,
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeCompanyList$,
} from '../../assigneeGroupList'

import { getAssigneeFromIssue } from '../../getAssigneeFromIssue'

import { gStationDocumentManagementUrl } from '../../../config'

import { Mode } from '../../atoms/SelectPreview/SelectPreview.types'

import {
  Container,
  LeftSide,
  ShortInfoBar,
  LeftSideContent,
  RightSide,
  RightSideContent,
  RightSideHeader,
  FieldName,
  Subtitle,
  AttachmentCount,
  AttachmentTitlePanel,
  CloseButton,
  LinearProgress,
  CopyPathIssueButton,
  ActivityWrapper,
  CheckMark,
  ShowAllWrapper,
  LinkedEntityValue,
  OpenInBrowserButton,
  GTechSimpleLogo,
  LinkedEntityValueText,
  DeadlineEditMode,
} from './EditIssuePopup.styled'

import { editIssuePending$ } from './editIssuePending'
import { TabIndex } from './tabIndex'

type EditIssuePopupProps = {
  userInfo: UserInfo
  issue: PreparedIssue | null
  selectedProject: smApi.Project | null
}

export const EditIssuePopup = ({
  userInfo,
  issue,
  selectedProject,
}: EditIssuePopupProps) => {
  const history = useHistory()
  const query = useQuery()
  const search = query.get(SEARCH_IL) || ''
  const projectUrn = query.get(PROJECT_URN) || null
  const selectedIssueId = Number(query.get(ISSUE_ID)) || null
  const [currentIssue, setCurrentIssue] = useState<PreparedIssue | null>(issue)
  const issueAnswer = currentIssue?.answer || null
  const fileList = useStore(fileList$)
  const issuePending = useStore(editIssuePending$)
  const fileLinkById = useStore(fileLinkById$)
  const issueTypes = useStore(issueTypes$)
  const statusList = useStore(issueStatusList$)
  const assigneeGroupList = useStore(assigneeGroupList$)

  const [copied, setCopied] = useState(false)
  const [tabIndex, setTabIndex] = useState(TabIndex.comment)
  const [deadlineState, setDeadlineState] = useState<Dayjs | null>(
    dayjs(currentIssue?.deadline),
  )
  const [modeDeadline, setModeDeadline] = useState(Mode.preview)
  const [isOfficialReplyFormOpen, setIsOfficialReplyFormOpen] = useState(false)
  const [
    openedFileValidationErrorsPopup,
    setOpenedFileValidationErrorsPopup,
  ] = useState(false)
  const [anchorElPopover, setAnchorElPopover] = useState<Element | null>(null)
  const [openFilesViewer, setOpenFilesViewer] = useState(false)
  const [viewerImageCurrentIdx, setViewerImageCurrentIdx] = useState(0)
  const getAssignees = useAssignees({
    assigneeRoleList$,
    assigneeUserList$: assigneeAllUserList$,
    assigneeCompanyList$,
  })
  const assigneeIssue = getAssigneeFromIssue(currentIssue)
  const [assignee] = getAssignees(assigneeIssue ? [assigneeIssue] : [])

  const imgList = fileList?.filter(isImage)
  const imgLinkList = imgList?.map(({ id }) => fileLinkById[id]).filter(Boolean)

  const issueType = useMemo(
    () => issueTypes.find(({ id }) => currentIssue?.issueTypeId === id) || null,
    [issueTypes, currentIssue?.issueTypeId],
  )
  const {
    fileErrorMessages,
    validateFiles,
    clearFileErrors,
  } = useFilesValidation({
    rules: fileValidationRules,
    onError: () => setOpenedFileValidationErrorsPopup(true),
  })

  useEffect(() => {
    const subscription = api.GTechIssue.addFile.doneData.watch(({ id }) => {
      setCurrentIssue(prevIssue => {
        const newIssue = clone(prevIssue)
        newIssue?.allowedActions?.file?.delete?.push(id)

        return newIssue
      })
    })

    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (issue) {
      setCurrentIssue(issue)
    }
  }, [issue])

  useEffect(() => {
    if (currentIssue?.id && currentIssue?.version) {
      fetchIssueHistory({
        issueId: currentIssue.id,
        issueVersion: currentIssue.version,
      })
    }
  }, [currentIssue?.version, currentIssue?.id])

  useEffect(() => {
    if (
      currentIssue?.id &&
      currentIssue?.version &&
      tabIndex === TabIndex.activity
    ) {
      fetchIssueHistory({
        issueId: currentIssue.id,
        issueVersion: currentIssue.version,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])

  useEffect(() => {
    setDeadlineState(dayjs(currentIssue?.deadline))
  }, [currentIssue?.deadline])

  useEffect(() => {
    if (selectedIssueId) {
      ;(async () => {
        try {
          await fetchMostRecentGTechIssue({ id: selectedIssueId })

          if (!currentIssue) {
            // На случай, когда замечание открыли по прямой ссылке и его не оказалось в первых 20 замечаниях
            addIssueToIds({ id: selectedIssueId, search })
          }

          if (
            gStationDocumentManagementUrl &&
            selectedProject?.sourceType === 'GStation'
          ) {
            fetchListLinkedIssueToFile({ issues: [selectedIssueId] })
          }
        } catch (error) {
          console.error(error)
        }
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIssueId])

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), copiedDelay)

      return () => clearTimeout(timer)
    }
  }, [copied])

  const { isHidden, list: slicedFileList, setIsHidden } = useShowAll({
    list: fileList || [],
    initialCount: initialCountFiles,
  })

  const { ContextMenu } = useContextMenu<{
    event: React.MouseEvent
  }>([
    {
      title: 'Удалить',
      onClick: props => {
        setAnchorElPopover(props.event.target as Element)
      },
      icon: Icon.DELETE,
    },
  ])

  const { enqueueSnackbar } = useSnackbar()

  const getShowLabel = <T extends { label: string }>(user: T) => user?.label

  const onUpdateIssue = useCallback(
    async (updateParams: Partial<api.GTechIssue.UpdateParams>) => {
      if (!currentIssue) {
        throw new Error('currentIssue is null')
      }
      const issue = await updateGTechIssue({
        id: currentIssue.id,
        version: currentIssue.version,
        ...updateParams,
      })

      return issue
    },
    [currentIssue],
  )

  const onChangeAssignee = useCallback(
    (value: AssigneeListItem | null) => {
      if (!currentIssue) {
        throw new Error('currentIssue is null')
      }

      const params: Omit<api.GTechIssue.UpdateParams, 'id' | 'version'> = {}

      if (value === null && currentIssue.assigneeRoleId) {
        params.assigneeRoleId = null
      }

      if (value === null && currentIssue.assigneeUserId) {
        params.assigneeUserId = null
      }

      switch (value?.source) {
        case chmApi.AssigneeSource.USER: {
          if (value.id === currentIssue?.assigneeUserId) {
            return
          }

          params.assigneeUserId = value.id || null
          break
        }
        case chmApi.AssigneeSource.ROLE: {
          if (value.id === currentIssue?.assigneeRoleId) {
            return
          }

          params.assigneeRoleId = value.id || null
          break
        }

        case chmApi.AssigneeSource.COMPANY: {
          break
        }

        default: {
          if (value) {
            return assertNever('Unexpected source', value.source)
          }
        }
      }

      onUpdateIssue(params)
    },
    [currentIssue, onUpdateIssue],
  )

  const onChangeDeadline = useCallback(
    (date: Dayjs | null) => {
      setModeDeadline(Mode.preview)

      if (date && !date.isValid()) {
        enqueueSnackbar('Указана некорректная дата в поле "Срок"', {
          variant: 'error',
        })
        return
      }

      const newDateISOString = date ? date.toISOString() : null

      if (newDateISOString !== (currentIssue?.deadline || null)) {
        onUpdateIssue({
          deadline: newDateISOString,
        })
      }
    },
    [currentIssue?.deadline, enqueueSnackbar, onUpdateIssue],
  )

  if (!currentIssue) {
    return null
  }

  const onClose = () => {
    fetchMostRecentGTechIssue({ id: currentIssue.id })
    query.delete(ISSUE_ID)
    history.push({ search: query.toString() })
  }

  const onOpenViewer = (file: api.GTechIssue.File) => {
    if (!isImage(file)) {
      return
    }
    const imgIdx = imgList?.indexOf(file) || 0
    setViewerImageCurrentIdx(imgIdx)
    setOpenFilesViewer(true)
  }

  const getImageLink = (id: number) =>
    imgList?.some(img => img.id === id) ? fileLinkById[id] : null

  const onChangeStatus = (item: PreparedIssueStatus) => {
    const nextStatus = item.status

    if (nextStatus === api.IssueStatus.Enum.ON_REVIEW) {
      setIsOfficialReplyFormOpen(true)
      return
    }

    changeStatus({
      issueId: currentIssue.id,
      issueVersion: currentIssue.version,
      status: nextStatus,
    })
  }

  const onCopyLinkForIssue = () => {
    const linkIssue = buildIssueLink(
      window.location.origin,
      projectUrn,
      currentIssue.id,
    )

    window.navigator.clipboard.writeText(linkIssue)
    setCopied(true)
  }

  const onUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files
    if (!files) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), fileList?.length)

    if (allowFiles.length) {
      await uploadFiles(
        { issueId: currentIssue.id, issueVersion: currentIssue.version },
        allowFiles,
      )
    }
  }

  const handleClosePopover = () => {
    setAnchorElPopover(null)
  }

  const isMaxCountFiles = Number(fileList?.length) >= 20

  const onDeleteFile = (file: api.GTechIssue.File) => {
    deleteFile({
      fileId: file.id,
      issueId: currentIssue.id,
      issueVersion: currentIssue.version,
    })
  }

  const onDownloadFile = (file: api.GTechIssue.File) => {
    downloadFile({ fileId: file.id })
  }

  const allowStatusList = statusList.filter(item => {
    const { allowedActions, status } = currentIssue

    if (
      status === api.IssueStatus.Enum.ON_REVIEW &&
      item.status === api.IssueStatus.Enum.IN_PROCESS
    ) {
      //TODO поставил костыль, потому что есть два пути для перевода замечания в статус IN_PROCESS из статуса ON_REVIEW
      // через кнопку "Удалить официальный ответ" и кнопку "Отклонить". Соответственно, если у замечания совпадает овнер и ответственный, то невозможно понять какое действие нужно делать через селект
      return false
    }

    return allowedActions && allowedActions.changeStatus[item.status]
  })

  const onSubmitOfficialReplyForm = async ({
    description,
  }: {
    description: string
  }) => {
    await changeStatus({
      issueId: currentIssue.id,
      issueVersion: currentIssue.version,
      status: api.IssueStatus.Enum.ON_REVIEW,
      description,
    })
  }
  const onAcceptOfficialReply = async () => {
    await changeStatus({
      issueId: currentIssue.id,
      issueVersion: currentIssue.version,
      status: api.IssueStatus.Enum.CLOSED,
    })
  }
  const onRejectOfficialReply = async () => {
    await changeStatus({
      issueId: currentIssue.id,
      issueVersion: currentIssue.version,
      status: api.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: api.IssueAnswer.StatusEnum.DECLINED,
    })
  }
  const onDeleteOfficialReply = async () => {
    await changeStatus({
      issueId: currentIssue.id,
      issueVersion: currentIssue.version,
      status: api.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: api.IssueAnswer.StatusEnum.DELETED,
    })
  }

  const isIssueClosed = currentIssue.status === api.IssueStatus.Enum.CLOSED

  let linkedEntityValue = null

  if (currentIssue.linkedEntity) {
    linkedEntityValue = (
      <>
        <GTechSimpleLogo internalColor='#00003D' outerColor='#353B60' />
        <LinkedEntityValueText>
          <LongText
            partSize={10}
            withoutRightText
          >{`${currentIssue.linkedEntity.moduleName} / ${currentIssue.linkedEntity.entityName}`}</LongText>
        </LinkedEntityValueText>

        <Tooltip
          title={`Открыть в новом окне ${currentIssue.linkedEntity.moduleName}/${currentIssue.linkedEntity.entityName}`}
        >
          <OpenInBrowserButton
            onClick={() =>
              window.open(currentIssue.linkedEntity?.link, '_blank')
            }
          />
        </Tooltip>
      </>
    )
  }

  if (currentIssue.linkedEntity === null) {
    linkedEntityValue = (
      <>
        <CrossedChain />
        Не привязано
      </>
    )
  }

  return (
    <Popup open={!!selectedIssueId} onClose={onClose} width='1100px'>
      {issuePending && <LinearProgress />}
      <FileValidationErrorsPopup
        open={openedFileValidationErrorsPopup}
        onClose={() => setOpenedFileValidationErrorsPopup(false)}
        errors={fileErrorMessages}
      />
      <Container>
        <LeftSide>
          <TextFieldMultilineWithPreview
            value={currentIssue.name || ''}
            onSubmit={(value: string) => onUpdateIssue({ name: value })}
            disabled={issuePending || isIssueClosed}
            styleText={{
              fontWeight: 500,
              ...nameFieldStyles,
            }}
            maxLength={256}
            styleTextArea={nameFieldStyles}
            editIcon={pencil}
            editable={!!currentIssue.allowedActions?.changeableFields.name}
          />

          <LeftSideContent>
            <ShortInfoBar>
              <Subtitle>
                Замечание <b>#{currentIssue.id}</b>
              </Subtitle>
              <Tooltip
                title={copied ? 'Скопировано' : 'Скопировать адрес замечания'}
              >
                <CopyPathIssueButton onClick={onCopyLinkForIssue}>
                  {copied ? (
                    <CheckMark color='#42AB85' />
                  ) : (
                    <Chain color='#353B60' opacity={0.6} />
                  )}
                </CopyPathIssueButton>
              </Tooltip>
              <ConfirmActionPopover
                title='Удалить файлы'
                message={
                  <>
                    Вы уверены, что хотите навсегда удалить все файлы? Все
                    ссылки на ресурсы будут удалены. Это действие не может быть
                    отменено.
                  </>
                }
                anchorEl={anchorElPopover}
                handleClose={handleClosePopover}
                actions={
                  <>
                    <Button
                      size='regular'
                      color='secondary'
                      onClick={handleClosePopover}
                    >
                      Отменить
                    </Button>
                    <Button
                      color='warning'
                      onClick={() => {
                        handleClosePopover()
                      }}
                      size='regular'
                    >
                      Удалить
                    </Button>
                  </>
                }
              />
              <ContextMenu />

              <ColoredSelect
                list={allowStatusList}
                selectedItem={statusList.find(
                  item => item.status === currentIssue.status,
                )}
                onChange={onChangeStatus}
                disabled={issuePending}
              />
            </ShortInfoBar>
            <div>
              <FieldName>Описание</FieldName>

              <TextFieldMultilineWithPreview
                value={currentIssue.description || ''}
                onSubmit={(value: string) =>
                  onUpdateIssue({ description: value })
                }
                viewMaxLength={256}
                styleText={{
                  fontWeight: 400,
                  ...descriptionFieldStyles,
                }}
                disabled={issuePending || isIssueClosed}
                maxLength={256}
                emptyMessage='Не задано'
                allowSubmitEmpty
                styleTextArea={descriptionFieldStyles}
                editIcon={smallPencil}
                editable={
                  !!currentIssue.allowedActions?.changeableFields?.description
                }
              />
            </div>
            {issueAnswer?.status === 'PENDING' && (
              <IssueOfficialReply
                officialReply={issueAnswer}
                onDelete={
                  userInfo.id === currentIssue.assigneeUserId && //TODO Костыль, придумать как эту логику утащить на бэк
                  currentIssue.allowedActions?.changeStatus?.[
                    api.IssueStatus.Enum.IN_PROCESS
                  ] &&
                  currentIssue.status === api.IssueStatus.Enum.ON_REVIEW
                    ? onDeleteOfficialReply
                    : null
                }
                onAccept={
                  currentIssue.allowedActions?.changeStatus?.[
                    api.IssueStatus.Enum.CLOSED
                  ]
                    ? onAcceptOfficialReply
                    : null
                }
                onReject={
                  currentIssue.allowedActions?.changeStatus?.[
                    api.IssueStatus.Enum.CLOSED
                  ]
                    ? onRejectOfficialReply
                    : null
                }
                disabled={issuePending}
              />
            )}
            <div>
              <AttachmentTitlePanel>
                <FieldName>Приложения</FieldName>
                <AttachmentCount>{fileList?.length || 0}</AttachmentCount>
                {!!currentIssue.allowedActions?.file?.add && (
                  <Tooltip
                    title={
                      isMaxCountFiles ? maxCountErrorMessage : 'Добавить файл'
                    }
                    styleContent={{
                      width: 'max-content',
                    }}
                    noMaxWidth
                  >
                    <AttachFile
                      variant='default'
                      disabled={
                        issuePending || isMaxCountFiles || isIssueClosed
                      }
                      onChange={onUploadFile}
                    />
                  </Tooltip>
                )}
              </AttachmentTitlePanel>
              {imgLinkList && imgLinkList.length !== 0 && (
                <FileViewer
                  linkList={imgLinkList}
                  open={openFilesViewer}
                  setOpen={setOpenFilesViewer}
                  startIndex={viewerImageCurrentIdx}
                />
              )}
              {slicedFileList.length !== 0 && (
                <div>
                  <Gallery
                    disabled={issuePending}
                    fileList={slicedFileList}
                    onDeleteFile={onDeleteFile}
                    onDownloadFile={onDownloadFile}
                    getImgSrc={file => getImageLink(file.id)}
                    onOpenViewer={onOpenViewer}
                    isDeletable={file =>
                      !!currentIssue.allowedActions?.file?.delete?.some(
                        id => file.id === id,
                      )
                    }
                  />
                  {Number(fileList?.length) > initialCountFiles && (
                    <ShowAllWrapper>
                      <ShowAll
                        hideButtonText='Скрыть все файлы'
                        showButtonText='Показать все файлы'
                        onSwitch={() => setIsHidden(prev => !prev)}
                        isHidden={isHidden}
                      />
                    </ShowAllWrapper>
                  )}
                </div>
              )}
            </div>
            <div>
              <FieldName>Активность</FieldName>
              <Tabs
                onChangeTab={setTabIndex}
                activeTabIndex={tabIndex}
                headerStyles={{
                  fontSize: '14px',
                  height: '40px',
                  gap: 19,
                  marginTop: 'auto',
                  marginBottom: '-1px',
                  borderBottom: 'none',
                }}
                tabs={[
                  {
                    title: 'Комментарии',
                    content: null,
                  },
                  {
                    title: 'История',
                    content: null,
                  },
                ]}
              />

              {tabIndex === TabIndex.comment && (
                <Comments
                  issue={currentIssue}
                  userInfo={userInfo}
                  disabled={currentIssue.status === api.IssueStatus.Enum.CLOSED}
                />
              )}
              {tabIndex === TabIndex.activity && (
                <ActivityWrapper>
                  <EditIssuePopupHistoryChanges issue={currentIssue} />
                </ActivityWrapper>
              )}
            </div>
          </LeftSideContent>
        </LeftSide>
        <RightSide>
          <RightSideHeader>
            <CloseButton type='square'>
              <RoundedCross onClick={onClose} />
            </CloseButton>
          </RightSideHeader>
          <RightSideContent>
            <div>
              <FieldName>Дисциплина замечания</FieldName>
              <SelectWithPreview
                getOptionLabel={(value: api.GTechIssueType) => value.name}
                value={issueType}
                options={issueTypes}
                placeholder='Не задана'
                onChange={(value: api.GTechIssueType | null) => {
                  if (
                    value === currentIssue.assigneeUserId ||
                    value?.id === currentIssue.assigneeUserId
                  ) {
                    return
                  }
                  onUpdateIssue({ issueTypeId: value?.id || null })
                }}
                disabled={issuePending || isIssueClosed}
                allowDelete={false}
                renderValue={(value: api.GTechIssueType) => value.name}
                editable={
                  !!currentIssue.allowedActions?.changeableFields?.issueTypeId
                }
              />
            </div>
            <div>
              <FieldName>Назначено на</FieldName>
              <SelectByGroupsWithPreview
                value={assignee}
                getOptionLabel={value => value.label}
                groups={assigneeGroupList}
                placeholder='Не задан'
                onChange={onChangeAssignee}
                renderValue={getShowLabel}
                disabled={issuePending || isIssueClosed}
                editable={
                  !!currentIssue.allowedActions?.changeableFields
                    ?.assigneeRoleId &&
                  !!currentIssue.allowedActions?.changeableFields
                    ?.assigneeUserId
                }
              />
            </div>
            <div>
              <FieldName>Срок</FieldName>
              <ClickAwayListener
                onClickAway={() => {
                  if (modeDeadline === Mode.edit) {
                    onChangeDeadline(deadlineState)
                  }
                }}
              >
                <div>
                  <SelectPreview
                    renderValue={issue =>
                      issue?.deadline
                        ? moment(issue.deadline).format(
                            DEFAULT_DISPLAY_DATE_FORMAT,
                          )
                        : ''
                    }
                    placeholder='Не задан'
                    value={currentIssue}
                    mode={modeDeadline}
                    onChangeMode={setModeDeadline}
                    disabled={issuePending || isIssueClosed}
                    editable={
                      !!currentIssue.allowedActions?.changeableFields?.deadline
                    }
                    renderEditMode={() => (
                      <DeadlineEditMode>
                        <DatePicker
                          label='Укажите срок'
                          value={deadlineState}
                          onAccept={onChangeDeadline}
                          onChange={setDeadlineState}
                          disabled={issuePending || isIssueClosed}
                          onReset={() => onChangeDeadline(null)}
                          background='#f4f4f8'
                          slotProps={{
                            textField: {
                              error: false,
                              onKeyDown: event => {
                                switch (event.key) {
                                  case 'Tab':
                                  case 'Enter': {
                                    onChangeDeadline(deadlineState)
                                  }
                                }
                              },
                            },
                          }}
                          format={DEFAULT_DISPLAY_DATE_FORMAT}
                          autoFocus
                        />
                      </DeadlineEditMode>
                    )}
                  />
                </div>
              </ClickAwayListener>
            </div>
            {selectedProject?.sourceType !== 'Bim360' && (
              <div>
                <FieldName>Привязано к</FieldName>

                <LinkedEntityValue disabled={!currentIssue.linkedEntity}>
                  {linkedEntityValue}
                </LinkedEntityValue>
              </div>
            )}
          </RightSideContent>
        </RightSide>
      </Container>
      <IssueOfficialReplyForm
        open={isOfficialReplyFormOpen}
        onClose={() => setIsOfficialReplyFormOpen(false)}
        onSubmit={onSubmitOfficialReplyForm}
      />
    </Popup>
  )
}

const copiedDelay = 5000

const initialCountFiles = 3

const nameFieldStyles = {
  fontSize: '24px',
  lineHeight: '28px',
  letterSpacing: '0.1px',
}

const descriptionFieldStyles = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
}
