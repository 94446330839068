import ReactQuill, { Quill } from 'react-quill'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

import 'quill-emoji/dist/quill-emoji.css'
import './Styles/styles.css'
import './Styles/quill-emoji.css'

import { CodeBlockIcon } from './Icons/CodeBlockIcon'
import {
  BodyArea,
  BodyAreaWrap,
  EditorFooter,
  EditorWrap,
  FooterLeftWrap,
} from './MessageEditor.styled'

import { LinkWithValidation, modules, emojiConfig, bindingKeys } from './config'

Quill.register(emojiConfig, true)
Quill.register(LinkWithValidation)

const icons = ReactQuill.Quill.import('ui/icons')
// code and code-block has the same icons in quill
icons['code-block'] = ReactDOMServer.renderToString(<CodeBlockIcon />)

type MessageEditorProps = {
  onSubmit: (text: string) => Promise<any>
  footerRightButtons?: (submit: () => Promise<any>) => React.ReactNode
  footerLeftButtons?: () => React.ReactNode
  onEmptyMessageSubmit?: () => void
  bodyArea?: () => React.ReactNode
  disabled?: boolean
  loading?: boolean
  onLoading?: (val: boolean) => void
  placeholder?: string
}

export const MessageEditor = ({
  onSubmit,

  footerRightButtons,
  onEmptyMessageSubmit,
  footerLeftButtons,
  bodyArea,
  disabled,
  loading,
  onLoading,
  placeholder,
}: MessageEditorProps) => {
  const [message, setMessage] = React.useState('')

  const quillRef = React.createRef<ReactQuill>()

  const filterEmptyTags = (str: string) =>
    str.replace(/<p><br><\/p>+/g, '').trim()

  const submitMessage = React.useCallback(async () => {
    if (disabled || loading) {
      return
    }
    const text = quillRef.current?.editor?.getText().trim()
    // text is empty when emoji presents
    const emojiPresents = quillRef.current?.editor
      ?.getContents()
      .ops?.some(val => val?.insert?.emoji)

    if (!text && !emojiPresents) {
      onEmptyMessageSubmit?.()
      return
    }
    if (text && text.length > 10000) {
      return
    }

    onLoading?.(true)

    const cleanMessage = filterEmptyTags(message)
    await onSubmit(cleanMessage)

    setMessage('')
    onLoading?.(false)
  }, [
    disabled,
    loading,
    message,
    onEmptyMessageSubmit,
    onLoading,
    onSubmit,
    quillRef,
  ])

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      const newLineKeyPressed = e.shiftKey || e.ctrlKey || e.altKey

      if (e.key === 'Enter' && !newLineKeyPressed) {
        e.preventDefault()
        submitMessage()
      }
    },
    [submitMessage],
  )

  return (
    <EditorWrap>
      <ReactQuill
        ref={quillRef}
        modules={{
          ...modules,
          keyboard: {
            bindings: bindingKeys(quillRef.current),
          },
        }}
        value={message}
        onChange={value => setMessage(value)}
        onKeyDown={onKeyDown}
        readOnly={loading}
        placeholder={placeholder}
      />
      {bodyArea && (
        <BodyAreaWrap>
          <BodyArea>{bodyArea()}</BodyArea>
        </BodyAreaWrap>
      )}
      {(footerLeftButtons || footerRightButtons) && (
        <EditorFooter>
          {footerLeftButtons && (
            <FooterLeftWrap>{footerLeftButtons()}</FooterLeftWrap>
          )}
          {footerRightButtons && <div>{footerRightButtons(submitMessage)}</div>}
        </EditorFooter>
      )}
    </EditorWrap>
  )
}
