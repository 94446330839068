import { URLSearchParamsCustom, useQuery } from '@gmini/utils'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import { FilterItem, FilterWrapper } from '../../atoms'

import { TypeItem } from './FilterType.types'

type FilterStatusProps<T> = {
  typeList: T[]
  allowedItems: number[]
  urlKey: string
  loading?: boolean
  disabled?: boolean
  countSkeletonItems?: number
  onChange?: (query: URLSearchParamsCustom) => void
}

export const FilterType = <T extends TypeItem>({
  allowedItems,
  typeList,
  urlKey,
  disabled,
  loading,
  countSkeletonItems,
  onChange,
}: FilterStatusProps<T>) => {
  const history = useHistory()

  const query = useQuery()
  const typeListFromUrl = query.getArray(urlKey).map(Number)

  const preparedTypeList = useMemo(
    () =>
      typeList.map(item => ({
        ...item,
        uniqId: uuid(),
        selected: typeListFromUrl?.some(id => id === item.id),
        disabled: disabled || !allowedItems.some(id => id === item.id),
      })),
    [typeList, typeListFromUrl, disabled, allowedItems],
  )

  const onChangeHandler = useCallback(
    (item: typeof preparedTypeList[0]) => {
      if (
        typeListFromUrl.length &&
        typeListFromUrl.some(id => id === item.id)
      ) {
        query.removeValue(urlKey, item.id)
      } else {
        query.appendValue(urlKey, item.id)
      }

      history.replace({ search: query.toString() })

      onChange?.(query)
    },
    [typeListFromUrl, history, query, onChange, urlKey],
  )

  return (
    <FilterWrapper
      loading={!typeList.length && loading}
      countSkeletonItems={countSkeletonItems}
    >
      {preparedTypeList.map(item => (
        <FilterItem key={item.uniqId} item={item} onChange={onChangeHandler} />
      ))}
    </FilterWrapper>
  )
}
