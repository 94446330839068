import styled from 'styled-components'

import { DraggableDotsIcon } from '../../atoms'

export const ColumnOrderPopupItemWrapper = styled.div`
  padding: 6px 0;
  padding-right: 16px;
  color: #000335;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;

  & ${DraggableDotsIcon} {
    opacity: 0;
  }

  &:hover {
    background: #f4f4f8;

    & ${DraggableDotsIcon} {
      opacity: 1;
    }
  }
`
