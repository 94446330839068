import { createAssigneeGroupListService } from '@gmini/helpers'

import { roleList$ } from './role.store'
import { companyList$ } from './company.store'
import { allUserList$, projectUserList$ } from './user.store'

export const {
  assigneeGroupList$,
  assigneeRoleList$,
  assigneeUserList$,
  mapRoleToAssigneeOption,
  mapUserToAssigneeOption,
  assigneeCompanyList$,
  mapCompanyToAssigneeOption,
} = createAssigneeGroupListService({
  roleList$,
  userList$: projectUserList$,
  companyList$,
})

export const {
  assigneeUserList$: assigneeAllUserList$,
} = createAssigneeGroupListService({
  roleList$,
  userList$: allUserList$,
  companyList$,
})
