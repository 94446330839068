import {
  IconButton,
  CheckMark as CheckMarkInit,
  OpenInBrowser,
  GTechSimpleLogo as GTechSimpleLogoInit,
} from '@gmini/ui-kit'
import styled, { css } from 'styled-components'
import { LinearProgress as LinearProgressInit } from '@gmini/ui-kit/lib/LinearProgress'

export const Container = styled.div`
  display: flex;
  position: relative;
  max-height: 800px;
`

export const LeftSide = styled.div`
  flex: 1;
  padding: 30px 0 30px 24px;
  min-width: 750px;
`

export const LeftSideContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 30px;
  padding-right: 30px;
  max-height: 80vh;
`

export const ShortInfoBar = styled.div`
  display: flex;
  align-items: center;
`

export const RightSide = styled.div`
  border-left: 1px solid #e5e7f1;
  min-width: 300px;
`

export const RightSideHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 14px 30px 14px;
`

export const RightSideContent = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px 0;
  overflow: auto;
`

export const FieldName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #00003d;
  margin-bottom: 16px;
`

export const Subtitle = styled.div`
  display: flex;
  gap: 0 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #00003d;
  margin-right: 4px;
`

export const AttachmentTitlePanel = styled.div`
  display: flex;
  gap: 8px;
`

export const AttachmentCount = styled(FieldName)`
  opacity: 0.6;
  font-weight: 500;
  margin-right: auto;
`

export const CloseButton = styled(IconButton)`
  && svg path {
    fill: #353b60;
  }
`

export const LinearProgress = styled(LinearProgressInit)`
  && {
    border-radius: 4px 4px 0 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

export const CopyPathIssueButton = styled(IconButton)`
  margin-right: 12px;

  && svg path {
    fill: rgba(
      53,
      59,
      96,
      0.8
    ); //TODO разобраться с тем почему 0.5 opacity на самой иконке визуально выглядит как 0.2
  }
`

export const ActivityWrapper = styled.div`
  max-height: 300px;
`

export const CheckMark = styled(CheckMarkInit)`
  && {
    & svg path {
      fill: #42ab85;
    }
  }
`

export const ShowAllWrapper = styled.div`
  margin-top: 12px;
`

export const GTechSimpleLogo = styled(GTechSimpleLogoInit)``

type LinkedEntityValueProps = {
  disabled: boolean
}

export const LinkedEntityValue = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00003d;
  letter-spacing: 0.15px;

  ${(props: LinkedEntityValueProps) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}

  ${GTechSimpleLogo} {
    min-width: 18px;
    width: 18px;
    height: 18px;
  }
`

export const LinkedEntityValueText = styled.div`
  display: grid;
`

export const OpenInBrowserButton = styled(OpenInBrowser)`
  cursor: pointer;

  & path {
    fill: rgba(53, 59, 96, 0.7);
  }

  &:hover {
    & path {
      fill: rgba(53, 59, 96, 1);
    }
  }
`

export const DeadlineEditMode = styled.div`
  && {
    legend {
      display: none;
    }
    .MuiOutlinedInput-notchedOutline {
      top: 0;
    }
  }
`
