import { userInfo$ } from '@gmini/common'
import {
  FilterDate,
  FilterPanel,
  FilterType,
  FilterUser,
} from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import {
  ASSIGNEE_IDS_TL,
  CREATED_DATE_CODE_TL,
  CREATED_DATE_RANGE_TL,
  ISSUE_TYPE_IDS_TL,
  OWNER_IDS_TL,
  PROJECT_IS_NOT_SELECTED_ERROR,
  UPDATED_DATE_CODE_TL,
  UPDATED_DATE_RANGE_TL,
} from '../../../constants'
import { fetchCompanyListPending$ } from '../../company.store'
import { fetchIssueTypesPending$, issueTypes$ } from '../../issueTypes.store'
import { fetchRoleListPending$ } from '../../role.store'
import {
  fetchAllUserListPending$,
  fetchProjectUserListPending$,
  useProjectUserList,
} from '../../user.store'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type IssueTemplateListFilterPanelProps = {
  onChange: (query: URLSearchParamsCustom) => void
  projectUrn?: string | null
}

export const IssueTemplateListFilterPanel = ({
  onChange,
  projectUrn,
}: IssueTemplateListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const issueTypes = useStore(issueTypes$)
  const userList = useProjectUserList()
  const fetchProjectUserListPending = useStore(fetchProjectUserListPending$)
  const fetchCompanyListPending = useStore(fetchCompanyListPending$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)
  const fetchAllUserListPending = useStore(fetchAllUserListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const fetchIssueTypesPending = useStore(fetchIssueTypesPending$)
  const userInfo = useStore(userInfo$)

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
    })
  }, [projectUrn])

  const onChangeHandler = (query: URLSearchParamsCustom) => {
    onChange?.(query)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
    }) // Чтобы хэндлить изменения замечаний другими пользователями
  }

  return (
    <FilterPanel
      items={[
        {
          title: 'Дисциплины',
          component: (
            <FilterType
              allowedItems={allowedFilters?.issueTypeIds || []}
              typeList={issueTypes}
              urlKey={ISSUE_TYPE_IDS_TL}
              loading={fetchIssueTypesPending}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              countSkeletonItems={10}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата изменения',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_TL}
              urlKeyValue={UPDATED_DATE_RANGE_TL}
              disabled={!allowedFilters && fetchAllowFiltersPending}
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Дата создания',
          component: (
            <FilterDate
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_TL}
              urlKeyValue={CREATED_DATE_RANGE_TL}
              onChange={onChangeHandler}
              disabled={!allowedFilters && fetchAllowFiltersPending}
            />
          ),
        },
        {
          title: 'Автор',
          component: (
            <FilterUser
              userList={userList}
              urlKey={OWNER_IDS_TL}
              allowedItems={allowedFilters?.ownerIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchProjectUserListPending ||
                fetchAllUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeHandler}
            />
          ),
        },
        {
          title: 'Назначено на',
          component: (
            <FilterUser
              userList={userList}
              urlKey={ASSIGNEE_IDS_TL}
              allowedItems={allowedFilters?.assigneeIds || []}
              placeholder='Выбрать на кого на...'
              disabled={
                fetchProjectUserListPending ||
                fetchAllUserListPending ||
                fetchRoleListPending ||
                fetchCompanyListPending ||
                (!allowedFilters && fetchAllowFiltersPending)
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeHandler}
            />
          ),
        },
      ]}
    />
  )
}
