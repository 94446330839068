import { useQuery } from '@gmini/utils'
import { useMemo } from 'react'

import { AppliedFilterAssignee } from './useAppliedFilterUserData.types'

type UseAppliedFilterUserDataProps<U> = {
  urlKey: string | string[]
  name: string
  userList: U[]
}

export const useAppliedFilterUserData = <U extends AppliedFilterAssignee>({
  urlKey,
  name,
  userList,
}: UseAppliedFilterUserDataProps<U>) => {
  const query = useQuery()
  const usersFromUrl: string | string[] = useMemo(() => {
    if (Array.isArray(urlKey)) {
      return urlKey.map(key => [...query.getArray(key)]).flat()
    }

    return query.getArray(urlKey)
  }, [query, urlKey])

  return useMemo(
    () => ({
      name,
      value: userList
        .filter(item => usersFromUrl?.some(id => id === item.id))
        .map(item => item.name),
      urlKey,
    }),
    [name, urlKey, userList, usersFromUrl],
  )
}
