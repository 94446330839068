import { createDateRangeByDays, createDateRangeByMonths } from '@gmini/helpers'

import { DateFilterItemsCode } from './dateFilterItemsCode.enum'

type FilterDateRangeByCode = Record<
  DateFilterItemsCode,
  ((currentDate: Date) => [string, string]) | null
>

export const filterDateRangeByCode: FilterDateRangeByCode = {
  [DateFilterItemsCode.ALL]: null,
  [DateFilterItemsCode.CUSTOM]: null,
  [DateFilterItemsCode.LAST_WEEK]: currentDate =>
    createDateRangeByDays(currentDate, 7),
  [DateFilterItemsCode.LAST_2_WEEK]: currentDate =>
    createDateRangeByDays(currentDate, 14),
  [DateFilterItemsCode.LAST_MONTH]: currentDate =>
    createDateRangeByMonths(currentDate, 1),
}
