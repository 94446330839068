export const ThreeDotsIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.9987 6.66671C10.9154 6.66671 11.6654 5.91671 11.6654 5.00004C11.6654 4.08337 10.9154 3.33337 9.9987 3.33337C9.08203 3.33337 8.33203 4.08337 8.33203 5.00004C8.33203 5.91671 9.08203 6.66671 9.9987 6.66671ZM9.9987 8.33337C9.08203 8.33337 8.33203 9.08337 8.33203 10C8.33203 10.9167 9.08203 11.6667 9.9987 11.6667C10.9154 11.6667 11.6654 10.9167 11.6654 10C11.6654 9.08337 10.9154 8.33337 9.9987 8.33337ZM9.9987 13.3334C9.08203 13.3334 8.33203 14.0834 8.33203 15C8.33203 15.9167 9.08203 16.6667 9.9987 16.6667C10.9154 16.6667 11.6654 15.9167 11.6654 15C11.6654 14.0834 10.9154 13.3334 9.9987 13.3334Z'
      fill='#A2A3B7'
    />
  </svg>
)
