import styled from 'styled-components'
import { IconButton } from '@gmini/ui-kit'

import { LinearProgress as LinearProgressInit } from '@gmini/ui-kit/lib/LinearProgress'

import { Checkbox, OpenCtxButton } from '../../atoms'

export const TableContentContainer = styled.div`
  display: flex;
  gap: 14px;
  flex-grow: 1;
  min-height: 0;
`

export const NoFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 70%;
  justify-content: center;
  position: absolute;
`

export const TextMedium = styled.div`
  color: rgba(0, 0, 61, 1);
  font-size: 18px;
  line-height: 21.09px;
  margin: 5px 0;
`

export const Th = styled.th``
export const Td = styled.td``
type TrProps = {
  selected?: boolean
}
export const Tr = styled.tr`
  background: ${(props: TrProps) =>
    props.selected ? 'rgba(92, 130, 229, 0.15)' : 'inherit'};

  ${OpenCtxButton} {
    opacity: ${(props: TrProps) => (props.selected ? '1' : '0')};
  }

  &:hover {
    cursor: pointer;
    ${OpenCtxButton} {
      opacity: 1;
    }
  }
`

export const PaginationLine = styled.div`
  position: sticky;
  bottom: 0px;
  width: 100%;
  background: #fff;
  height: 41px;
  display: flex;
`
export const PaginationLineTd = styled.div``
export const SettingTh = styled(Th)``
export const CheckboxTh = styled(Th)`
  width: 35px !important;
`
export const CheckboxTd = styled(Td)`
  width: 35px !important;
`
export const VersionTd = styled(Td)`
  white-space: nowrap;
`
export const NameTd = styled(Td)`
  word-break: break-word;
`
export const Thead = styled.thead`
  background: #fafafc;
  position: sticky;
  top: 0;
  z-index: 1;
`
export const NumberTh = styled(Th)`
  width: 40px !important;
`
export const NumberTd = styled(Td)`
  text-align: right;
  width: 40px !important;
`

export const TableInitial = styled.table`
  &,
  tr,
  td,
  th {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    border-collapse: inherit;
    border-spacing: 0;
    border-color: inherit;
    vertical-align: inherit;
    text-align: left;
    font-weight: inherit;
  }
`

export const StyledTable = styled(TableInitial)`
  width: 100%;

  ${Th} {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #a2a3b7;
    letter-spacing: 0.15px;
    padding: 11px 8px;
    text-align: left;
    white-space: nowrap;
  }

  ${Td} {
    padding: 13px 8px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #00003d;
    letter-spacing: 0.15px;
    text-align: left;
    border-bottom: 1px solid #e5e7f1;
  }

  ${Tr} {
    &:hover {
      background: rgba(0, 5, 100, 0.04);

      ${Checkbox} {
        opacity: 1;
      }
    }

    &:active {
      background: rgba(0, 10, 87, 0.07);
    }
  }

  ${SettingTh},${CheckboxTh} {
    padding: 2px 8px;
  }

  ${CheckboxTd} {
    padding: 10px 8px;
  }
`

export const SettingButtonWrapper = styled.div`
  position: relative;
`

export const SettingButton = styled(IconButton)`
  margin-left: auto;
`

export const Count = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.3);
  margin-top: 25px;
`

type LinearProgressProps = {
  show?: boolean
}

export const LinearProgress = styled(LinearProgressInit)`
  opacity: ${(props: LinearProgressProps) => (props.show ? 1 : 0)};
`
