import styled from 'styled-components'

export const ColumnOrderPopupWrapper = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  background: #fff;
  padding: 7px 0;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`

export const ColumnOrderPopupItemText = styled.div`
  margin-left: 12px;
`
