import { DateFilterItemsCode } from '@gmini/components'
import { useQuery } from '@gmini/utils'
import moment from 'moment'
import { useMemo } from 'react'

type useAppliedFilterDateDataProps = {
  urlKeyCode: string
  urlKeyValue: string
  dateFormat: string
  name: string
}

export const useAppliedFilterDateData = ({
  urlKeyCode,
  urlKeyValue,
  dateFormat,
  name,
}: useAppliedFilterDateDataProps) => {
  const query = useQuery()

  const dateRangeFromUrl = query.getArray(urlKeyValue)
  const filterCode = query.get(urlKeyCode) as DateFilterItemsCode | null

  return useMemo(() => {
    let value: string[] = []

    switch (filterCode) {
      case DateFilterItemsCode.LAST_WEEK: {
        value = ['За последнюю неделю']
        break
      }
      case DateFilterItemsCode.LAST_2_WEEK: {
        value = ['За последние 2 недели']
        break
      }
      case DateFilterItemsCode.LAST_MONTH: {
        value = ['За последний месяц']
        break
      }
      case DateFilterItemsCode.CUSTOM: {
        value = [
          `${moment(dateRangeFromUrl[0]).format(dateFormat)} - ${moment(
            dateRangeFromUrl[1],
          ).format(dateFormat)}`,
        ]
      }
    }

    return {
      name,
      value,
      urlKey: urlKeyCode,
    }
  }, [dateFormat, dateRangeFromUrl, filterCode, name, urlKeyCode])
}
