import { SelectByGroups, LongText } from '@gmini/ui-kit'
import {
  Group,
  RenderOptionParams,
} from '@gmini/ui-kit/lib/SelectByGroups/SelectByGroups.types'
import { URLSearchParamsCustom, useQuery } from '@gmini/utils'

import * as chmApi from '@gmini/chm-api-sdk'

import { ReactNode, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { AssigneeListItem } from '@gmini/helpers'

import { FilterUserItem } from '../../atoms'

import {
  FilterUserWrapper,
  FirstElWrapper,
  GroupTitleStyled,
  Separator,
} from './FilterAssignee.styled'
import { getUrlKey } from './getUrlKey'

export type FilterUserInfoType = {
  id: string
}

export type FilterAssigneeGroup = Group<AssigneeListItem> & {
  source: chmApi.AssigneeSource
}

type FilterAssigneeProps<UI> = {
  assigneeGroupList: FilterAssigneeGroup[]
  userInfo: UI | null
  allowedItems: string[]
  urlKeyUser: string
  urlKeyRole: string
  urlKeyCompany?: string
  labelFirstEl: string
  loading?: boolean
  disabled?: boolean
  countSkeletonItems?: number
  placeholder?: string
  onChange?: (query: URLSearchParamsCustom) => void
}

const currentUserId = 'currentUserId'

export const FilterAssignee = <UI extends FilterUserInfoType>({
  allowedItems,
  assigneeGroupList,
  userInfo,
  urlKeyUser,
  urlKeyRole,
  urlKeyCompany,
  disabled,
  loading,
  countSkeletonItems,
  placeholder,
  labelFirstEl,
  onChange,
}: FilterAssigneeProps<UI>) => {
  const history = useHistory()

  const query = useQuery()
  const userListFromUrl = query.getArray(urlKeyUser)
  const roleListFromUrl = query.getArray(urlKeyRole)
  const companyListFromUrl = urlKeyCompany
    ? query.getArray(urlKeyCompany)
    : null

  const onChangeHandler = useCallback(
    (item: AssigneeListItem | null) => {
      if (!item) {
        return
      }

      const query = new URLSearchParamsCustom(history.location.search)
      const urlKey = getUrlKey(item.source, {
        urlKeyUser,
        urlKeyRole,
        urlKeyCompany,
      })
      const listFromUrl = query.getArray(urlKey)

      if (
        listFromUrl.length &&
        listFromUrl.some(id => id === item.id.toString())
      ) {
        query.removeValue(urlKey, item.id)
      } else {
        query.appendValue(urlKey, item.id)
      }

      history.replace({ search: query.toString() })

      onChange?.(query)
    },
    [history, urlKeyUser, urlKeyRole, urlKeyCompany, onChange],
  )

  const firstEl = useMemo(
    () =>
      ({
        id: currentUserId,
        source: chmApi.AssigneeSource.USER,
        name: labelFirstEl,
      } as AssigneeListItem & { name: string }),
    [labelFirstEl],
  )

  const allowedAssigneeGroupList: FilterAssigneeGroup[] = useMemo(() => {
    let result = assigneeGroupList.map(group => ({
      ...group,
      options: group.options.filter(
        option =>
          allowedItems.includes(option.id) && option.id !== userInfo?.id,
      ),
    }))

    if (userInfo && allowedItems.some(id => id === userInfo.id)) {
      result = result.map(group => {
        if (group.source === chmApi.AssigneeSource.USER) {
          return { ...group, options: [firstEl, ...group.options] }
        }

        return group
      })
    }

    return result
  }, [allowedItems, assigneeGroupList, firstEl, userInfo])

  const renderOption = useCallback(
    (params: RenderOptionParams<AssigneeListItem>): ReactNode => {
      if (userInfo && params.option.id === currentUserId) {
        return (
          <FirstElWrapper>
            <FilterUserItem
              item={userInfo}
              label={
                <LongText partSize={20} withoutRightText>
                  {labelFirstEl}
                </LongText>
              }
              onChange={() =>
                onChangeHandler({
                  id: userInfo.id,
                  label: labelFirstEl,
                  source: chmApi.AssigneeSource.USER,
                })
              }
              checked={userListFromUrl.some(id => id === userInfo.id)}
            />
            <Separator />
          </FirstElWrapper>
        )
      }

      return (
        <FilterUserItem
          item={params.option}
          label={
            <LongText partSize={20} withoutRightText>
              {params.option.label}
            </LongText>
          }
          onChange={() => onChangeHandler(params.option)}
          checked={
            userListFromUrl.some(id => id === params.option.id) ||
            roleListFromUrl.some(id => id === params.option.id) ||
            !!companyListFromUrl?.some(id => id === params.option.id)
          }
        />
      )
    },
    [
      companyListFromUrl,
      labelFirstEl,
      onChangeHandler,
      roleListFromUrl,
      userInfo,
      userListFromUrl,
    ],
  )

  return (
    <FilterUserWrapper>
      <SelectByGroups
        getOptionLabel={value => value.label}
        groups={allowedAssigneeGroupList}
        emptyOptionListMessage='Нет доступных совпадений'
        loading={loading}
        placeholder={placeholder}
        disabled={
          disabled ||
          !allowedAssigneeGroupList.some(({ options }) => options.length)
        }
        value={null}
        hideInputIcon
        allowDelete={false}
        optionListStyles={{
          width: '364px',
          paddingBottom: '10px',
        }}
        renderOption={renderOption}
        popoverBoxShadow='0px 4px 10px rgba(0, 0, 0, 0.25)'
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        style={{ paddingRight: '9.5px' }}
        groupTitleStyled={GroupTitleStyled}
      />
    </FilterUserWrapper>
  )
}
