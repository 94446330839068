import { useForm, Controller } from 'react-hook-form'

import { useStore } from 'effector-react'

import { Select, ActionPopupWrapper } from '@gmini/ui-kit'

import { useEffect, useMemo } from 'react'

import { FieldError, FieldLabel } from '@gmini/components'

import { buildUserLabel } from '@gmini/helpers'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '@gmini/ism-api-sdk'

import {
  TextArea,
  TextField,
  Form,
  FieldContainer,
  MultipleFieldRow,
} from '../CreateGTechIssuePopup/CreateGTechIssuePopup.styled'

import { EmptyContainer } from './UpsertIssueTemplatePopup.styled'

import { openPopup$, toggleOpenPopup } from './upsertIssueTemplatePopup.store'
import { FormValueUpsertIssueTemplate } from './UpsertIssueTemplatePopup.types'

type UpsertIssueTemplatePopupProps = {
  issueTemplate: api.GTechIssueTemplate | null
  onSubmit: (data: FormValueUpsertIssueTemplate) => void
  issueTypes: api.GTechIssueType[]
  projectUsers: smApi.Auth.UserData[]
  allUsers: smApi.Auth.UserData[]
  title: string
  submitButtonTitle: string
  onClose?: () => void
  projectList: smApi.Project[]
  projectUrn: string | null
}

export const UpsertIssueTemplatePopup = ({
  onSubmit,
  issueTemplate,
  issueTypes,
  projectUsers,
  allUsers,
  submitButtonTitle,
  title,
  onClose,
  projectList,
  projectUrn,
}: UpsertIssueTemplatePopupProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValueUpsertIssueTemplate>({
    mode: 'onChange',
    defaultValues: {
      assigneeUser: null,
      description: '',
      issueType: null,
      name: '',
      project: null,
    },
  })

  const selectedProject = useMemo(
    () => projectList.find(({ urn }) => urn === projectUrn),
    [projectList, projectUrn],
  )

  useEffect(() => {
    reset({
      assigneeUser:
        allUsers.find(({ id }) => id === issueTemplate?.assigneeUserId) || null,
      description: issueTemplate?.description || '',
      issueType:
        issueTypes.find(({ id }) => id === issueTemplate?.issueTypeId) || null,
      name: issueTemplate?.name || '',
      project:
        projectList.find(({ urn }) => urn === issueTemplate?.projectUrn) ||
        selectedProject,
    })
  }, [selectedProject, projectList, issueTemplate, issueTypes, reset, allUsers])

  const open = useStore(openPopup$)

  const onCloseHandler = () => {
    toggleOpenPopup(false)
    onClose?.()
    reset()
  }

  const onSubmitHandler = (data: FormValueUpsertIssueTemplate) => {
    onSubmit(data)

    onCloseHandler()
  }

  return (
    <ActionPopupWrapper
      open={open}
      onClose={onCloseHandler}
      onSubmit={handleSubmit(onSubmitHandler)}
      width='600px'
      submitButtonTitle={submitButtonTitle}
      title={title}
    >
      {({ existScroll }) => (
        <Form>
          {!issueTemplate && (
            <FieldContainer>
              <FieldLabel required>Проект</FieldLabel>
              <Controller
                name='project'
                control={control}
                rules={{
                  required: { message: requiredErrorMessage, value: true },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={projectList}
                    getOptionLabel={(option: smApi.Project) => option.name}
                    placeholder='Выберите проект'
                    error={Boolean(errors.project)}
                    emptyOptionListMessage={emptyOptionListMessage}
                    allowDelete={false}
                    lockSelect={!!selectedProject}
                  />
                )}
              />
              <FieldError hidden={!errors.project}>
                {errors.project && 'message' in errors.project
                  ? errors.project.message
                  : null}
              </FieldError>
            </FieldContainer>
          )}
          <FieldContainer>
            <FieldLabel required>Название</FieldLabel>
            <Controller
              name='name'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
                maxLength: {
                  message: maxLengthTitleErrorMessage,
                  value: 256,
                },
              }}
              render={({ field }) => (
                <TextField
                  placeholder='Укажите название'
                  error={Boolean(errors.name)}
                  onChange={field.onChange}
                  value={field.value}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.name}>
              {errors.name && 'message' in errors.name
                ? errors.name.message
                : null}
            </FieldError>
          </FieldContainer>
          <MultipleFieldRow>
            <FieldContainer>
              <FieldLabel required>Дисциплина</FieldLabel>
              <Controller
                name='issueType'
                control={control}
                rules={{
                  required: { message: requiredErrorMessage, value: true },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={issueTypes}
                    getOptionLabel={(option: api.GTechIssueType) => option.name}
                    placeholder='Укажите дисциплину шаблона'
                    error={Boolean(errors.issueType)}
                    emptyOptionListMessage={emptyOptionListMessage}
                  />
                )}
              />
              <FieldError hidden={!errors.issueType}>
                {errors.issueType && 'message' in errors.issueType //TODO избавиться от таких проверок при использовании react-hook-form по всему проекту
                  ? errors.issueType.message
                  : null}
              </FieldError>
            </FieldContainer>

            <FieldContainer>
              <FieldLabel>Назначить на</FieldLabel>

              <Controller
                name='assigneeUser'
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={projectUsers}
                    getOptionLabel={buildUserLabel}
                    placeholder='Выберите пользователя'
                    error={Boolean(errors.assigneeUser)}
                    emptyOptionListMessage={emptyOptionListMessage}
                  />
                )}
              />
              <FieldError hidden={!errors.assigneeUser}>
                {errors.assigneeUser && 'message' in errors.assigneeUser
                  ? errors.assigneeUser.message
                  : null}
              </FieldError>
            </FieldContainer>
          </MultipleFieldRow>
          <FieldContainer>
            <FieldLabel>Описание</FieldLabel>

            <Controller
              name='description'
              control={control}
              rules={{
                maxLength: {
                  value: 300,
                  message: maxLengthDescriptionErrorMessage,
                },
              }}
              render={({ field }) => (
                <TextArea
                  placeholder='Укажите описание'
                  error={Boolean(errors.description)}
                  {...field}
                />
              )}
            />

            <FieldError hidden={!errors.description}>
              {errors.description && 'message' in errors.description
                ? errors.description.message
                : null}
            </FieldError>
          </FieldContainer>

          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}

const emptyOptionListMessage = 'Нет доступных совпадений'
const maxLengthTitleErrorMessage =
  'Максимальная допустимая длина названия - 256 символов'
const requiredErrorMessage = 'Это поле является обязательным'
const maxLengthDescriptionErrorMessage =
  'Максимальная допустимая длина описания - 300 символов'
