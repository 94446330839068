import { useQuery } from '@gmini/utils'
import { useMemo } from 'react'

import { IssueListFilterDeadlineOptionCode } from '../IssueListFilterPanel/issueListDeadlineOptions'

type UseAppliedFilterDeadlineDataProps = {
  urlKeyCode: string
  name: string
}

export const useAppliedFilterDeadlineData = ({
  urlKeyCode,
  name,
}: UseAppliedFilterDeadlineDataProps) => {
  const query = useQuery()

  const filterCode = query.get(
    urlKeyCode,
  ) as IssueListFilterDeadlineOptionCode | null

  return useMemo(() => {
    let value: string[] = []

    switch (filterCode) {
      case IssueListFilterDeadlineOptionCode.ALMOST_EXPIRED: {
        value = ['Приближающийся срок']
        break
      }
      case IssueListFilterDeadlineOptionCode.ONLY_EXPIRED: {
        value = ['Только просроченные']
        break
      }
    }

    return {
      name,
      value,
      urlKey: urlKeyCode,
    }
  }, [filterCode, name, urlKeyCode])
}
