import { DateFilterItemsCode } from '@gmini/components'
import { filterDateEnrichment } from '@gmini/helpers'
import * as api from '@gmini/ism-api-sdk'
import { URLSearchParamsCustom } from '@gmini/utils'
import { createEffect, createEvent, merge } from 'effector'
import { clone } from 'ramda'

import { ZERO_SEARCH } from '../../../constants'

import { createIssueTemplateService } from '../../createIssueTemplateListService'

import {
  updateIssueTemplate,
  createIssueTemplate,
  deleteIssueTemplate,
} from '../../issueTemplate.action'
import { fetchAllowedFilters } from '../IssueTemplateListFilterPanel/model'

import { getIssueTemplateListFilters } from './getIssueTemplateListFilters'
import { matchIssueTemplateToFilters } from './matchIssueTemplateToFilters'

merge([
  deleteIssueTemplate.doneData,
  updateIssueTemplate.doneData,
  createIssueTemplate.doneData,
]).watch(issueTemplate => {
  fetchAllowedFilters({ projectUrn: issueTemplate.projectUrn })

  const query = new URLSearchParamsCustom(window.location.search)
  const filters = getIssueTemplateListFilters(query)

  if (!matchIssueTemplateToFilters(issueTemplate, filters)) {
    removeIssueTemplateFromIds({ id: issueTemplate.id, search: filters.filter })
  }
})

export const {
  resetTemplateList,
  templateList$,
  fetchIssueTemplateList,
  ids$,
  byId$,
} = createIssueTemplateService({
  fetchIssueTemplateListApi: api.GTechIssueTemplate.fetchList,
})

export const addIssueTemplateToIds = createEvent<{
  search: string
  id: number
}>()
export const removeIssueTemplateFromIds = createEvent<{
  search: string
  id: number
}>()

byId$.on(
  merge([updateIssueTemplate.doneData, createIssueTemplate.doneData]),
  (state, result) => ({
    ...state,
    [result.id]: { ...state[result.id], ...result },
  }),
)

ids$
  .on(addIssueTemplateToIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = [...new Set([result.id, ...(next[search] || [])])]

    return next
  })
  .on(removeIssueTemplateFromIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = next[search]?.filter(id => id !== result.id) || []

    return next
  })

export type FetchIssueTemplateListWithPrepareDateRange = api.GTechIssueTemplate.FetchListParams & {
  updatedDateCode?: DateFilterItemsCode | null
  createdDateCode?: DateFilterItemsCode | null
}

export const fetchIssueTemplateListWithEnrichDateFilter = createEffect<
  FetchIssueTemplateListWithPrepareDateRange,
  unknown
>()

fetchIssueTemplateListWithEnrichDateFilter.use(
  ({
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const {
      enrichedUpdatedDateRange,
      enrichedCreatedDateRange,
    } = filterDateEnrichment(currentDate, {
      updatedDateRange,
      createdDateRange,
      updatedDateCode,
      createdDateCode,
    })

    fetchIssueTemplateList({
      ...otherProps,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
    })
  },
)
