import styled from 'styled-components'

import {
  TextField as TextFieldInit,
  TextArea as TextAreaInit,
} from '@gmini/ui-kit'

export const TextField = styled(TextFieldInit)`
  background: #f4f4f8;
`

export const TextArea = styled(TextAreaInit)`
  && {
    background: #f4f4f8;
    width: 100%;
  }
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  padding: 10px 24px 24px 24px;
`

export const MultipleFieldRow = styled.div`
  display: flex;
  gap: 12px;
`

export const FieldContainer = styled.div`
  width: 100%;
`

export const Separator = styled.div`
  width: 100%;
  background: #e5e7f1;
  height: 1px;
  margin-bottom: 13px;
`

export const UploadFilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  max-height: 200px;
  margin-right: -17px;
  padding-right: 17px;
`

export const FullSeparator = styled(Separator)`
  margin: 16px -24px 18px -24px;
  width: calc(100% + 48px);
`

export const EmptyContainer = styled.div`
  height: 60px;
`

export const HeaderTitle = styled.div`
  display: flex;
  gap: 0 12px;
  align-items: center;
  width: 100%;
`
