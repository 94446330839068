import { FetchService } from '@gmini/utils'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'

import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import * as smApi from '@gmini/sm-api-sdk'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import * as api from '@gmini/ism-api-sdk'

import * as documentManagmentApi from '@gmini/sm-api-sdk/lib/DMAPi'

import { gStationDocumentManagementUrl, localBackUrl } from '../config'

export const issueManagerFetch = new FetchService({
  baseUrl: localBackUrl ? localBackUrl : '/api/issues',
  refreshTokenFunc,
})

const gStationModelNavigation = new FetchService({
  baseUrl: '/api/g-station-model-navigation',
  refreshTokenFunc,
})

export const documentManagment = new FetchService({
  baseUrl: gStationDocumentManagementUrl,
})

export const authApi = new FetchService({ baseUrl: '/api/auth/v1' })

export const hubManagement = new FetchService({
  baseUrl: '/api/hub-management',
})

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [
        authApi,
        issueManagerFetch,
        gStationModelNavigation,
        documentManagment,
        hubManagement,
      ],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([
      authApi,
      issueManagerFetch,
      gStationModelNavigation,
      documentManagment,
      hubManagement,
    ])
  }
})

smApi.Auth.User.fetch.use(() =>
  authApi.get(`/auth/current-user`).then(smApi.Auth.User.fetch.toPromise),
)

api.issueManagerConnect(issueManagerFetch)
smApi.navigationModelRepoConnect(gStationModelNavigation)
documentManagmentApi.DMConnect(documentManagment)
smApi.authConnect(authApi)
smApi.hubManagementConnect(hubManagement)
