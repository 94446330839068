import { SnackbarContent, SnackbarMessage, SnackbarKey } from 'notistack'
import { forwardRef } from 'react'

import {
  Wrapper,
  Message,
  InfoText,
  InfoIcon,
  InfoTextWrapper,
  CloseButton,
  Content,
} from './EntityCreatedSnackbar.styled'

type EntityCreatedSnackbarProps = {
  id: SnackbarKey
  message: SnackbarMessage
  infoText?: string | null
  onClose?: () => void
}

export const EntityCreatedSnackbar = forwardRef<
  HTMLDivElement,
  EntityCreatedSnackbarProps
>((props, ref) => {
  const { id, message, infoText, onClose, ...other } = props

  return (
    <SnackbarContent ref={ref} role='alert' {...other}>
      <Wrapper isSmall={!infoText}>
        <Content>
          <Message>{message}</Message>

          {infoText && (
            <InfoTextWrapper>
              <InfoIcon />
              <InfoText>{infoText}</InfoText>
            </InfoTextWrapper>
          )}
        </Content>

        <CloseButton color='#fff' onClick={onClose} />
      </Wrapper>
    </SnackbarContent>
  )
})

EntityCreatedSnackbar.displayName = 'EntityCreatedSnackbar'
