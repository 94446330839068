import ReactQuill from 'react-quill'

export const bindingKeys = (quill: ReactQuill | null) => {
  if (quill) {
    return {
      enter: {
        key: 'enter',
        shiftKey: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handler() {},
      },
      // New line for alt_enter, option_enter(mac)
      alt_enter: {
        key: 'Enter',
        altKey: true,
        handler(range: { index: number }) {
          const editor = quill.getEditor()
          editor.insertText(range.index, '\n')
        },
      },
      shift_enter: {
        key: 'Enter',
        shiftKey: true,
        handler(range: { index: number }) {
          const editor = quill.getEditor()
          editor.insertText(range.index, '\n')
        },
      },
    }
  }

  return {}
}

export const modules = {
  toolbar: {
    container: [],
  },
  clipboard: {
    matchVisual: false,
  },
  'emoji-toolbar': false,
  'emoji-textarea': false,
  'emoji-shortname': false,
}
