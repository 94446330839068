import { Checkbox } from '@gmini/ui-kit'
import { useHover } from '@gmini/utils'

import { ReactNode } from 'react'

import { filterCheckboxColors } from '../FilterItem'

import { FilterUserRenderItemType } from './FilterUserItem.types'
import { FilterUserItemStyled } from './FilterUserItem.styled'

type FilterUserItemProps<U> = {
  item: U
  onChange?: (item: U) => void
  label?: ReactNode
  checked: boolean
  disabled?: boolean
}

export const FilterUserItem = <U extends FilterUserRenderItemType>({
  item,
  onChange,
  label,
  checked,
  disabled,
}: FilterUserItemProps<U>) => {
  const { hover, onMouseLeave, onMouseOver } = useHover()

  return (
    <FilterUserItemStyled
      htmlFor={item.id}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      disabled={disabled}
    >
      <Checkbox
        id={item.id}
        name={item.id}
        hover={hover}
        checked={checked}
        disabled={disabled}
        colors={filterCheckboxColors}
        onChange={() => onChange?.(item)}
      />
      {label}
    </FilterUserItemStyled>
  )
}
