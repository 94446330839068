import { useStore } from 'effector-react'
import { userInfo$ } from '@gmini/common'

import { useEffect } from 'react'

import { getOriginFromEnv } from '@gmini/helpers'
import { useQuery } from '@gmini/utils'

import * as api from '@gmini/ism-api-sdk'

import {
  issueTypes$,
  fetchIssueTypes,
  fetchIssueTypesPending$,
} from '../../issueTypes.store'

import { CreateGTechIssuePopup } from '../../organisms'

import { envLinks } from '../../../config'

import { DEVELOPMENT_MODE, LOCAL_ORIGIN, PROJECT_URN } from '../../../constants'

import { fetchAllUserList, fetchProjectUserList } from '../../user.store'

import { fetchRoleList } from '../../role.store'

import { fetchProjectList, projectList$ } from './model'

export const CreateIssuePopupPage = () => {
  const query = useQuery()
  const projectUrn = query.get(PROJECT_URN) || null

  const userInfo = useStore(userInfo$)
  const projectList = useStore(projectList$)
  const fetchIssueTypesPending = useStore(fetchIssueTypesPending$)

  useEffect(() => {
    fetchIssueTypes.submit()
    fetchProjectList.submit()
    fetchRoleList.submit()
    fetchAllUserList.submit({})
    if (projectUrn) {
      fetchProjectUserList.submit({ projectUrn })
    }
  }, [projectUrn])

  const sendMessage = (issue: api.GTechIssue.IssuePopulated) => {
    const inspectionOrigin = getOriginFromEnv(envLinks, 'fm')
    if (window.opener) {
      window.opener.postMessage(
        issue,
        DEVELOPMENT_MODE ? LOCAL_ORIGIN : `https://${inspectionOrigin}`,
      )
      window.close()
    }
  }

  if (!userInfo) {
    return null
  }
  return (
    <CreateGTechIssuePopup
      issueTypes$={issueTypes$}
      onCreateIssue={sendMessage}
      projectList={projectList}
      projectUrn={projectUrn}
      loading={fetchIssueTypesPending}
      lockProjectSelect
      open
    />
  )
}
