import { DateFilterItemsCode, filterDateRangeByCode } from '@gmini/components'

export const filterDateEnrichment = (
  currentDate: Date,
  {
    createdDateCode,
    createdDateRange,
    updatedDateCode,
    updatedDateRange,
  }: {
    createdDateRange?: string[] | null
    updatedDateRange?: string[] | null
    updatedDateCode?: DateFilterItemsCode | null
    createdDateCode?: DateFilterItemsCode | null
  },
) => {
  let enrichedUpdatedDateRange: string[] | null | undefined = null

  if (updatedDateCode) {
    enrichedUpdatedDateRange =
      filterDateRangeByCode[updatedDateCode]?.(currentDate) || null
  }

  if (updatedDateCode === DateFilterItemsCode.CUSTOM) {
    enrichedUpdatedDateRange = updatedDateRange
  }

  let enrichedCreatedDateRange: string[] | null | undefined = null

  if (createdDateCode) {
    enrichedCreatedDateRange =
      filterDateRangeByCode[createdDateCode]?.(currentDate) || null
  }

  if (createdDateCode === DateFilterItemsCode.CUSTOM) {
    enrichedCreatedDateRange = createdDateRange
  }

  return {
    enrichedUpdatedDateRange,
    enrichedCreatedDateRange,
  }
}
