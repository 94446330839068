import * as chmApi from '@gmini/chm-api-sdk'

type GetAssigneeFromIssue = {
  assigneeRoleId?: string | null
  assigneeUserId?: string | null
}
// TODO Костыль на время, пока не будет множественного выбора назначенных. Сейчас в замечании всегда только одно из полей - либо assigneeRoleId, либо assigneeUserId
export const getAssigneeFromIssue = (issue?: GetAssigneeFromIssue | null) => {
  if (!issue) {
    return null
  }

  if (issue.assigneeRoleId) {
    return {
      source: chmApi.AssigneeSource.ROLE,
      assigneeId: issue.assigneeRoleId,
    }
  }

  if (issue.assigneeUserId) {
    return {
      source: chmApi.AssigneeSource.USER,
      assigneeId: issue.assigneeUserId,
    }
  }

  return null
}
