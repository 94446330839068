import { DateFilterItemsCode } from '@gmini/components'
import { URLSearchParamsCustom } from '@gmini/utils'

import {
  ASSIGNEE_IDS_TL,
  CREATED_DATE_CODE_TL,
  CREATED_DATE_RANGE_TL,
  ISSUE_TYPE_IDS_TL,
  OWNER_IDS_TL,
  SEARCH_TL,
  UPDATED_DATE_CODE_TL,
  UPDATED_DATE_RANGE_TL,
} from '../../../constants'

import { IssueTemplateListFilters } from './IssueTemplateList.types'

export const getIssueTemplateListFilters = (
  query: URLSearchParamsCustom,
): IssueTemplateListFilters => {
  const searchValueFromUrl = query.get(SEARCH_TL) || ''
  const issueTypeIds = query.getArray(ISSUE_TYPE_IDS_TL).map(Number)
  const ownerIds = query.getArray(OWNER_IDS_TL)
  const assigneeIds = query.getArray(ASSIGNEE_IDS_TL)
  const createdDateRange = query.getArray(CREATED_DATE_RANGE_TL)
  const updatedDateRange = query.getArray(UPDATED_DATE_RANGE_TL)
  const createdDateCode = query.get(
    CREATED_DATE_CODE_TL,
  ) as DateFilterItemsCode | null
  const updatedDateCode = query.get(
    UPDATED_DATE_CODE_TL,
  ) as DateFilterItemsCode | null

  return {
    filter: searchValueFromUrl,
    issueTypeIds,
    ownerIds,
    assigneeIds,
    createdDateRange,
    updatedDateRange,
    createdDateCode,
    updatedDateCode,
  }
}
